.address-card {
  border: 0.2rem solid $border;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-bottom: 1.5rem;
  padding: 1rem 3.6rem 0 1rem;
  position: relative;
  transition: background-color 300ms,
  opacity 100ms,
  box-shadow 200ms;

  @media screen and (max-width: $tablet) {
    border: none;
    border-bottom: 0.1rem solid rgba(213, 221, 224, 0.4);
    border-radius: 0;
    margin-bottom: 1rem;
    padding: 0 0 1rem;

    &:last-child {
      border-bottom: none;
    }
  }

  .block-address {
    min-width: 20rem;
    padding-right: 4rem;

    p {
      margin-bottom: 1rem;
    }

    @media screen and (max-width: $tablet) {
      display: flex;
      flex-wrap: wrap;

      p {
        margin-right: 0.8rem;
        padding-right: 0.5rem;
        position: relative;
        white-space: nowrap;

        @media screen and (max-width: $tablet) {
          &:after {
            content: ',';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }

      strong {
        font-weight: $font-medium;
      }
    }
  }

  .btn-delete {
    position: absolute;
    top: -0.3rem;
    right: -0.3rem;
    border: none;
    background-color: transparent;

    &:hover {
      &::before {
        transition: transform 300ms;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }

  &-disabled {
    opacity: 0.5;
  }
}

.status {
  font-weight: $font-bold;
  text-transform: capitalize;

  &-confirm,
  &-done,
  &-ready {
    color: $green;
  }

  &-reject {
    color: $add-text;
  }
}

.profile {
  &-content {
    display: flex;

    @media screen and (max-width: $tablet) {
      display: block;
      background-color: $white;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 12;
      overflow-y: auto;
    }
  }

  &-aside {
    border-top: 0.8rem solid $bg-blue-gray;
    border-right: 0.8rem solid $bg-blue-gray;
    flex: 0 0 28.2rem;
    width: 28.2rem;
    padding: 5.8rem 0 6.8rem 6.1rem;

    &_top {
      h1 {
        margin-bottom: 1.4rem;
      }
    }

    .btn-logout {
      background-color: #eceff2;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem 2.8rem 0 0;
      padding: 1.2rem 6.5rem;

      @media screen and (max-width: $tablet) {
        background: #f7f8f9;
        border: 0.1rem solid rgba(213, 221, 224, 0.4);
        border-radius: 0.8rem;
        font-size: 1.7rem;
        font-weight: $font-medium;
        height: 6rem;
        padding: 0 1rem;
        width: 100%;

        span {
          background: url(../../images/ic-logout_m.svg) 100% 50% no-repeat;
          background-size: 2.1rem 2rem;
          padding-right: 3.6rem;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      border-top: none;
      border-right: none;
      width: 100%;
      padding: 0 2.4rem 2.4rem;

      &_top {
        background: #f7f8f9;
        border-radius: 0 0 2rem 2rem;
        margin: 0 -2.4rem 0.6rem;
        padding: 3rem 2.4rem;

        .nav-data {
          .nav-link,
          .nav-link:hover {
            &:before {
              background: url(../../images/ic-profile.svg) 0 0 no-repeat;
              background-size: cover;
              content: '';
              display: block;
              width: 1.6rem;
              height: 1.6rem;
              position: absolute;
              left: 2rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .nav-orders,
        .nav-orders:hover {
          .nav-link {
            &:before {
              background: url(../../images/ic-history_m.svg) 0 0 no-repeat;
              background-size: cover;
              content: '';
              display: block;
              width: 1.6rem;
              height: 2.1rem;
              position: absolute;
              left: 2.1rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .nav-settings,
        .nav-settings:hover {
          .nav-link {
            &:before {
              background: url(../../images/ic-pencil.svg) 0 0 no-repeat;
              background-size: cover;
              content: '';
              display: block;
              width: 1.6rem;
              height: 1.6rem;
              position: absolute;
              left: 2rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .nav-credits,
        .nav-credits:hover {
          .nav-link {
            &:before {
              background: url(../../images/ic-credit-card.svg) 0 0 no-repeat;
              background-size: cover;
              content: '';
              display: block;
              width: 1.6rem;
              height: 1.6rem;
              position: absolute;
              left: 2rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .nav-reviews,
        .nav-reviews:hover {
          .nav-link {
            &:before {
              background: url(../../images/ic-discussion-closed.svg) 0 0 no-repeat;
              background-size: cover;
              content: '';
              display: block;
              width: 1.6rem;
              height: 1.6rem;
              position: absolute;
              left: 2rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .nav-subscriptions,
        .nav-subscriptions:hover {
          .nav-link {
            &:before {
              background: url(../../images/ic-rss.svg) 0 0 no-repeat;
              background-size: cover;
              content: '';
              display: block;
              width: 1.6rem;
              height: 1.6rem;
              position: absolute;
              left: 2rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

      h1 {
        color: #2d3541;
        margin-bottom: 0.9rem;
      }

      .mobile-user_static {
        margin-bottom: 2.1rem;
        padding: 0 !important;
      }
    }

    @media screen and (max-width: $smartphone-smallest) {
      padding: 0 1.5rem 2.4rem;

      &_top {
        margin: 0 -1.5rem 0.6rem;
        padding: 3rem 1.5rem;
      }
    }

    .close-link {
      display: none;
    }

    &_credits {
      display: flex;
      align-items: center;
      background-color: #eceff2;
      font-size: 1.4rem;
      margin: 0 2.8rem 2rem 0;
      padding: 1.2rem 1.5rem;

      @media screen and (max-width: $tablet) {
        margin: 0 0 0.8rem;
      }

      .number {
        font-weight: $font-bold;
        margin: 0 0.7rem 0 0.5rem;
      }

      .info {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #919ba9;
        border-radius: 100%;
        color: $white;
        font-size: 1.1rem;
        font-weight: $font-semibold;
        width: 1.6rem;
        height: 1.6rem;

        &-block {
          position: relative;
          flex: 0 0 1.6rem;
        }

        &-hover {
          display: none;
          background-color: $white;
          border-radius: 0.2rem;
          box-shadow: 0 0.2rem 0.8rem rgba($black, 0.15);
          position: absolute;
          top: 50%;
          left: 3.2rem;
          transform: translateY(-50%);
          font-size: 1.2rem;
          width: 13.8rem;
          padding: 0.8rem;

          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            border-width: 0.7rem 0.7rem 0.7rem 0;
            border-right-color: $white;
            position: absolute;
            top: 50%;
            left: -0.7rem;
            transform: translateY(-50%);
          }
        }

        &:hover {
          cursor: default;

          & + .info-hover {
            display: block;
          }
        }
      }
    }
  }

  &-menu {
    flex-direction: column;
    flex-wrap: nowrap;
    font-weight: $font-bold;
    font-size: 1.4rem;

    .nav-link {
      font-size: 1.4rem;
      font-weight: $font-bold;
      height: 4.8rem;
      display: flex;
      align-items: center;
      padding: 0;
      position: relative;
      transition: all 0.2s linear;

      @media screen and (max-width: $tablet) {
        background-color: $white;
        border: 0.1rem solid rgba(213, 221, 224, 0.4);
        border-radius: 0.8rem;
        display: flex;
        align-items: center;
        font-size: 1.7rem;
        font-weight: $font-medium;
        height: 6rem;
        padding: 0 3rem 0 5.4rem;
        position: relative;

        &:after {
          background: url(../../images/ic-arrow_d.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 0.7rem;
          height: 1.2rem;
          position: absolute;
          top: 50%;
          right: 2.5rem;
          transform: translateY(-50%);
        }
      }

      &.active,
      &:hover {
        color: $subcategory;

        &:before {
          background-color: $subcategory;
          content: '';
          display: block;
          width: 0.4rem;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;

          @media screen and (max-width: $tablet) {
            display: none;
          }
        }
      }

      &.disabled {
        color: $add-text;
      }
    }

    li {
      height: 4.8rem;

      @media screen and (max-width: $tablet) {
        height: auto;
        padding: 1rem 0 0 !important;

        &:first-child {
          padding-top: 0 !important;
        }
      }
    }
  }

  &-right {
    border-top: 0.8rem solid $bg-blue-gray;
    flex: 2;
    font-size: 1.4rem;
    padding: 4.8rem 24rem 15rem;
    position: relative;

    h4 {
      margin-bottom: 1.8rem;
    }

    .link-dashed {
      font-size: 1.4rem;
    }

    .back-link {
      display: none;
    }

    .tab-content {
      max-width: 86rem;

      @media screen and (max-width: $tablet) {
        max-width: none;
      }
    }

    @media screen and (max-width: $xl-hd) {
      padding: 4.8rem 8.8rem 15rem;
    }

    @media screen and (max-width: $hd) {
      padding: 4.8rem 3.8rem 15rem;
    }

    @media screen and (max-width: $tablet) {
      border-top: none;
      background-color: $bg;
      padding: 0;

      .back-link {
        display: inline-block;
      }

      .tab-pane {
        padding: 1.8rem 2.4rem;

        &.data {
          background-color: $white;
        }

        &.settings {
          padding-bottom: 8rem;

          .profile-info {
            &_row {
              padding-bottom: 0;

              &:first-child,
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(7) {
                border-bottom: none;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $smartphone-smallest) {
      .tab-pane {
        padding: 1.8rem 1.5rem;
      }
    }
  }

  &-block {
    background-color: $white;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    border-radius: 0.8rem;
    font-size: 1.4rem;
    margin-bottom: 3.5rem;
    padding: 2.4rem 2.4rem 2.4rem 7.2rem;
    position: relative;

    .profile-form-error {
      bottom: 0;
      padding: 0;
    }

    @media screen and (max-width: $hd) {
      padding: 4.5rem;
    }

    @media screen and (max-width: $tablet) {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      margin-bottom: 0;
    }

    .tab-content {
      width: 100%;
    }

    .checkout-delivery_address {
      display: block;
    }

    &_title {
      border-bottom: 0.1rem solid $subcategory;
      font-weight: $font-bold;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      padding-bottom: 1rem;
      display: none;

      @media screen and (max-width: $tablet) {
        display: block;
        border-bottom: none;
        font-size: 2.4rem;
        font-weight: $font-bold;
        margin-bottom: 1.5rem;
        padding-bottom: 0;
      }
    }

    &_credits {
      margin-bottom: 2rem;
      min-height: 60rem;
      padding: 0 3.8rem 2.4rem;

      & + .paging {
        margin-top: 0;
      }

      @media screen and (max-width: $hd) {
        min-height: 45rem;
        padding: 0 3.8rem 2.4rem;
      }

      @media screen and (max-width: $tablet) {
        border: 0.1rem solid #edeff2;
      }

      @media screen and (max-width: $smartphone-middle) {
        padding: 0 2rem 2.4rem;
      }
    }
  }

  &-info {
    &_row {
      display: flex;
      margin-bottom: 2rem;

      @media screen and (max-width: $tablet) {
        display: block;
        margin-bottom: 1rem;
      }
    }

    &_label {
      flex: 0 0 23.4rem;
      width: 23.4rem;
      font-size: 1.4rem;
      font-weight: $font-bold;
      margin: 0;

      @media screen and (max-width: $tablet) {
        color: #919ba9;
        font-weight: $font-medium;
        width: 100%;
        margin-bottom: 0.6rem;
      }

      .star {
        color: $subcategory;
      }
    }

    &_name {
      font-weight: $font-bold;
      margin: 0;

      @media screen and (max-width: $tablet) {
        font-size: 1.6rem;
        font-weight: $font-medium;
      }
    }

    &_txt {
      p {
        margin-bottom: 0;
      }

      .address-card {
        p {
          margin-bottom: 1rem;

          @media screen and (max-width: $tablet) {
            margin-bottom: 0;
          }
        }

        @media screen and (max-width: $tablet) {
          .block-address {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &_button {
      &_settings {
        button {
          max-width: 225px;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      &_txt {
        font-size: 1.6rem;
        font-weight: $font-medium;

        .link-dashed {
          background-color: transparent;
          border-bottom: none;
          color: $subcategory;
          font-weight: $font-bold;
        }
      }

      &-addresses {
        margin-bottom: 1rem;
      }

      &_agree {
        .profile-info_txt {
          font-size: 1.4rem;
          font-weight: $font-regular;
        }
      }

      &_button {
        position: fixed !important;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $white;
        box-shadow: 0 0 1.3rem rgba($black, 0.15);
        border-radius: 0.5rem 0.5rem 0 0;
        margin-bottom: 0;
        padding: 1rem 2.4rem !important;

        &_settings {
          button {
            max-width: 100%;
          }
        }
      }
    }
  }

  &-form-error {
    color: $red;
    font-size: 1.2rem;
    margin: 0;
    position: absolute;
    bottom: 2rem;
    right: 0;
    width: 100%;
    padding: 0 1.5rem;
    text-align: right;
  }

  &-contact {
    &_cards {
      display: flex;
      align-items: flex-start;

      .checkout-delivery_card {
        flex: 0 0 31.8rem;

        @media screen and (max-width: $smartphone) {
          flex: 0 0 24.5rem;
          width: 24.5rem;

          .card-name {
            font-size: 1.8rem;
          }

          .term {
            display: none;
          }
        }
      }

      &-one {
        background-color: $white;
        border-radius: 0.2rem;
        border: 0.1rem solid $gray-border;
        font-size: 1.6rem;
        flex: 0 0 20.1rem;
        width: 20.1rem;
        margin-left: 3.2rem;
        padding: 2.1rem 2.1rem 2rem;

        .icon {
          display: block;
          margin-bottom: 1rem;

          &-card {
            img {
              height: 2rem;
            }
          }
        }

        .name {
          font-weight: $font-medium;
          margin-bottom: 0.4rem;
        }

        @media screen and (max-width: $smartphone) {
          display: flex;
          margin-left: 0.5rem;
          padding: 1rem 1.5rem 1.1rem;

          .icon {
            margin: 0 1rem 0 0;
          }

          .name {
            margin-bottom: 0;
          }

          .term {
            display: none;
          }
        }
      }
    }
  }

  &-card {
    color: $main-text;

    &_one {
      background-color: $white;
      border: 0.1rem solid rgba($gray-border, 0.5);
      border-radius: 0.2rem;
      box-shadow: 0 0.1rem 0.6rem rgba($shadow, 0.2);
      display: flex;
      align-items: flex-start;
      height: 20rem;
      margin-bottom: 2rem;
      padding: 4rem;
      width: 43.5rem;

      @media screen and (max-width: $smartphone-small) {
        display: block;
        height: auto;
        margin-bottom: 1rem;
        width: 100%;
        padding: 2.9rem 4rem;
      }

      &-number {
        background-color: $white;
        border: 0.1rem solid rgba($gray-border, 0.5);
        border-radius: 0.2rem;
        box-shadow: 0 0.1rem 0.6rem rgba($shadow, 0.2);
        height: 20rem;
        margin-bottom: 3rem;
        padding: 1.9rem 0;
        width: 43.5rem;

        @media screen and (max-width: $smartphone-small) {
          height: 18rem;
          margin-bottom: 0;
          width: 100%;
        }

        .line {
          background-color: $gray-border;
          height: 3rem;
          margin-bottom: 5rem;

          @media screen and (max-width: $smartphone-small) {
            height: 2rem;
          }
        }

        .form_card-copy {
          padding: 0 4rem;
        }
      }
    }

    &_logo {
      margin-right: 6.4rem;

      img {
        height: 3.1rem;

        @media screen and (max-width: $smartphone-small) {
          height: 2.4rem;
        }
      }
    }

    &_address {
      font-size: 1.4rem;
      padding-top: 0.5rem;

      @media screen and (max-width: $smartphone-small) {
        padding-top: 0;
      }

      li {
        margin-top: 1.4rem;
        padding-top: 1.5rem;
        position: relative;

        &:before {
          background-color: $placeholder;
          content: '';
          display: block;
          height: 0.1rem;
          width: 2.5rem;
          position: absolute;
          top: 0;
          left: 0;
        }

        &:first-child {
          padding-top: 0;
          margin-top: 0;

          &:before {
            display: none;
          }
        }

        @media screen and (max-width: $smartphone-small) {
          margin-top: 1rem;
          padding-top: 1rem;
        }
      }
    }
  }

  &-gift {
    color: $main-text;
    max-width: 43.5rem;

    &_status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.2rem;

      .status {
        font-size: 1.6rem;
      }

      @media screen and (max-width: $smartphone-small) {
        display: none;
      }
    }

    &_card {
      background-color: $white;
      border: 0.2rem solid $subcategory;
      border-radius: 0.2rem;
      font-size: 1.6rem;
      padding: 3.5rem 5.6rem 4rem;

      @media screen and (max-width: $smartphone-middle) {
        padding: 3.6rem 3.2rem 2.6rem;
      }

      &-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.8rem;

        @media screen and (max-width: $smartphone-middle) {
          flex-direction: column-reverse;
          margin-bottom: 1.2rem;
        }
      }
    }

    &_price {
      p {
        color: $subcategory;
        font-weight: $font-bold;
        font-size: 3rem;
        margin-bottom: 0;
        padding-top: 0.3rem;

        @media screen and (max-width: $smartphone-middle) {
          padding-top: 0;
        }
      }
    }

    &_logo {
      img {
        width: 9.6rem;

        @media screen and (max-width: $smartphone-middle) {
          width: 8rem;
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        margin-bottom: 2rem;
      }
    }

    &_info {
      border-top: 0.1rem solid rgba($gray-border, 0.5);
      color: $main-text;
      font-size: 1.8rem;
      margin-top: 4rem;
      padding: 3.4rem 0 0;

      h4 {
        font-size: 1.6rem;
        margin-bottom: 1.5rem;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1.8rem;
        }
      }

      ol {
        list-style-position: inside;

        li {
          margin-top: 0.7rem;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        border-top: none;
        font-size: 1.6rem;
        margin-top: 0;
        padding: 2rem 0 0;
      }
    }
  }

  &-order {
    background-color: $white;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    border-radius: 0.8rem;
    font-size: 1.4rem;
    margin-top: 1rem;
    padding: 2.4rem 2.4rem 0;
    position: relative;

    @media screen and (max-width: $tablet) {
      background: #fefefe;
      box-shadow: 0 0.3rem 0.4rem rgba($black, 0.1);
      border-radius: 1.6rem;
      margin-top: 2rem;
      padding: 1.8rem 2rem 2.4rem;
    }

    @media screen and (max-width: $smartphone-smallest) {
      padding: 1.8rem 1.5rem 2.4rem;
    }

    &:first-child {
      margin-top: 0;
    }

    &_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      position: relative;

      &-right {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        position: relative;

        @media screen and (max-width: $tablet-small) {
          display: block;
          margin-top: 1rem;
          text-align: left;
        }

        .btn {
          width: auto;

          @media screen and (max-width: $tablet-small) {
            display: none;
          }
        }
      }

      @media screen and (max-width: $tablet-small) {
        margin-bottom: 1rem;
      }
    }

    &_number {
      display: flex;
      align-items: center;
      font-size: 1.6rem;

      @media screen and (max-width: $tablet) {
        justify-content: space-between;
      }

      .number {
        flex: 0 0 11.2rem;
        width: 11.2rem;
        font-weight: $font-bold;
        margin: 0 1rem 0 0;

        @media screen and (max-width: $tablet) {
          font-size: 1.8rem;
        }
      }

      &-caption {
        color: $add-text;
        display: block;
        font-size: 1.6rem;
        margin-top: 0.5rem;

        @media screen and (max-width: $tablet) {
          color: $main-text;
          font-size: 1.4rem;
          margin-top: 1.2rem;
        }
      }

      .status-price {
        color: $red;
        font-weight: $font-bold;

        @media screen and (max-width: $tablet) {
          color: #919ba9;
          font-size: 1.8rem;
        }
      }
    }

    &_date {
      color: $add-text;

      @media screen and (max-width: $tablet) {
        color: $main-text;
        padding-left: 2rem;
        position: relative;

        &:before {
          background: url(../../images/ic-date.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 1.3rem;
          height: 1.4rem;
          position: absolute;
          top: 0.2rem;
          left: 0;
        }
      }
    }

    &_status {
      margin-left: 1.5rem;

      .caption {
        color: $add-text;
        margin-right: 0.5rem;

        @media screen and (max-width: $tablet) {
          color: $main-text;
        }
      }
    }

    &_item {
      display: flex;
      justify-content: space-between;
      border-top: 0.1rem solid rgba(218, 222, 234, 0.5);
      margin-bottom: 2rem;
      padding-top: 2rem;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }

      &-left {
        display: flex;

        .pic {
          border-radius: 0.2rem 0.2rem 0 0;
          margin-right: 3rem;
          width: 9.6rem;
          height: 5.8rem;
          overflow: hidden;
          position: relative;

          &:after {
            background-color: #fff;
            border-radius: 0.2rem 0.2rem 0 0;
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 9rem;
            height: 0.3rem;
          }

          img {
            width: 100%;
          }
        }

        .info {
          font-weight: $font-medium;

          &-name {
            font-size: 2rem;
            margin-bottom: 0.3rem;

            a {
              color: $main-text;
            }
          }
        }
      }
    }

    &_description {
      display: flex;
      flex-flow: row wrap;
      padding: 0 0 0 4.6rem;
      position: relative;

      &:before {
        background: url(../../images/ic-arrow.svg) 50% 50% no-repeat;
        background-size: 1.2rem 1rem;
        content: '';
        display: block;
        width: 4.6rem;
        height: 4rem;
        position: absolute;
        top: 0;
        left: 0;
      }

      .inner {
        flex-grow: 2;
        position: relative;
        overflow: hidden;
        height: 6.1rem;

        @media screen and (max-width: $smartphone) {
          height: auto;
          overflow: visible;
        }
      }

      .more {
        background-color: $white;
        border-left: 0.1rem solid $gray-border;
        display: block;
        font-weight: $font-medium;
        font-size: 2rem;
        width: 4.5rem;
        height: 4rem;
        line-height: 4rem;
        text-align: right;
        position: absolute;
        top: 2rem;
        right: 0;

        &-in {
          display: none;
        }

        @media screen and (max-width: $smartphone) {
          display: none;

          &-in {
            border-left: none;
            display: block;
            position: relative;
            top: auto;
            right: auto;
            text-align: center;
          }
        }
      }

      &-more {
        padding-right: 4.5rem;
      }

      @media screen and (max-width: $tablet) {
        border: 0.2rem solid #f6f6f6;
        border-radius: 1rem;
        margin-bottom: 2.4rem;
        padding: 1rem 0 2.4rem;

        &:before {
          background: #f6f6f6 url(../../images/ic-arrow-drop-b.svg) 50% 50% no-repeat;
          background-size: 1.5em 0.8rem;
          border-radius: 0 0 1rem 1rem;
          width: 100%;
          height: 2.4rem;
          top: auto;
          bottom: 0;
        }
      }
    }

    &_list {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;

      @media screen and (max-width: $smartphone) {
        position: relative;
        flex-flow: row wrap;
      }
    }

    &_product {
      width: 6rem;
      height: 4rem;
      margin: 0 0 2rem 2rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media screen and (max-width: $smartphone) {
        margin: 0 1rem 1rem 0;
      }
    }

    &_history {
      @media screen and (max-width: $tablet) {
        border: 0.2rem solid #f6f6f6;
        border-radius: 1rem;
        margin-bottom: 2.4rem;
      }

      &-link {
        display: flex;
        align-items: center;
        height: 4rem;
        padding-left: 6.6rem;
        position: relative;

        &:before {
          background: url(../../images/ic-arrow.svg) 50% 50% no-repeat;
          background-size: 1.2rem 1rem;
          content: '';
          display: block;
          width: 4.5rem;
          height: 4rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(180deg);
        }

        span {
          border-bottom: 0.1rem dashed $text;
        }

        @media screen and (max-width: $tablet) {
          background-color: #ebebec;
          border-radius: 1rem 1rem 0 0;
          height: 2.4rem;
          margin: 0 -0.2rem;
          padding-left: 0;

          &:before {
            background: #ebebec url(../../images/ic-arrow-drop-b.svg) 50% 50% no-repeat;
            background-size: 1.5em 0.8rem;
            border-radius: 0 0 1rem 1rem;
            width: 100%;
            height: 2.4rem;
            margin-top: -0.2rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
          }

          span {
            display: none;
          }
        }
      }

      &-item {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        padding-top: 1rem;

        @media screen and (max-width: $tablet) {
          font-size: 1.4rem;
          padding: 0 1rem;
        }

        @media screen and (max-width: $smartphone-small - 60) {
          margin-bottom: 2rem;
        }

        .inner {
          display: flex;
          align-items: center;
          position: relative;

          @media screen and (max-width: $tablet) {
            align-items: center;
          }

          @media screen and (max-width: $smartphone-small - 60) {
            display: block;
          }
        }

        .pic {
          flex: 0 0 6rem;
          width: 6rem;
          height: 6rem;
          margin-right: 1rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .info {
          flex-grow: 2;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: $font-bold;

          @media screen and (max-width: $tablet-small) {
            display: block;
          }

          &-left {
            margin-right: 2rem;

            @media screen and (max-width: $tablet-small) {
              margin: 0 0 0.6rem;
            }
          }

          &-name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            width: 100%;
            max-width: 36rem;
            height: 3.3rem;
            margin-bottom: 0;

            @media screen and (max-width: $tablet-small) {
              font-size: 1.2rem;
              -webkit-line-clamp: 4;
              width: 100%;
              height: auto;
              max-height: 7.2rem;
            }

            @media screen and (max-width: $smartphone-small - 60) {
              max-width: none;
            }

            &_not {
              color: $red;
              font-weight: $font-regular;
              margin: 0.4rem 0 0;

              @media screen and (max-width: $tablet-small) {
                font-size: 1.2rem;
              }
            }
          }

          &-price {
            display: flex;
            margin-bottom: 0;
            flex: 0 0 10rem;
            width: 10rem;
            justify-content: flex-end;

            @media screen and (max-width: $tablet) {
              flex: 0 0 14rem;
              width: 14rem;
            }

            @media screen and (max-width: $tablet-small) {
              flex: 0 0 100%;
              width: 100%;
              justify-content: flex-start;
            }

            .price {
              color: $red;
              margin-left: 0.5rem;

              @media screen and (max-width: $tablet) {
                color: $subcategory;
              }
            }
          }
        }

        .comment {
          margin: 0;

          @media screen and (max-width: $tablet-small) {
            margin-bottom: 1.4rem;
          }

          .caption {
            color: $add-text;
          }
        }

        .buy-cart {
          width: 22.5rem;
          display: flex;
          justify-content: flex-end;

          .btn-buy-sm {
            font-size: 2.6rem;
            width: 3.5rem;
          }

          .quantity-form_inside {
            margin-right: 0;
            width: 10rem;
            display: flex;
            justify-content: flex-end;

            input {
              height: 3.5rem;
            }
          }

          @media screen and (max-width: $smartphone-small - 60) {
            position: absolute;
            top: 1.4rem;
            right: 0;
          }
        }
      }
    }

    &_delivery {
      margin: 0 22rem 4rem 0;

      @media screen and (max-width: $smartphone-middle) {
        margin-right: 0;
      }

      .basket-block_bottle {
        padding: 0.8rem 1rem;

        @media screen and (max-width: $tablet) {
          padding: 0.8rem 1.6rem;
        }
      }

      .delivery {
        background-color: rgba($bg-blue-gray, 0.5);
        border-radius: 0.4rem 0.4rem 0 0;
        color: $add-text;
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 1rem;

        @media screen and (max-width: $tablet) {
          background-color: #e9e9e9;
          border-radius: 0;
          color: $main-text;
          font-size: 1.2rem;
          padding: 0.85rem 1.6rem;
        }
      }

      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $bg-blue-gray;
        border-radius: 0 0 0.4rem 0.4rem;
        font-size: 1.4rem;
        font-weight: $font-bold;
        margin: 0;
        padding: 1rem;

        @media screen and (max-width: $tablet) {
          background-color: #e9e9e9;
          border-radius: 0;
          color: $main-text;
          font-size: 1.2rem;
          margin-top: 0.1rem;
          padding: 0.85rem 1.6rem;
        }
      }

      @media screen and (max-width: $tablet) {
        margin-bottom: 0;
      }
    }

    &_info {
      @media screen and (max-width: $hd - 81) {
        &-info {
          margin-bottom: 2rem;
        }
      }

      @media screen and (max-width: $tablet) {
        background-color: #f6f6f6;
        padding: 1.6rem 0;
        font-size: 1.2rem;
        margin: 0;
      }

      .title {
        font-weight: $font-bold;
        margin-bottom: 1.5rem;

        @media screen and (max-width: $smartphone) {
          margin-bottom: 0.5rem;
        }
      }

      li {
        display: flex;

        @media screen and (max-width: $smartphone) {
          display: block;
          padding: 0.2rem 0;
        }

        .caption {
          color: $add-text;
          display: block;
          margin: 0;
          padding-right: 0.5rem;
          flex: 0 0 45%;
          width: 45%;

          @media screen and (max-width: $smartphone) {
            width: 100%;
          }
        }

        p {
          margin-bottom: 0;
        }
      }

      &-history {
        .status {
          flex: 0 0 55%;
          width: 55%;
          text-align: right;

          @media screen and (max-width: $hd - 81) {
            text-align: left;
          }

          @media screen and (max-width: $smartphone) {
            width: 100%;
          }
        }
      }
    }

    &_courier {
      background-color: $white;
      border: 0.1rem solid $border;
      font-size: 1.8rem;
      padding: 1.2rem 3rem 1.3rem;

      .top {
        color: $subcategory;
        font-weight: $font-medium;
        font-size: 2rem;
        margin-bottom: 0.3rem;
        text-transform: uppercase;
      }

      .name {
        font-weight: $font-medium;
        margin-bottom: 0.2rem;
      }

      @media screen and (max-width: $smartphone) {
        font-size: 1.6rem;
        padding: 1.4rem 2.4rem 1.7rem;
      }
    }

    &.open {
      padding-bottom: 2.4rem;

      .profile-order_history-link {
        margin-bottom: 2rem;
      }
    }

    &.cancelled {
      .profile-order_number .number {
        text-decoration: line-through;
      }
    }

    .btn-repeat {
      display: none;

      @media screen and (max-width: $tablet) {
        display: block;
      }
    }

    &_buttons {
      display: flex;
      justify-content: flex-end;
      margin: 0 -0.5rem 0.5rem;
      width: 100%;

      @media screen and (max-width: $tablet) {
        display: none;
      }

      .btn {
        font-size: 1.5rem;
        height: 3rem;
        margin: 0.5rem 0.5rem 0;
      }
    }
  }

  &-form {
    margin-bottom: 3rem;
    max-width: 60.2rem;

    @media screen and (max-width: $tablet) {
      margin-bottom: 0;
    }

    &-item {
      .form-control {
        border: 0.2rem solid $border;
        border-radius: 0.4rem;

        @media screen and (max-width: $tablet) {
          border: 0.05rem solid #c8c8c8;
          border-radius: 1rem;
        }
      }
    }

    .profile-info_txt,
    .form-group_password {
      width: 100%;
    }

    .profile-info_txt {
      margin-bottom: 1.6rem;
    }

    label.profile-info_label {
      line-height: 4.8rem;

      @media screen and (max-width: $smartphone-middle) {
        line-height: 1.25;
        margin-bottom: 0.5rem;
      }
    }

    .restore-code_block {
      margin-top: -1.5rem;
    }

    @media screen and (max-width: $smartphone) {
      max-width: none;

      &_border {
        border: 0.1rem solid $gray-border;
        margin-bottom: 3rem;
        padding: 3rem 3.5rem 0;
      }
    }
  }

  &-change-pass {
    border-top: 0.1rem solid rgba($gray-border, 0.5);
    margin-top: 4rem;
    padding-top: 2.5rem;

    h3 {
      border-bottom: 0.1rem solid $subcategory;
      font-weight: $font-medium;
      font-size: 2rem;
      padding-bottom: 1.5rem;
      text-transform: uppercase;

      @media screen and (max-width: $smartphone) {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        text-transform: none;
      }
    }

    input[type='password'] {
      margin-bottom: 1rem;
    }

    .new-password {
      margin-top: 0;
    }
  }

  &-password {
    &_note {
      display: flex;
      justify-content: space-between;
      font-family: $font-family-second;
      font-size: 1rem;
      margin-top: -0.5rem;

      li {
        padding: 0 0.3rem;
        text-align: center;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        span {
          display: block;
          color: $green;
          font-size: 1.6rem;
        }
      }
    }
  }

  &-register {
    .form-control {
      margin-bottom: 1rem;
    }

    .profile {
      &-info_row {
        margin-top: 0;
      }

      &-logout a {
        font-family: $font-family-base-bold;
      }

      @media screen and (max-width: $smartphone-small - 100) {
        &-form_border {
          padding: 3rem 0 1rem;
        }
      }
    }

    .repeat-password {
      margin: 2.6rem 0 2rem;

      @media screen and (max-width: $smartphone-middle) {
        padding: 0 3.5rem;
      }
    }

    @media screen and (max-width: $smartphone) {
      border: none;
      margin-bottom: 0;
      padding: 0;
    }
  }

  &-restore {
    .profile {
      &-aside {
        .btn-link {
          margin-bottom: 2rem;
          text-transform: none;
        }
      }

      &-form_border {
        @media screen and (max-width: $smartphone) {
          padding: 3rem 3.5rem;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      .profile {
        &-aside {
          position: relative;
          padding: 0;
          text-align: center;

          h1 {
            text-transform: uppercase;
          }
        }
      }
    }

    @media screen and (max-width: $smartphone) {
      .profile-info_row {
        display: block;
      }
    }

    &_right {
      .title {
        display: none;
      }

      @media screen and (max-width: $tablet) {
        .title {
          border-bottom: 0.1rem solid $subcategory;
          display: block;
          margin-bottom: 2rem;
          padding-bottom: 1.5rem;
          text-align: center;
          text-transform: uppercase;
        }
      }

      @media screen and (max-width: $smartphone) {
        .title {
          padding-bottom: 1.2rem;
        }

        .profile {
          &-form_border {
            padding-bottom: 3rem;
          }

          &-info_row {
            padding: 0;
          }
        }

        .restore-code {
          &_title {
            font-size: 1.8rem;
            margin-bottom: 1rem;
          }

          &_block {
            padding: 2rem 1.8rem;
          }
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        .profile-form .restore-code_block {
          margin-top: 0;
          max-width: 29rem;
        }
      }

      @media screen and (max-width: $smartphone-small - 110) {
        .restore-code_block input {
          width: 4rem;
        }
      }
    }
  }

  &-qrcode {
    border: 0.1rem solid $bg-pic;
    font-weight: $font-medium;
    font-size: 1.6rem;
    display: none;
    align-items: center;
    margin: 1.2rem 0 2rem;
    padding: 1rem;

    &_pic {
      margin-right: 1.6rem;
      width: 2.4rem;

      img {
        width: 100%;
      }
    }

    @media screen and (max-width: $smartphone) {
      display: flex;
    }
  }

  &-credits {
    &_title {
      background-color: #edeff2;
      border-radius: 0.8rem 0.8rem 0 0;
      font-size: 2rem;
      font-weight: $font-bold;
      margin: 0 -3.8rem 1.1rem;
      padding: 2.2rem 3.8rem 2.4rem;

      @media screen and (max-width: $tablet) {
        border-radius: 0;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.8rem;
        margin: 0 -2rem 1.1rem;
        padding: 1.2rem 2rem 1.4rem;
      }
    }

    &_one {
      border-bottom: 0.1rem solid #d8dbdf;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.4rem;
      font-weight: $font-medium;
      padding: 0.9rem 1rem 1.3rem;

      @media screen and (max-width: $smartphone-middle) {
        padding: 0.9rem 0 1.3rem;
      }
    }

    &_number {
      flex: 0 0 10.2rem;
      width: 10.2rem;
      font-weight: $font-bold;
      text-align: right;

      &.plus {
        color: #4cb31c;
      }

      &.minus {
        color: #e0465f;
      }
    }

    &_date {
      color: #919ba9;
      font-size: 1.2rem;
      margin-bottom: 0.4rem;
    }

    &_info {
      flex: 2;

      a {
        text-decoration: underline;
      }
    }
  }

  &-product-review {
    background-color: $white;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    border-radius: 0.8rem;
    font-size: 1.4rem;
    margin-top: 1rem;
    padding: 2.4rem 2.4rem 0;
    position: relative;

    @media screen and (max-width: $tablet) {
      background: #fefefe;
      box-shadow: 0 0.3rem 0.4rem rgba($black, 0.1);
      border-radius: 1.6rem;
      margin-top: 2rem;
      padding: 1.8rem 2rem 2.4rem;
    }

    @media screen and (max-width: $smartphone-smallest) {
      padding: 1.8rem 1.5rem 2.4rem;
    }

    &_history {
      @media screen and (max-width: $tablet) {
        border: 0.2rem solid #f6f6f6;
        border-radius: 1rem;
        margin-bottom: 2.4rem;
      }

      &-link {
        display: flex;
        align-items: center;
        height: 4rem;
        padding-left: 6.6rem;
        position: relative;

        &:before {
          background: url(../../images/ic-arrow.svg) 50% 50% no-repeat;
          background-size: 1.2rem 1rem;
          content: '';
          display: block;
          width: 4.5rem;
          height: 4rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(180deg);
        }

        span {
          border-bottom: 0.1rem dashed $text;
        }

        @media screen and (max-width: $tablet) {
          background-color: #ebebec;
          border-radius: 1rem 1rem 0 0;
          height: 2.4rem;
          margin: 0 -0.2rem;
          padding-left: 0;

          &:before {
            background: #ebebec url(../../images/ic-arrow-drop-b.svg) 50% 50% no-repeat;
            background-size: 1.5em 0.8rem;
            border-radius: 0 0 1rem 1rem;
            width: 100%;
            height: 2.4rem;
            margin-top: -0.2rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
          }

          span {
            display: none;
          }
        }
      }

      &-item {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        padding-top: 1rem;

        @media screen and (max-width: $tablet) {
          font-size: 1.4rem;
          padding: 0 1rem;
        }

        @media screen and (max-width: $smartphone-small - 60) {
          margin-bottom: 2rem;
        }

        .inner {
          display: flex;
          position: relative;

          @media screen and (max-width: $tablet) {
            align-items: center;
          }

          @media screen and (max-width: $smartphone-small - 60) {
            display: table;
          }
        }

        .pic {
          flex: 0 0 6rem;
          width: 6rem;
          height: 6rem;
          margin-right: 1rem;
          overflow: hidden;

          @media screen and ((max-width: $tablet-small) or (max-width: $smartphone-small - 60)) {
            display: table-cell;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .info {
          flex-grow: 2;
          display: flex;
          justify-content: space-between;
          font-weight: $font-bold;

          @media screen and ((max-width: $tablet-small) or (max-width: $smartphone-small - 60)) {
            display: table-cell;
            vertical-align: top;
            padding-left: 0.5rem;
          }

          &-left {
            margin-right: 2rem;

            .comment {
              border-left: 0.3rem solid $border;
              padding-left: 1rem;
              margin-bottom: 0.5rem;
              font-weight: normal;

              .product-reviews_rating {
                font-size: 1.1rem;

                img {
                  width: 1.2rem;
                }
              }
            }

            .btn.leave-review {
              width: fit-content;
              font-size: 1.3rem;
              padding: 0 0.5rem;
              height: 2.5rem;
            }

            @media screen and (max-width: $tablet-small) {
              margin: 0 0 0.6rem;
            }
          }

          &-name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            width: 100%;
            height: auto;
            margin-bottom: 0.5rem;

            @media screen and (max-width: $tablet-small) {
              font-size: 1.2rem;
              -webkit-line-clamp: 4;
              width: 100%;
              height: auto;
              max-height: 7.2rem;
            }

            @media screen and (max-width: $smartphone-small - 60) {
              max-width: none;
            }

            &_not {
              color: $red;
              font-weight: $font-regular;
              margin: 0.4rem 0 0;

              @media screen and (max-width: $tablet-small) {
                font-size: 1.2rem;
              }
            }
          }
        }

        .comment {
          margin: 0;

          @media screen and (max-width: $tablet-small) {
            margin-bottom: 1.4rem;
          }

          .caption {
            color: $add-text;
          }
        }
      }
    }
  }

  &-subscription {
    background-color: $white;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    border-radius: 0.8rem;
    font-size: 1.4rem;
    margin-top: 1rem;
    padding: 2.4rem 2.4rem 0;
    position: relative;

    @media screen and (max-width: $tablet) {
      background: #fefefe;
      box-shadow: 0 0.3rem 0.4rem rgba($black, 0.1);
      border-radius: 1.6rem;
      margin-top: 2rem;
      padding: 1.8rem 2rem 2.4rem;
    }

    @media screen and (max-width: $smartphone-smallest) {
      padding: 1.8rem 1.5rem 2.4rem;
    }

    &_history {
      @media screen and (max-width: $tablet) {
        border: 0.2rem solid #f6f6f6;
        border-radius: 1rem;
        margin-bottom: 2.4rem;
      }

      &-link {
        display: flex;
        align-items: center;
        height: 4rem;
        padding-left: 6.6rem;
        position: relative;

        &:before {
          background: url(../../images/ic-arrow.svg) 50% 50% no-repeat;
          background-size: 1.2rem 1rem;
          content: '';
          display: block;
          width: 4.5rem;
          height: 4rem;
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(180deg);
        }

        span {
          border-bottom: 0.1rem dashed $text;
        }

        @media screen and (max-width: $tablet) {
          background-color: #ebebec;
          border-radius: 1rem 1rem 0 0;
          height: 2.4rem;
          margin: 0 -0.2rem;
          padding-left: 0;

          &:before {
            background: #ebebec url(../../images/ic-arrow-drop-b.svg) 50% 50% no-repeat;
            background-size: 1.5em 0.8rem;
            border-radius: 0 0 1rem 1rem;
            width: 100%;
            height: 2.4rem;
            margin-top: -0.2rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(180deg);
          }

          span {
            display: none;
          }
        }
      }

      &-item {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        padding-top: 1rem;

        @media screen and (max-width: $tablet) {
          font-size: 1.4rem;
          padding: 0 1rem;
        }

        @media screen and (max-width: $smartphone-small - 60) {
          margin-bottom: 2rem;
        }

        .inner {
          display: flex;
          position: relative;

          @media screen and (max-width: $tablet) {
            align-items: center;
          }

          @media screen and (max-width: $smartphone-small - 60) {
            display: table;
          }
        }

        .pic {
          flex: 0 0 6rem;
          width: 6rem;
          height: 6rem;
          margin-right: 1rem;
          overflow: hidden;

          @media screen and ((max-width: $tablet-small) or (max-width: $smartphone-small - 60)) {
            display: table-cell;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .info {
          flex-grow: 2;
          display: flex;
          justify-content: space-between;
          font-weight: $font-bold;

          @media screen and ((max-width: $tablet-small) or (max-width: $smartphone-small - 60)) {
            display: table-cell;
            vertical-align: top;
            padding-left: 0.5rem;
          }

          &-left {
            margin-right: 2rem;

            .subscription-message {
              margin-top: 1rem;

              .btn-more {
                color: $subcategory;
                font-weight: normal;
              }

              img {
                display: inline;
                margin-right: 0.5rem;
              }

              @media screen and ((max-width: $smartphone-middle) or (max-width: $tablet)) {
                white-space: normal;
              }
            }

            @media screen and (max-width: $tablet-small) {
              margin: 0 0 0.6rem;
            }
          }

          &-name {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            width: 100%;
            height: auto;
            margin-bottom: 0.5rem;

            @media screen and (max-width: $tablet-small) {
              font-size: 1.2rem;
              -webkit-line-clamp: 4;
              width: 100%;
              height: auto;
              max-height: 7.2rem;
            }

            @media screen and (max-width: $smartphone-small - 60) {
              max-width: none;
            }

            &_not {
              color: $red;
              font-weight: $font-regular;
              margin: 0.4rem 0 0;

              @media screen and (max-width: $tablet-small) {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }
}

.profile-block_title + .profile-order {
  margin-top: 0;
}

.status {
  &-progress {
    color: $subcategory;
    font-size: 1.8rem;
    text-transform: uppercase;
  }

  &-done {
    color: $green;
    font-size: 1.8rem;
    text-transform: uppercase;
  }

  &-cancel {
    color: $red;
    font-size: 1.8rem;
    text-transform: uppercase;
  }
}

.title-link {
  display: flex;
  justify-content: space-between;

  a {
    color: $add-text;
  }
}

.social {
  &-login {
    a {
      background-color: $white;
      border: 0.1rem solid $gray-border;
      border-radius: 0.2rem;
      color: $main-text;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      padding: 1rem;
      width: 100%;
      text-align: center;

      span[class*='icon-'] {
        margin-right: 2rem;

        @media screen and (max-width: $smartphone-middle) {
          margin-right: 1rem;
        }
      }

      span[class*='font-'] {
        margin-left: 0.5rem;
      }

      &:hover {
        border: none;
        color: $white;

        &.social-facebook {
          background-color: #5465ac;

          .icon-facebook-svg {
            background-image: url(../../images/ic-facebook-white.svg);
          }
        }

        &.social-google {
          background-color: #3f85ff;

          .icon-google-svg {
            background-image: url(../../images/ic-google-white.svg);
          }
        }
      }

      @media screen and (max-width: $smartphone) {
        font-size: 1.6rem;
        height: 4rem;
      }
    }
  }
}

.restore-code {
  &_title {
    font-weight: $font-medium;
    font-size: 2.4rem;
    margin: -1.1rem 0 3.5rem;
  }

  &_block {
    background-color: $bg-blue-gray;
    border: 0.1rem solid #e8eef2;
    border-radius: 0.4rem;
    display: flex;
    justify-content: space-between;
    padding: 2.8rem 4.2rem;
    width: 100%;

    input {
      box-shadow: 0 0.1rem 0.6rem 0 rgba($shadow, 0.2);
      border-radius: 0.4rem;
      margin: 0;
      width: 5rem;
    }

    &.error {
      border-color: $red;
      position: relative;
      margin-bottom: 2rem;

      .form-error {
        bottom: -2rem;
      }
    }
  }
}

.back-link {
  color: $main-text;
  display: inline-block;
  margin-bottom: 2.5rem;
  padding-left: 2.7rem;
  position: relative;

  @media screen and (max-width: $tablet) {
    color: $subcategory;
    font-weight: $font-bold;
    padding-left: 2.2rem;
  }

  &:before {
    background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
    background-size: cover;
    content: '';
    display: block;
    width: 1.2rem;
    height: 0.7rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(180deg);

    @media screen and (max-width: $tablet) {
      background-image: url(../../images/ic-arrow_b.svg);
      width: 1.5rem;
      height: 1rem;
      transform: translateY(-50%) rotate(0deg);
    }
  }
}

.section-register {
  .profile {
    @media screen and (max-width: $tablet) {
      &-aside {
        position: relative;
        padding: 0;
      }
    }

    @media screen and (max-width: $smartphone) {
      &-info_label {
        display: none;
      }
    }
  }

  .registration-item-note {
    .form-note {
      margin-top: -2rem;
      @media screen and ((max-width: $smartphone-small) or (max-width: $smartphone-middle) or (max-width: $smartphone) or (max-width: $tablet)) {
        margin-top: -1rem;
      }
    }
  }

  .code-confirmation {
    padding-top: 1.5rem;

    .subtitle {
      margin-bottom: 1.5rem;
      text-transform: uppercase;
    }

    .note {
      padding-bottom: 0.5rem;
    }

    .reg-error {
      color: $red;
    }

    .reg-success {
      color: $dark-green;
    }

    .btn-resend {
      margin-left: 1rem;
      color: $subcategory-light;
      border-bottom: 0.1rem dashed $subcategory-light;
      transition: all 0.2s linear;
    }

    &_submit {
      width: auto;
      display: inline-flex;
    }

    &_items {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-flex;

      li {
        margin-right: 1rem;

        input {
          width: 4rem;
          padding: 0 1.4rem;

          &[type=number] {
            -moz-appearance: textfield;
          }

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
  }
}
