.black {
  color: $black;
}

.white {
  color: $white;
}

.blue {
  color: $blue;
}

.main-text {
  color: $main-text;
}

.font-regular {
  font-family: $font-family-base;
}

.font-medium {
  font-weight: $font-medium;
}

.font-semibold {
  font-weight: $font-semibold;
}

.font-bold {
  font-weight: $font-bold;
}

.bold {
  font-weight: $font-bold;
}

.nowrap {
  white-space: nowrap;
}

.hide {
  display: none !important;

  &-sm {
    @media screen and (max-width: $smartphone) {
      display: none !important;
    }
  }

  &-md {
    @media screen and (max-width: $tablet-small) {
      display: none !important;
    }
  }

  &-lg {
    @media screen and (max-width: $tablet) {
      display: none !important;
    }
  }
}

.show {
  &-md {
    @media screen and (max-width: $tablet-small) {
      display: block !important;
    }
  }

  &-lg {
    @media screen and (max-width: $tablet) {
      display: block !important;
    }
  }
}

.react-multi-carousel-dot-list {
  .owl-dot {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 100%;
    background-color: $bg-gray;
    margin: 0 0.5rem;
    transition: all 0.2s linear;

    &.active {
      background-color: $text;
    }
  }
}

.loading-cart {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000 !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($white, 0.5);
    filter: blur(20px);
    -webkit-filter: blur(20px);
    z-index: 1;
  }

  div {
    z-index: 3;
  }
}
