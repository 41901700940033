.brands {
  &-page {
    background-color: $bg;
    padding-bottom: 4.4rem;

    @media screen and (max-width: $tablet) {
      padding-bottom: 10rem;
    }

    .paging {
      margin: 2.3rem 0 0;
    }
  }

  &-search {
    flex: 0 0 32rem;
    width: 32rem;
    position: relative;

    @media screen and (max-width: $smartphone-middle) {
      margin-top: 1rem;
      width: 100%;
    }

    input {
      background: #f5f8fb;
      border: 0.05rem solid #d5dde0;
      border-radius: 0.4rem;
      font-size: 1.4rem;
      height: 4rem;
      padding: 0 3.5rem 0 4.9rem;

      &::-webkit-input-placeholder {
        color: #919ba9;
      }

      &::-moz-placeholder {
        color: #919ba9;
      }

      &:-moz-placeholder {
        color: #919ba9;
      }

      &:-ms-input-placeholder {
        color: #919ba9;
      }
    }

    &:before {
      background: url(../../images/ic-search.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      position: absolute;
      top: 1rem;
      left: 1.7rem;
      opacity: 0.85;
    }

    .search-close {
      position: absolute;
      top: 50%;
      right: 0.9rem;
      transform: translateY(-50%);
    }
  }

  &-top {
    background-color: $white;
    margin-bottom: 1.8rem;
    padding: 1.1rem 0;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: $smartphone-middle) {
        display: block;
        height: auto;
      }

      h1 {
        flex: 2;
        margin: 0;
      }
    }
  }

  &-logo {
    background-color: $white;
    border-radius: 0.8rem;
    width: 19.7rem;
    height: 14rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $smartphone-small) {
      width: 15rem;
    }

    @media screen and (max-width: $smartphone-smallest - 25) {
      width: 19.7rem;
    }

    img {
      width: 100%;
      max-width: 15rem;
      height: 100%;
      max-height: 10rem;
      object-fit: contain;

      @media screen and (max-width: $smartphone-small) {
        max-width: 12rem;
      }

      @media screen and (max-width: $smartphone-smallest - 25) {
        max-width: 15rem;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -1rem;

    li {
      padding: 1rem;
    }
  }

  &-modal {
    flex: 2;

    &_logo {
      position: absolute;
      top: 1.5rem;
      left: 5.6rem;
      height: 10rem;

      @media screen and (max-width: $smartphone-middle) {
        left: 3rem;
        max-width: calc(100% - 6rem);
      }

      img {
        width: auto;
        height: 100%;

        @media screen and (max-width: $smartphone-middle) {
          max-width: 100%;
          object-fit: contain;
        }
      }
    }

    &_name {
      color: #2d3541;
      font-size: 2.4rem;
      font-weight: $font-bold;
      margin-bottom: 2.3rem;
    }

    &_text {
      color: $black;
      font-size: 1.4rem;
    }

    &_content {
      max-height: 42rem;
      overflow-y: auto;

      @media screen and (max-width: $tablet) {
        height: 100%;
        max-height: none;
      }
    }

    &_buttons {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 3rem;
      left: 0;
      width: 100%;

      @media screen and (max-width: $smartphone-small) {
        flex-direction: column-reverse;
        align-items: center;
        bottom: 1.5rem;
      }

      .btn {
        font-size: 1.4rem;
        margin: 0 0.75rem;
        width: auto;
        min-width: 15.4rem;

        @media screen and (max-width: $smartphone-small) {
          margin-top: 1rem;
        }
      }
    }

    &_modal {
      display: flex;
      background-color: $white;
      border-radius: 0.8rem;
      width: calc(100% - 3rem);
      height: auto;
      max-width: 74rem;
      max-height: 66rem;
      margin: 0 auto;
      overflow: hidden;
      padding: 13rem 5.6rem 11rem;
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);

      @media screen and (max-width: $tablet) {
        max-height: calc(100% - 3rem);
      }

      @media screen and (max-width: $smartphone-middle) {
        padding: 13rem 3rem 11rem;
      }

      @media screen and (max-width: $smartphone-small) {
        padding-bottom: 13rem;
      }
    }
  }
}

.brand-page {
  .section-main {
    @media screen and (max-width: $tablet) {
      display: block;
    }
  }

  .brand-page-header:not(:has(.brand-page-logo-no-description)) {
    padding: 20px 30px;
  }

  .brand-page-logo {
    max-height: 150px;
    max-width: 23rem;
    float: left;
    padding: 0 20px 20px 0;
    @media screen and (max-width: $tablet) {
      float: none;
      margin: 0 auto;
      padding: 0 30px 20px;
    }
  }

  .brand-page-logo-no-description {
    max-height: 110px;
    max-width: 23rem;
    float: none;
    margin: 0 auto;
  }
}
