.item {
  &-food {
    background-color: $white;
    margin: 0 auto;
    padding: 0.5rem 0.9rem;
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 21rem;
    text-align: center;

    border: 0.3rem solid $catalog-item-border;
    border-radius: 1.6rem;

    &:hover {
      box-shadow: 0 0 0.5rem rgba($black, 0.5);
    }

    @media screen and (max-width: $tablet) {
      background-color: #fefefe;
      border: none;
      box-shadow: 0 0.3rem 0.4rem rgba($black, 0.1);
      border-radius: 1.6rem;
      padding: 0.8rem;
      text-align: left;
    }

    &_pic {
      cursor: pointer;
      width: 15rem;
      height: 15rem;
      margin: 0 auto 1rem;
      z-index: 2;

      @media screen and (max-width: $tablet) {
        margin: 0 0 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        a {
          width: 100%;
          height: 100%;
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        height: 11.6rem;
        a {
          width: 100%;
          height: 100%;
        }
      }

      a img,
      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        cursor: pointer;
        pointer-events: none;
      }

      .btn-wish {
        position: absolute;
        top: 12rem;
        left: 1rem;
        width: 4.8rem;

        @media screen and (max-width: $tablet) {
          left: 0;
        }

        @media screen and (max-width: $smartphone-middle) {
          top: 9rem;
        }
      }

      .btn-buy-sm {
        position: absolute;
        top: 12rem;
        right: 1rem;
        width: 3.5rem;
        align-items: normal;

        &.btn-plus {
          color: #ffffff;
          font-size: 3rem;
        }

        @media screen and (max-width: $tablet) {
          right: 0.8rem;
        }

        @media screen and (max-width: $smartphone-middle) {
          right: 0.8rem;
          top: 9rem;
        }
      }

      .quantity-form_inside {
        margin: 0;
        position: absolute;
        top: 12rem;
        right: 1rem;
        width: 10.5rem;
        height: 3.5rem;
        align-items: center;

        @media screen and (max-width: $tablet) {
          right: 0.8rem;
          input {
            font-size: 1.2rem;
          }
        }

        @media screen and (max-width: $smartphone-middle) {
          top: 9rem;
          right: 0.8rem;
          input {
            font-size: 1.2rem;
          }
        }
      }

      .organic {
        &-items {
          position: absolute;
          top: 0;
          left: 0.5rem;
          display: flex;
          flex-wrap: wrap;

          li {
            margin-left: 0.4rem;
            width: 3.8rem;
            height: 3.8rem;

            @media screen and (max-width: $tablet) {
              width: 2.4rem;
              height: 2.4rem;
            }

            @media screen and (max-width: $smartphone-middle) {
              margin-left: 0.2rem;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      &.item-sale {
        .organic-items {
          max-width: 13rem;

          @media screen and (max-width: $tablet) {
            max-width: 14.5rem;
          }

          @media screen and (max-width: $tablet-small) {
            max-width: none;
            margin-right: 6.5rem;
          }

          @media screen and (max-width: $smartphone-middle) {
            margin-right: 5.5rem;
          }
        }
      }
    }

    &_info {
      .weight {
        margin: 0 0 2rem;

        @media screen and (max-width: $smartphone) {
          margin: 0 0 1rem;
        }

        &-unit {
          margin-bottom: 0.3rem;

          span {
            background-color: $add-text;
            border-radius: 0.8rem;
            color: $white;
            display: inline-block;
            font-size: 1.2rem;
            font-weight: $font-semibold;
            padding: 0.2rem 0.6rem;

            @media screen and (max-width: $smartphone-middle) {
              font-size: 1rem;
            }
          }

          @media screen and (max-width: $tablet) {
            text-align: center;
          }
        }
      }
    }

    h4, h3 {
      font-size: 1.4rem;
      font-weight: normal;
      height: 4rem;
      line-height: 1.4;
      margin-bottom: 0.3rem;
      text-transform: none;

      @media screen and (max-width: $tablet) {
        font-size: 1.4rem;
        line-height: 1.333;
        margin-bottom: 1rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.2rem;
        height: 3.2rem;
      }

      .shortDesc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &_add {
      font-size: 1.3rem;
      display: none;

      .caption {
        color: $add-text;
        display: inline-block;
        margin-right: 0.5rem;
      }
    }

    &_price {
      white-space: nowrap;
      padding: 0 0.5rem;

      @media screen and (max-width: $tablet) {
        padding: 0;

        .btn-buy {
          background: $subcategory url(../../images/ic-basket_w-m.svg) 50% 50% no-repeat;
          background-size: 1.7rem 1.7rem;
          border-color: $subcategory;
          border-radius: 0.8rem;
          margin: 0;
          width: calc(100% - 1.6rem);
          height: 3.2rem;
          position: absolute;
          bottom: 0.7rem;
          left: 0.8rem;

          span,
          svg {
            display: none;
          }
        }
      }

      .number {
        display: block;
        font-size: 2.4rem;
        font-weight: $font-bold;
        position: relative;

        @media screen and (max-width: $tablet) {
          font-size: 1.4rem;
        }

        &-unit {
          color: $add-text;
          font-size: 1.3rem;

          @media screen and (max-width: $tablet) {
            color: $main-text;
            display: block;
            font-size: 1.2rem;
            opacity: 1;
          }

          &.sale {
            font-size: 1.2rem;
            margin-right: 0.6rem;
            opacity: 0.6;
            text-decoration: line-through;

            @media screen and (max-width: $tablet) {
              display: none;
            }
          }
        }
      }

      .price {
        margin-bottom: 1rem;

        @media screen and (max-width: $tablet) {
          min-height: 0.5rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-block {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 3rem;
          position: relative;

          @media screen and (max-width: $tablet) {
            display: block;
            height: auto;
          }
        }

        .available {
          display: inline-block;
          font-size: 1.3rem;
          margin-bottom: 0.3rem;

          @media screen and (max-width: $tablet) {
            display: none;
          }
        }

        .info-center {
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.5;
          font-size: 1.4rem;
          height: 4.8rem;

          @media screen and (max-width: $tablet) {
            display: block;
            height: auto;
            font-size: 1.2rem;
            white-space: normal;
          }
        }

        &-sale {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;

          .number {
            color: $add-text;
            font-size: 1.4rem;
            margin: 0 0.5rem;
            position: relative;
            text-decoration: line-through $red;

            @media screen and (max-width: $tablet) {
              font-size: 1.2rem;
              margin: 0;
            }

            &-sale {
              color: $red;
              font-size: 2.4rem;
              font-weight: $font-bold;
              margin: 0 0.5rem;

              @media screen and (max-width: $tablet) {
                font-size: 1.4rem;
              }
            }
          }
        }
      }

      .quantity-form_inside {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 15rem;

        @media screen and (max-width: $tablet) {
          border: 0.1rem solid $subcategory;
          border-radius: 0.8rem;
          width: calc(100% - 1.6rem);
          position: absolute;
          bottom: 0.7rem;
          left: 0.8rem;

          .btn {
            &-minus,
            &-plus {
              border: none;
              flex: 0 0 4.8rem;
              width: 4.8rem;
              height: 3rem;
              padding-bottom: 0.4rem;
            }

            &-minus {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: transparent;
              color: #919ba9;
              font-size: 3.2rem;
            }

            &-plus {
              background-color: $subcategory;
              border-radius: 0.7rem;
              color: $white;
              font-size: 2.6rem;
              line-height: 1;
            }
          }

          input {
            flex: 2;
            background-color: $white;
            border: none;
            color: $subcategory;
            font-size: 1.2rem;
            height: 3rem;
          }
        }
      }

      .subscription-message {
        margin-top: 1rem;

        .btn-more {
          color: $subcategory;
        }

        img {
          display: inline;
          margin-right: 0.5rem;
        }

        @media screen and ((max-width: $smartphone-middle) or (max-width: $tablet)) {
          white-space: normal;
        }
      }
    }

    &_empty {
      background-color: #f9f9f9;
      border: none;
      box-shadow: none;
      height: 42.4rem;
    }

    &_no {
      .item-food {
        &_pic {
          opacity: 0.3;
          cursor: default;
        }

        &_end {
          color: $shadow;
          position: absolute;
          bottom: 3rem;
          left: 0;
          width: 100%;
          padding: 0 2rem;

          p {
            margin-bottom: 0.6rem;
          }

          @media screen and (max-width: $hd) {
            bottom: 2rem;
            padding: 0 1.2rem;
          }

          @media screen and (max-width: $smartphone) {
            bottom: 1rem;
            padding: 0 0.8rem;
          }
        }
      }

      a, .product-title-item:hover {
        cursor: default;
        color: $text;
      }

      .btn-wish:hover {
        background: transparent url(../../images/ic-heart.svg) 50% 50% no-repeat;
        cursor: default;
      }

      .btn-plus:hover {
        cursor: default;
      }

      h4,
      .item-food_pic {
        opacity: 0.5;
      }
    }

    &_buy {
      .item-food {
        &_basket {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: rgba($subcategory, 0.6);
          color: $white;
          width: 18.6rem;
          padding: 1rem;
          border-radius: 0.6rem;

          @media screen and (max-width: $tablet) {
            display: none;
          }

          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            border-width: 1rem 0.8rem 0;
            border-top-color: rgba($subcategory, 0.6);
            position: absolute;
            bottom: -1rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
          }

          span {
            font-weight: $font-bold;
          }
        }
      }

      .item-status {
        top: 6.8rem;

        @media screen and (max-width: $tablet) {
          top: 1.7rem;
        }
      }

      .organic-items {
        top: 6.8rem;

        @media screen and (max-width: $tablet) {
          top: 0;
        }
      }

      @media screen and (max-width: $tablet) {
        .item-food {
          &_main {
            border-top: none;
            border-radius: 0;
            padding: 0;
          }
        }
      }
    }
  }

  &-carousel {
    height: 100%;

    .item-food {
      height: 100%;

      &_pic {
        width: 11.6rem;
        height: 11.6rem;

        .btn {
          &-wish {
            top: 13rem;
            right: 2rem;
            width: 2rem;
            height: 1.9rem;
            padding: 0;
          }
        }
      }

      .btn {
        &-buy {
          width: 4.8rem;

          .icon-basket {
            margin-right: 0;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  &-status {
    position: absolute;
    top: 0;
    right: 1.2rem;
    border-radius: 0.4rem;
    display: block;
    font-size: 1.4rem;
    font-weight: $font-bold;
    padding: 0.2rem 0.5rem;
    text-transform: uppercase;

    @media screen and (max-width: $tablet) {
      top: 1.7rem;
      padding: 0.25rem 0.25rem 0.1rem 0.6rem;
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1rem;
    }

    &_sale {
      background-color: #fdd6de;
      color: $red;

      .sale-date {
        font-size: 1.1rem;
        text-transform: none;
        font-weight: normal;
        padding-right: 0.3rem;
        margin-right: 0.3rem;
        border-right: solid 0.1rem #f5afaf;

        &_bg {
          @extend .sale-date;
          font-size: 1.5rem;
          padding-right: 0.5rem;
          margin-right: 0.5rem;
          @media screen and ((max-width: $tablet-middle) or (max-width: $tablet-small) or (max-width: $tablet)) {
            vertical-align: middle;
          }
        }

        @media screen and ((max-width: $smartphone-middle) or (max-width: $smartphone-small) or (max-width: $smartphone)) {
          font-size: 1.1rem;
          vertical-align: middle;
        }
      }
    }
  }

  @media screen and (max-width: $tablet) {
    &-food.item-weight {
      .item-food_price {
        .price {
          .item-food_price {
            margin-top: 0.3rem;
          }

          &-sale {
            display: block;

            .number {
              display: inline-block;

              &-sale {
                display: block;
                margin-left: 0;
              }
            }
          }
        }

        .number-unit.sale {
          display: block;
        }

        &.sale {
          .price {
            align-items: flex-start;
          }
        }
      }
    }
  }
}

.available {
  &.yes {
    color: $green;
    padding-left: 1.8rem;
    position: relative;

    &:before {
      background: url(../../images/ic-check-mark.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.2rem;
      height: 1rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: $smartphone) {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
