.shop {
  &-about {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: $tablet - 91) {
      display: block;
    }

    &_pic {
      flex: 0 0 55.1rem;
      width: 55.1rem;
      margin: 0 3.7rem 3.7rem 0;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        @media screen and (max-width: $hd) {
          object-fit: contain;
          transform: scale(1.2);
        }
      }

      @media screen and (max-width: $hd) {
        flex: 0 0 40rem;
        width: 40rem;
      }

      @media screen and (max-width: $tablet - 91) {
        margin-right: 0;
      }

      @media screen and (max-width: $smartphone-middle) {
        margin-bottom: 1.5rem;
        width: 100%;
      }
    }

    &_content {
      position: relative;

      h3 {
        font-size: 3rem;
        text-align: left;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 2.4rem;
        }
      }

      .btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 6rem;
        width: auto;

        @media screen and (max-width: $tablet - 91) {
          position: relative;
          margin-bottom: 3rem;
        }

        @media screen and (max-width: $smartphone-middle) {
          width: 100%;
        }
      }
    }

    &_address {
      font-size: 1.8rem;
      padding: 0 0 1.2rem 3.6rem;
      position: relative;

      &:before {
        background: url(../../images/ic-map.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 2.1rem;
        height: 2.7rem;
        position: absolute;
        top: 0;
        left: 0;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.6rem;
        margin-bottom: 0;
        padding: 0 0 2.3rem 2.3rem;

        &:before {
          width: 1.5rem;
          height: 1.9rem;
        }
      }
    }

    &_text {
      font-size: 1.8rem;
      line-height: 1.56;
      margin-bottom: 4.5rem;

      p {
        margin-bottom: 0;
      }

      @media screen and (max-width: $smartphone-middle) {
        margin-bottom: 2.4rem;
      }
    }

    &_info {
      display: flex;
      padding: 2.1rem 0;

      .subtitle {
        color: $add-text;
      }

      .info {
        &-time {
          margin-bottom: 3rem;
        }

        &-info {
          p {
            margin: 1.6rem 0 0;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }

      @media screen and (max-width: $hd) {
        padding: 2.1rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        display: block;
        padding: 2.1rem 3rem;
      }
    }

    &_delivery {
      flex: 0 0 40%;
      width: 40%;
      margin-right: 4rem;

      @media screen and (max-width: $smartphone) {
        flex: 0 0 50%;
        width: 50%;
      }

      @media screen and (max-width: $smartphone-middle) {
        flex: 0 0 100%;
        width: 100%;
        margin: 0 0 2rem;
      }
    }

    &_pay {
      border-left: 0.1rem solid $gray-border;
      flex-grow: 2;
      padding-left: 3.2rem;

      .main-text {
        margin-top: 2.4rem;

        &:first-child {
          margin-top: 0;
        }
      }

      .phone {
        font-weight: $font-bold;
      }

      ul {
        display: flex;
        margin-left: -2.5rem;

        li {
          padding: 2.4rem 0 0 2.5rem;
        }

        @media screen and (max-width: $hd) {
          display: block;
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        border-left: none;
        padding-left: 0;
      }
    }
  }
}

.section-avia {
  h2 {
    font-size: 4rem;
    line-height: 1.25;
    margin: 0 auto 1rem;
    text-align: center;

    @media screen and (max-width: $tablet) {
      font-size: 3rem;
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 2rem;
    }
  }

  &_subtitle {
    font-size: 1.8rem;
    margin-bottom: 4.4rem;
    text-align: center;

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }

  .shop-about {
    margin-bottom: 4rem;

    @media screen and (max-width: $tablet-small) {
      &_content {
        margin-top: 2rem;
      }
    }

    @media screen and (max-width: $smartphone) {
      margin-bottom: 0;

      &_text {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: $smartphone-middle) {
      &_text {
        font-size: 1.6rem;
        line-height: 1.5;
      }
    }
  }

  @media screen and (max-width: $smartphone) {
    .static-links {
      margin-bottom: 3rem;
    }
  }
}

.avia {
  &-week {
    background-color: $gray;
    margin-bottom: 4rem;
    padding: 3rem;

    &_container {
      display: flex;
      justify-content: space-between;
      max-width: 113.4rem;
      margin: 0 auto;

      @media screen and (max-width: $hd) {
        flex-wrap: wrap;
        justify-content: center;
        max-width: 90rem;
      }

      @media screen and (max-width: $tablet-small) {
        flex-wrap: nowrap;
        flex-direction: column;
      }
    }

    @media screen and (max-width: $tablet-small) {
      padding: 1.5rem;
      margin: 0 -15px 2rem;
    }
  }

  &-day {
    background-color: $white;
    border: 0.1rem solid $gray-border;
    border-radius: 0.2rem;
    font-size: 1.6rem;
    line-height: 1.13;
    flex: 0 0 15rem;
    width: 15rem;
    height: 25rem;
    margin: 0 0.4rem;
    padding: 0.4rem;
    text-align: center;

    @media screen and (max-width: $hd) {
      margin: 0.4rem;
    }

    @media screen and (max-width: $tablet-small) {
      flex: 0 0 100%;
      width: 100%;
      display: flex;
      height: 7.5rem;
      margin: 0.5rem 0;
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.4rem;

      .fish {
        width: 5.8rem;
      }

      .airplane {
        width: 6.7rem;
      }
    }

    &_title {
      background-color: $blue-gray;
      border-radius: 0.2rem 0.2rem 0 0;
      color: $main-text;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 7rem;
      font-weight: $font-medium;
      font-size: 2.4rem;
      position: relative;

      @media screen and (max-width: $tablet-small) {
        border-radius: 0.4rem 0 0 0.4rem;
        height: 100%;
        flex: 0 0 7rem;
        width: 7rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.8rem;
        flex: 0 0 5rem;
        width: 5rem;
      }
    }

    &_block1 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8.5rem;
      padding: 0 0.4rem;

      @media screen and (max-width: $tablet-small) {
        height: 100%;
        flex: 1 0 0;
      }
    }

    &_block2 {
      border-top: 0.1rem solid $gray-border;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8.4rem;
      padding: 0 0.4rem;

      @media screen and (max-width: $tablet-small) {
        height: 100%;
        flex: 1 0 0;
        border-top: none;
        border-left: 0.1rem solid $gray-border;
      }
    }
  }

  &-about {
    &_pic {
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
      }
    }
  }

  &-timetable {
    border: 0.1rem solid $gray-border;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: space-between;
    padding: 3.8rem 5rem 2.2rem;

    &_one {
      font-weight: $font-medium;

      .type {
        text-transform: uppercase;
      }

      p {
        margin: 0.2rem 0 0;
      }

      .number {
        color: $subcategory;
        font-size: 4rem;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 2.4rem;
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        .unit {
          display: inline-block;
          line-height: 1;
          width: 4.7rem;
        }
      }
    }

    @media screen and (max-width: $smartphone) {
      margin-bottom: 2rem;
      padding: 2rem 2rem 1rem;
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.2rem;
      padding: 1.5rem 0.8rem 1.2rem 1.8rem;

      .type {
        font-size: 1rem;
      }
    }
  }
}

.static {
  &-subtitle {
    font-size: 2.2rem;
    font-weight: $font-bold;
    margin: 5rem 0 3rem;
  }

  &-caption {
    font-size: 1.8rem;
    font-weight: $font-bold;
    margin: 3rem 0 1.5rem;
  }

  &-list {
    margin-bottom: 1.5rem;

    li {
      padding-left: 2rem;
      position: relative;

      &:before {
        background-color: $main-text;
        border-radius: 100%;
        content: '';
        display: block;
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        top: 0.8rem;
        left: 0.5rem;
      }
    }
  }

  &-links {
    display: none;
    margin: 0 -0.25rem;

    li {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 0.25rem;

      a {
        border: 0.1rem solid $gray-border;
        border-radius: 0.2rem;
        color: $main-text;
        display: flex;
        align-items: center;
        height: 4.5rem;
        line-height: 1;
        padding: 0 5.4rem 0 2rem;
        position: relative;

        &:after {
          background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 1.2rem;
          transform: translateY(-50%);
          width: 1.8rem;
          height: 1.2rem;
        }
      }

      &:first-child {
        a {
          justify-content: flex-end;
          padding: 0 2rem 0 5.4rem;

          &:after {
            right: auto;
            left: 1.2rem;
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }

    @media screen and (max-width: $smartphone) {
      display: flex;
      margin-top: 2rem;
    }
  }
}
