.dropdown {
  padding: 0 1rem;

  &-toggle {
    border-radius: 0.2rem;
    color: $text;
    cursor: pointer;
    display: block;

    &:after {
      display: none;
    }

    span {
      display: inline-block;
      padding-right: 3rem;
      position: relative;

      &:after {
        background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
        background-size: cover;
        border: none;
        content: '';
        display: block;
        margin: 0.2rem 0 0;
        width: 1.2rem;
        height: 0.74rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }

  &-menu {
    border: none;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    font-size: 1.4rem;
    margin-top: 0.3rem;
    padding: 1.6rem;
    min-width: 5rem;
    cursor: pointer;
    display: block;

    .dropdown-item {
      color: $main-text;
      font-weight: $font-medium;
      padding: 0.7rem 0;
      position: relative;

      &:hover,
      &:focus,
      &.active,
      &:active {
        color: $subcategory;
        text-decoration: none;
        background-color: transparent;
      }
    }

    ul {
      border-top: 0.1rem solid $border;
      border-bottom: 0.1rem solid $border;
      font-weight: $font-medium;
      margin-bottom: 0.5rem;
      padding: 0.6rem 0 0.2rem;

      li {
        margin-left: 0;
        padding: 0.4rem 0;
      }

      a {
        color: $main-text;

        &:hover,
        &:focus,
        &.active,
        &:active {
          color: $subcategory;
        }
      }
    }
  }

  &-item {
    &.active,
    &:active {
      background-color: transparent;
    }
  }

  &.show {
    background-color: $gray;

    .dropdown {
      &-toggle {
        span {
          &:after {
            margin-top: -1rem;
            transform: rotate(-180deg) translateY(-50%);
          }
        }
      }
    }

    & > .btn-primary.dropdown-toggle {
      color: $white;
      background-color: $subcategory;
      border-color: $subcategory;
    }
  }

  span:after {
    transition: transform 300ms;
  }

  &-open {
    span:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &-disabled {
    pointer-events: none;
    opacity: 0.5;
    display: block;
  }

  &-item-shop {
    cursor: default;

    &:hover {
      color: initial !important;
    }
  }

  &-date {
    opacity: 0;
    transition: 400ms;
    z-index: -1;

    &-focus {
      opacity: 1;
      transition: 400ms;
      z-index: 200;
    }
  }
}

.trigger-element {
  & ~ .target-element {
    opacity: 0;
    transition: 400ms;
    z-index: -1;
  }

  &:focus ~ .target-element {
    opacity: 1;
    transition: 400ms;
    z-index: 20;
  }
}
