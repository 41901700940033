.delivery-popup {
  display: block;
  background-color: #fff;
  box-shadow: 0 0.4rem 1.2rem rgba(59, 59, 72, 0.12);
  border-radius: 0.8rem;
  /* color: #3B3B48; */
  width: 29.4rem;
  position: fixed;
  /* top: 4rem!important; */
  transform: translateX(-50%);
  padding: 1.6rem;
  font-size: 1.3rem;
}

.delivery-popup:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border: 1rem solid transparent;
  border-bottom-color: #fff;
  border-top: none;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
}
.delivery-popup .delivery-popup_close {
  position: absolute;
  top: 0.4rem;
  right: 0.9rem;
}

.delivery-popup .delivery-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}

.delivery-popup .delivery-form {
  border: 0.1rem solid #e3eaf2;
  border-radius: 0.4rem;
}
.delivery-popup .delivery-form_form {
  border-bottom: 0.1rem solid #e3eaf2;
  display: flex;
  padding: 0.3rem;
}

.delivery-popup .delivery-selected_btn {
  display: flex;
  justify-content: space-around;
  margin: 0 -0.4rem;
  padding: 1.6rem 0 0;
}

.delivery-popup .delivery-selected_btn .btn {
  display: inline-block;
  font-size: 1.4rem;
  height: 3.6rem;
  line-height: 3.4rem;
  padding: 0 1.5rem;
  width: 100%;
}
