.header {
  background-color: $white;
  box-shadow: 0 0.4rem 0.4rem rgba($text, 0.04);
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.2s linear;
  z-index: 505;

  @media screen and (max-width: $tablet) {
    min-height: 8.7rem;
    z-index: 500;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: none;

    @media screen and (max-width: $tablet) {
      display: block;
      min-height: 8.7rem;
    }
  }

  &-top {
    background-color: $bg-blue-gray;
    font-size: 1.3rem;
    height: 3.6rem;
    position: relative;
    z-index: 52;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    .container {
      padding: 0 3.6rem;

      @media screen and (max-width: $hd) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }

    &_left {
      display: flex;
      align-items: center;

      a {
        color: $main-text2;
      }
    }

    &_contact {
      color: $placeholder;
      display: flex;
      align-items: center;
      margin-right: 5.8rem;

      .number {
        color: $main-text2;
        font-weight: $font-bold;
        margin: 0 0.8rem 0 0.6rem;
      }

      @media screen and (max-width: $hd - 80) {
        margin-right: 3.6rem;
      }
    }

    &_right {
      display: flex;
      align-items: center;
    }

    &_delivery {
      display: flex;
      align-items: center;

      .delivery {
        &-block {
          position: relative;
        }

        &-place {
          cursor: pointer;
          display: flex;
          align-items: center;

          .icon-place {
            margin-right: 0.6rem;
          }
        }

        &-popup {
          display: none;
          background-color: $white;
          box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
          border-radius: 0.8rem;
          color: $text;
          width: 29.4rem;
          position: absolute;
          top: 2.7rem;
          left: 50%;
          transform: translateX(-50%);
          padding: 1.6rem;

          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border: 1rem solid transparent;
            border-bottom-color: $white;
            border-top: none;
            position: absolute;
            top: -1rem;
            left: 50%;
            transform: translateX(-50%);
          }

          &.open {
            display: block;
          }

          &_close {
            position: absolute;
            top: 0.4rem;
            right: 0.9rem;
          }
        }

        &-title {
          font-size: 1.6rem;
          font-weight: $font-bold;
          margin-bottom: 0.8rem;
        }

        &-form {
          border: 0.1rem solid $border;
          border-radius: 0.4rem;

          &_form {
            border-bottom: 0.1rem solid $border;
            display: flex;
            padding: 0.3rem;
          }

          input {
            font-size: 1.3rem;
            height: 4rem;
            padding-left: 0.5rem;
          }

          .btn {
            flex: 0 0 4rem;
            max-width: 4rem;
            height: 4rem;
            padding: 0;
          }

          &_scroll {
            height: 20.9rem;
            font-size: 1.4rem;
            font-weight: $font-bold;
            padding: 0.6rem 0;

            li {
              padding: 1rem 0.8rem 1.1rem;
            }

            & > .scroll-element.scroll-y {
              right: 0;
              width: 0.2rem;
            }

            & > .scroll-element .scroll-element_track {
              background-color: $border;
              opacity: 1;
            }

            & > .scroll-element .scroll-bar {
              background-color: $text;
              opacity: 1;
            }
          }
        }

        &-selected {
          li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 0.4rem;
          }

          &_btn {
            display: flex;
            margin: 0 -0.4rem;
            padding: 1.6rem 0 0;
          }

          .btn {
            font-size: 1.4rem;
            height: 3.6rem;
            line-height: 3.4rem;
          }
        }
      }

      .icon {
        margin: 0 1.4rem;
      }
    }

    &_language {
      display: flex;
      margin-left: 5.4rem;

      li {
        padding-left: 0.4rem;

        a {
          background-color: transparent;
          color: $placeholder;
          display: block;
          font-size: 1.3rem;
          line-height: 2.4rem;
          width: 3.8rem;
          height: 2.4rem;
          text-align: center;
        }

        &.active {
          a {
            background-color: $placeholder;
            border-radius: 0.2rem;
            color: $white;
          }
        }
      }

      @media screen and (max-width: $hd - 80) {
        margin-left: 2.2rem;
      }
    }
  }

  &-main {
    background-color: $white;
    padding: 0.8rem 3.6rem !important;
    position: relative;
    z-index: 51;

    @media screen and (max-width: $hd) {
      padding: 0.8rem 1.5rem !important;
    }

    &_left,
    &_right {
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: $tablet) {
      &_left {
        margin-bottom: 1rem;
      }
    }
  }

  &-logo {
    margin-right: 2rem;
    position: relative;
    z-index: 5;

    img {
      height: 4rem;
      transition: all 0.2s linear;

      @media screen and (max-width: $tablet) {
        height: 2.5rem;
      }
    }

    a {
      display: block;
    }
  }

  &-catalog {
    &_link {
      display: none;
      justify-content: center;
      align-items: center;
      width: 21rem;
      position: relative;

      .icon {
        &-burger {
          margin-right: 1.2rem;
        }

        &-close {
          display: none;
        }
      }

      &.open {
        background-color: $white;
        border: 0.1rem solid $border;
        color: $subcategory;

        .icon {
          &-burger {
            display: none;
          }

          &-close {
            display: block;
            margin-right: 1.6rem;
          }
        }

        &:before {
          content: '';
          display: block;
          width: 0;
          height: 0;
          border: 1rem solid transparent;
          border-bottom-color: $white;
          border-top: none;
          position: absolute;
          bottom: -2.2rem;
          left: 5.4rem;

          @media screen and (max-width: $tablet-middle) {
            bottom: -1.9rem;
            left: 1.4rem;
          }

          @media screen and (max-width: $smartphone-middle) {
            display: none;
          }
        }
      }

      @media screen and (max-width: $hd - 80) {
        width: 14.3rem;
      }

      @media screen and (max-width: $tablet) {
        position: absolute;
        top: 0.8rem;
        left: 1.5rem;
        height: 3.6rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        position: relative;
        top: auto;
        left: auto;
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    transition: all 0.2s linear;
    width: 100%;

    &_delivery {
      @media screen and (max-width: $tablet) {
        .header-delivery {
          display: none;
        }
      }
    }

    &_basket {
      flex: 0 0 20.2rem;
      width: 20.2rem;

      .pay-loader {
        position: absolute;

        &:before {
          position: absolute;
        }
      }

      @media screen and (max-width: $hd - 100) {
        flex: 0 0 16.5rem;
        width: 16.5rem;
      }

      @media screen and (max-width: $tablet) {
        flex: 0 0 20.2rem;
        width: 20.2rem;

        .btn {
          height: 3.6rem;
        }
      }

      @media screen and (max-width: $smartphone) {
        flex: 0 0 4.8rem;
        width: 4.8rem;
      }

      @media screen and (max-width: $smartphone-small) {
        flex: 0 0 3.6rem;
        width: 3.6rem;

        .btn {
          width: 3.6rem;
        }
      }
    }

    .authUserContainer {
      height: 100%;

      .authUserBtn {
        height: 100%;
      }
    }

    @media screen and (max-width: $tablet) {
      display: block;

      .header-menu {
        justify-content: space-between;
      }

      &_login {
        position: absolute !important;
        top: 0.9rem;
        right: 1.5rem;
        height: auto !important;
      }

      &_search {
        position: absolute !important;
        left: 7.2rem;
        bottom: 0.8rem;
        height: 3.6rem !important;
        margin-left: 0 !important;
      }

      .search-form_btn {
        width: 4.8rem;
        height: 3.6rem;
      }
    }

    @media screen and (max-width: $smartphone) {
      .header-menu {
        gap: 0rem;
      }
    }

    @media screen and (max-width: $smartphone-small) {
      &_search {
        left: 6.2rem;
      }

      .search-form_btn {
        width: 3.6rem;
      }
    }
  }

  &-menu {
    display: flex;
    align-items: center;
    height: 100%;

    @media screen and (max-width: $tablet) {
      height: auto;
    }

    &_left {
      & > li {
        &.mobile-profile {
          display: none;
        }

        &.active {
          a {
            color: $add-text;
          }
        }
      }

      .dropdown {
        &-toggle {
          height: 5rem;
          line-height: 5rem;
        }

        @media screen and (max-width: $hd + 60) {
          margin-left: -1.4rem;
          width: 19rem;
        }
      }

      @media screen and (max-width: $tablet) {
        display: block;

        & > li {
          & > a {
            display: block;
            padding: 1.3rem 2.1rem;
          }

          &.mobile-profile {
            display: block;
          }
        }

        .dropdown {
          margin-left: 0;
          padding: 0;
          width: 100%;
          text-align: left;

          &-toggle {
            span {
              display: inline-block;
            }
          }
        }
      }

      @media screen and (max-width: $smartphone) {
        & > li {
          a.wishlist-icon {
            width: 4.4rem;
            padding-left: 0;
          }
        }
      }

      .search-form {
        position: absolute;
        top: 0.8rem;
        left: 50%;
        transform: translateX(-50%);
        width: 55rem;
        box-shadow: 0 0 1.2rem rgba($subcategory, 0.6);

        @media screen and (max-width: $xl-hd + 80) {
          left: 19rem;
          transform: none;
        }

        @media screen and (max-width: $l-hd) {
          width: 36rem;
        }

        @media screen and (max-width: $hd - 180) {
          top: auto;
          left: auto;
          transform: none;
          position: relative;
        }

        @media screen and (max-width: $tablet) {
          width: 100%;
          max-width: none;
        }

        input,
        .search-form_btn {
          height: 3.2rem;

          @media screen and (max-width: $tablet) {
            height: 4.8rem;
          }

          @media screen and (max-width: $smartphone) {
            height: 4.5rem;
          }
        }

        & > input {
          color: $subcategory;

          &::-webkit-input-placeholder {
            color: $subcategory;
          }

          &::-moz-placeholder {
            color: $subcategory;
          }

          &:-moz-placeholder {
            color: $subcategory;
          }

          &:-ms-input-placeholder {
            color: $subcategory;
          }
        }

        .search-form_btn {
          background-color: $subcategory;
        }
      }
    }

    &_right {
      & > li {
        height: 100%;
        margin-left: 2.5rem;
        position: relative;

        &:first-child {
          margin-left: 0;
        }

        & > span,
        & > a {
          height: 100%;
        }

        @media screen and (max-width: $hd + 60) {
          margin-left: 3.5rem;
        }

        @media screen and (max-width: $hd - 25) {
          margin-left: 1rem;
        }
      }

      .wishlist-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.4rem;
        width: 4.8rem;
        padding: 0 1.5rem;
        transition: none;

        &:hover,
        &.active {
          background: transparent url(../../images/ic-heart_o.svg) 50% 50% no-repeat;
          background-size: 2rem 1.9rem;

          img {
            opacity: 0;
          }
        }

        &.active {
          background-color: $bg-orange;
        }

        @media screen and (max-width: $smartphone) {
          display: none;
        }
      }

      .btn-login {
        justify-content: flex-start;
        padding-left: 2.8rem;
        position: relative;

        &:before {
          background: url(../../images/metro/ic-user.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 1.6rem;
          height: 1.7rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }
  }

  &-login {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  &-user {
    display: flex;
    align-items: center;
    padding-left: 2.6rem;
    position: relative;
    white-space: nowrap;

    &:before {
      background: url(../../images/metro/ic-user.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.64rem;
      height: 1.7rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    &:hover {
      & + .user-popup {
        display: block;
      }
    }

    @media screen and (max-width: $hd - 25) {
      width: 2.6rem;
      overflow: hidden;
    }

    @media screen and (max-width: $tablet) {
      width: auto;
    }
  }

  &-delivery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 1.8rem;
    line-height: 1.4rem;
    position: relative;
    padding-left: 2.6rem;
    cursor: pointer;

    &:before {
      background: url(../../images/ic-target.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.66rem;
      height: 2rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    & span {
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
    }
  }

  &-block_info {
    background-color: $bg-blue-gray;
    padding: 0.8rem;

    .block-one {
      background-color: $white;
      box-shadow: 0 0.4rem 0.5rem rgba($text, 0.12);
      border-radius: 0.8rem;
      font-family: $font-family-base !important;
      font-size: 1.4rem;
      padding: 1.5rem 4rem 3rem 7rem;
      overflow: hidden;
      position: relative;
      width: 100%;
      word-break: break-word;
      text-overflow: ellipsis;

      @media screen and (max-width: $smartphone-middle) {
        padding-right: 3.5rem;
      }

      &:before {
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-family: FontAwesome;
        font-size: 2.4rem;
        width: 6rem;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &.blocked::before {
        background: url('../../images/blocked.png') 50% 50% no-repeat;
        background-size: 3rem 3rem;
      }

      &.warning::before {
        background: url('../../images/warning.png') 50% 50% no-repeat;
        background-size: 3rem 3rem;
      }

      &.information::before {
        background: url('../../images/info.png') 50% 50% no-repeat;
        background-size: 3rem 3rem;
      }

      &.updates::before {
        background: url('../../images/update.png') 50% 50% no-repeat;
        background-size: 3rem 3rem;
      }

      &.sale::before {
        background: url('../../images/sale.png') 50% 50% no-repeat;
        background-size: 3rem 3rem;
      }

      p {
        margin: 0;
        padding: 0;
      }

      &_out {
        margin-top: 0.5rem;
        position: relative;

        &:first-child {
          margin-top: 0;
        }

        .btn-more {
          font-size: 1.4rem;
        }
      }

      &_close {
        width: 3rem;
        height: 3rem;
        background-color: transparent;
        position: absolute;
        top: 0.8rem;
        right: 1rem;

        @media screen and (max-width: $smartphone-middle) {
          right: 0.4rem;
        }
      }

      &_more {
        &--container {
          background-color: $white;
          position: absolute;
          right: 1.9rem;
          left: 6rem;
          bottom: 0rem;
          display: flex;
          justify-content: flex-end;
          padding-bottom: 0.8rem;
        }
      }
    }
  }

  &-category {
    height: 5rem;
    padding: 0 3.6rem;
    white-space: nowrap;

    @media screen and (max-width: $hd) {
      padding: 0 1.5rem;
    }

    @media screen and (max-width: $tablet) {
      display: none;
    }

    ul {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    a,
    span {
      display: block;
      padding: 1.5rem;

      @media screen and (max-width: $smartphone) {
        padding: 1rem 1.5rem;
      }

      &:hover {
        color: $subcategory;
      }
    }

    .active {
      background-color: rgba($subcategory, 0.2);
      border-radius: 0.4rem 0.4rem 0 0;

      a {
        color: $subcategory;
        font-weight: $font-bold;
      }
    }

    .wishlist-icon {
      display: flex;
      align-items: center;
      padding: 0 0 0 1.5rem;
      cursor: pointer;

      &:hover,
      &:focus {
        border-radius: 0.4rem;
        background: rgb(242, 244, 244);
        color: $subcategory;
      }
    }
  }

  &-subcategory {
    background-color: $subcategory;
    height: 4.5rem;
    padding: 0 3.6rem;

    @media screen and (max-width: $hd) {
      padding: 0 1.5rem;
    }

    @media screen and (max-width: $tablet) {
      display: none;
    }

    & > ul {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;

      & > li,
      .catalog-popup-item-li {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    &-landing {
      background-color: transparent;
      box-shadow: none;
      position: relative;

      .header-main_right,
      .header-block {
        display: none;
      }

      & + .content {
        margin-top: -8.7rem;
        padding-top: 0 !important;
      }

      .header {
        &-main {
          background-color: transparent;
        }

        &-logo {
          margin: 1rem 0 0 1rem;

          img {
            height: 4rem;
          }
        }
      }
    }
  }

  &-zip {
    background: url(../../images/ic-target_b.svg) 0 50% no-repeat;
    background-size: 1.4rem 2rem;
    color: #404957;
    font-size: 1.6rem;
    margin-right: 5.5rem;
    padding-left: 2rem;
    width: 100%;
    max-width: 13.6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
