.select2 {
  &-container {
    &--default {
      .select2-selection {
        &--single {
          background-color: $white;
          border: 0.1rem solid $gray-border;
          border-radius: 0;
          font-size: 1.6rem;
          height: 5rem;

          @media screen and (max-width: $smartphone) {
            height: 4rem;
          }

          .select2-selection {
            &__rendered {
              font-family: $font-family-second;
              line-height: 4.8rem;

              @media screen and (max-width: $smartphone) {
                font-size: 1.4rem;
                line-height: 3.8rem;
              }
            }

            &__arrow {
              border-left: 0.1rem solid $gray-border;
              height: 4rem;
              width: 4.6rem;
              top: 0.5rem;

              &:before {
                background: url(../../images/ic-arrow-drop.svg) 0 0 no-repeat;
                background-size: cover;
                content: '';
                display: block;
                width: 1.65rem;
                height: 0.9rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              b {
                display: none;
              }

              @media screen and (max-width: $smartphone) {
                width: 3.4rem;
                height: 3rem;
              }
            }

            &__clear {
              display: none;
            }
          }
        }
      }

      .select2-results {
        & > .select2-results__options {
          padding: 1rem 0 0.8rem;
        }

        &__option {
          color: $main-text;
          height: 5rem;
          padding: 1.4rem 0.5rem 1.4rem 2rem;
          position: relative;

          &[aria-selected='true'] {
            background-color: transparent;
            color: $subcategory;
            padding-right: 3rem;

            &:after {
              background: url(../../images/ic-check-mark_b.svg) 0 0 no-repeat;
              background-size: cover;
              content: '';
              display: block;
              width: 1.4rem;
              height: 1rem;
              position: absolute;
              top: 50%;
              right: 1rem;
              transform: translateY(-50%);
            }
          }

          &--highlighted[aria-selected] {
            background-color: $gray;
            color: $main-text;
          }
        }
      }
    }

    .select2-selection {
      &--single {
        .select2-selection__rendered {
          color: $main-text;
          padding-left: 2rem;
          padding-right: 5.5rem;
          text-align: left;

          @media screen and (max-width: $smartphone) {
            padding-left: 1.2rem;
            padding-right: 5rem;
          }
        }
      }
    }

    &--open {
      .select2-dropdown--below {
        border-top: 0.1rem solid $gray-border;
        font-family: $font-family-second;
      }

      .select2-selection--single .select2-selection__arrow:before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  &-dropdown {
    border-radius: 0.2rem;
    border-color: $border;
    box-shadow: 0 0.1rem 0.3rem rgba($shadow, 0.2);
    font-family: $font-family-base;
    font-size: 1.6rem;
    margin-top: -0.1rem;
  }

  &-search--dropdown {
    display: none;
  }
}

.select2-container--open {
  .simple-select_dropdown {
    border-color: $subcategory;

    &.select2-dropdown--below {
      border-top-color: $subcategory;
    }
  }
}
