.register {
  &-login {
    display: flex;
    height: 48rem;

    @media screen and (max-width: $tablet) {
      display: block;
      height: auto;

      &_text {
        display: none;
      }
    }

    &_list {
      @media screen and (max-width: $tablet-small) {
        display: flex;
        justify-content: space-between;
        text-align: center;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.4rem;
      }

      li {
        display: flex;
        align-items: center;
        padding: 2rem 0;

        @media screen and (max-width: $tablet-small) {
          flex: 0 0 33.333333%;
          width: 33.333333%;
          display: block;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5.3rem;
          height: 5.3rem;

          @media screen and (max-width: $tablet-small) {
            margin: 0 auto 1rem;
          }
        }
      }
    }

    &_left {
      flex: 0 0 43.6rem;
      width: 43.6rem;
      display: flex;
      align-items: center;
      border-right: 0.8rem solid $bg-blue-gray;

      @media screen and (max-width: $tablet) {
        flex: 0 0 34rem;
        width: 34rem;
        padding-left: 3rem;
      }

      @media screen and (max-width: $tablet-small) {
        display: block;
        border-right: none;
        width: 100%;
        padding: 0;
      }
    }

    &_right {
      flex-grow: 2;
      display: flex;
      align-items: center;
      padding: 2rem 5rem 2rem 11.7rem;

      @media screen and (max-width: $tablet) {
        padding-left: 5rem;
      }

      @media screen and (max-width: $tablet-small) {
        display: flex;
        justify-content: center;
        padding: 4rem 0;
      }

      h4 {
        font-weight: $font-bold;
        font-size: 2rem;
      }
    }

    &_form {
      width: 100%;

      @media screen and (max-width: $tablet-small) {
        width: auto;
      }

      .profile-info_row {
        width: 100%;

        input {
          &:focus,
          &:active {
            border-color: $subcategory;
          }
        }
      }

      .info-text {
        margin: 1.5rem 0 0.4rem;
      }

      .link {
        color: $subcategory;
        font-weight: $font-medium;
      }
    }

    &_block {
      flex: 0 0 31.8rem;
      max-width: 31.8rem;
    }

    &_code {
      background-color: $bg-blue-gray;
      border: 0.1rem solid $border;
      border-radius: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 11rem;
      margin-top: 2.6rem;

      input {
        background-color: $white;
        border: 0.1rem solid $border;
        box-shadow: 0 0.1rem 0.6rem rgba($shadow, 0.2);
        border-radius: 0.4rem;
        width: 5rem;
        height: 5rem;
        margin: 0 0.5rem;
      }
    }

    &_time {
      border-top: 0.1rem solid rgba($border, 0.5);
      font-size: 2rem;
      margin-top: 3.9rem;
      padding-top: 3rem;
      width: 100%;
      max-width: 51.8rem;
    }
  }

  &-left {
    display: flex;
    align-items: center;
    position: relative;

    @media screen and (max-width: $tablet) {
      display: block;
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $tablet) {
      display: block;
    }
  }

  &-social {
    width: 100%;
    max-width: 47rem;
    margin-right: 5rem;
    padding: 2rem 0 7rem;

    @media screen and (max-width: $tablet) {
      margin: 0 auto;
      padding: 5.5rem 0 9rem;
    }

    .login-social {
      height: 4.8rem;
    }

    p {
      color: $add-text;
      text-align: center;
    }
  }

  &-form {
    width: 100%;
    max-width: 47rem;
    margin-left: 5rem;
    padding: 2rem 0 7rem;

    @media screen and (max-width: $tablet) {
      margin: 0 auto;

      .profile-info_row {
        border-bottom: none;
        padding-bottom: 0;
      }

      .profile-form-error {
        padding: 0;
        bottom: 1rem;
      }
    }

    @media screen and (max-width: $tablet-small) {
      padding: 0 2.3rem 4rem;
    }

    @media screen and (max-width: $smartphone-smallest) {
      padding: 0 1rem 4rem;
    }

    .btn {
      margin-top: 1rem;
    }
  }

  &-sep {
    background-color: $white;
    display: block;
    font-size: 1.4rem;
    font-weight: $font-bold;
    padding: 0 1rem;
    width: 8rem;
    text-align: center;
    position: absolute;
    top: 50%;
    right: -4.3rem;
    transform: translateY(-50%);

    @media screen and (max-width: $tablet) {
      background-color: transparent;
      color: #919ba9;
      width: 100%;
      text-transform: lowercase;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      span {
        background-color: $bg;
        padding: 0 5rem;
        position: relative;
      }

      &:first-letter {
        text-transform: uppercase;
      }

      &:before {
        background-color: #e9e9e9;
        content: '';
        display: block;
        width: 100%;
        height: 0.1rem;
        position: absolute;
        top: 0.8rem;
        left: 0;
      }
    }
  }

  &-after {
    background-color: $white;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    margin-bottom: 8rem;
    padding: 4rem;
    text-align: center;

    .subtitle {
      font-size: 1.8rem;
      font-weight: $font-bold;
    }
  }

  @media screen and (max-width: $tablet) {
    &-row {
      flex-direction: column-reverse;
    }
  }
}
