.search-page {
  background-color: $bg;
  min-height: 100vh;
  padding: 2.3rem 2.4rem 7.5rem;

  @media screen and (max-width: $smartphone-smallest) {
    padding: 2.3rem 1.5rem 7.5rem;
  }

  .search {
    &-form {
      border: 0.05rem solid #d5dde0;
      box-shadow: none;
      margin: 0 auto 2rem;
      padding: 0 0 0 3.1rem;

      &:before {
        background: url(../../images/icon-search.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.97rem;
        height: 1.97rem;
        position: absolute;
        top: 1rem;
        left: 1.8rem;
      }

      .search-close {
        position: absolute;
        top: 1rem;
        right: 1.6rem;
        width: 1.8rem;

        img {
          width: 100%;
          height: auto;
        }
      }

      .my-input-search {
        padding-right: 4.5rem;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1.4rem;

          &::-webkit-input-placeholder {
            color: #919ba9;
          }
          &::-moz-placeholder {
            color: #919ba9;
          }
          &:-moz-placeholder {
            color: #919ba9;
          }
          &:-ms-input-placeholder {
            color: #919ba9;
          }
        }
      }
    }

    &-tag {
      background-color: #dddfe0;
      border-radius: 1.6rem;
      font-size: 1.4rem;
      margin: 0 0 0.8rem 0.8rem;
      padding: 0.7rem 1.6rem 0.8rem;

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.2rem;
      }
    }

    &-actual {
      margin-left: -0.8rem;

      .title {
        font-size: 2rem;
        font-weight: $font-bold;
        margin: 0 0 2.4rem 0.8rem;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1.6rem;
          margin-bottom: 1.4rem;
        }
      }

      &_list {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &-result {
      .infinite-scroll-component {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.4rem;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .catalog-item {
          padding: 0 0.4rem 1rem;
        }
      }
    }

    &-hint {
      background-color: $white;
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 1.2rem rgba($text, 0.2);
      position: absolute;
      top: 6rem;
      bottom: 8%;
      left: 50%;
      width: 100%;
      font-size: 1.4rem;
      font-weight: $font-bold;
      overflow: hidden;
      opacity: 1;
      transform: translateX(-50%);
      transition: 400ms all ease-in;
      z-index: 20;
      margin-top: 0.3rem;
      max-height: calc(100vh - 10rem);
      overflow-y: scroll;

      .infinite-scroll-component {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .catalog-item {
          padding: 0 0.4rem 1rem;
          @media screen and (max-width: $tablet) {
            margin-bottom: 0;
            padding: 0.4rem 0.4rem 1rem;
          }
        }

        .item-food {
          .quantity-form_inside {
            margin: 0;
            position: absolute;
            top: 12rem;
            right: 1rem;
            width: 10.5rem;
            height: 3.5rem;
            align-items: center;

            input {
              height: 3.5rem;
            }

            @media screen and (max-width: $tablet) {
              right: 0.8rem;
              input {
                font-size: 1.2rem;
              }
            }

            @media screen and (max-width: $smartphone-middle) {
              top: 9rem;
              right: 0.8rem;
              input {
                font-size: 1.2rem;
              }
            }
          }
        }
      }

      .search-result-item {
        &:hover {
          cursor: pointer;
          background-color: rgb(242, 244, 244);
        }
      }

      .search-panel {
        background-color: rgb(242, 244, 244);
        border-bottom: 1px solid rgb(218, 222, 224);
        align-items: center;
        padding: 1.2rem 1.6rem;
        text-transform: uppercase;
      }

      .search-panel-show-all {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 1.2rem 1.6rem;

        .show-all {
          width: auto;
          text-transform: uppercase;
          padding: 0 1.5rem;
        }
      }

      .search-tag-mobile {
        background-color: #dddfe0;
        border-radius: 1.6rem;
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: $font-light;
        margin: 0 0 0.4rem 0.4rem;
        padding: 0.3rem 1.3rem 0.4rem;

        &:hover {
          color: $text;
        }

        &_categories {
          position: relative;
          display: flex;
          flex: 0 0 auto;
          padding: 1.9rem 1.4rem;
          background: rgb(255, 255, 255);
          color: rgb(28, 37, 41);
          height: 4rem;

          .parent-category {
            font-weight: $font-regular;
          }

          &:hover {
            cursor: pointer;
            background-color: rgb(242, 244, 244);
          }

          a:hover {
            color: $text;
          }

          &::after {
            content: "›";
            position: absolute;
            top: 45%;
            right: 30px;
            color: rgb(93, 103, 108);
            font-size: 3rem;
            transform: translateY(-50%);
          }
        }

        &_list {
          display: flex;
          flex-wrap: wrap;
          margin: 0.7rem 0 0 0.8rem;

          li {
            height: auto;
            padding: 0;
            @media screen and (max-width: $tablet) {
              min-height: 2.7rem;
            }
          }
        }
      }

      .quantity-form_inside {
        height: 3.25rem;
      }

      @media screen and (max-width: $tablet) {
        .item-food_price .price {
          align-items: center;
          display: flex;
          justify-content: space-between;
          min-height: 0.5rem;
        }
      }

      li {
        @media screen and ((max-width: $smartphone-middle) or (max-width: $tablet)) {
          display: block;
          height: auto;
          min-height: 3rem;
          padding-left: 0;
          position: relative;
        }
      }
    }
  }
}
