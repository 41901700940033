.breadcrumb {
  background: $bg-blue-gray;
  border-radius: 0;
  font-size: 1.3rem;
  margin-bottom: 0;
  padding: 1.2rem 3.6rem;

  &-item {
    position: relative;

    & + .breadcrumb-item {
      padding-left: 3.6rem;

      &:hover {
        cursor: pointer;
      }

      &.active:hover {
        cursor: default;
      }

      a,
      a:hover {
        color: #8f9bab;
      }

      &:before {
        background: url(../../images/ic-angle.svg) 0 0 no-repeat;
        background-size: 100% 100%;
        content: '';
        margin-right: 1.3rem;
        padding-right: 0;
        width: 0.8rem;
        height: 0.6rem;
        transform: rotate(-90deg);
        position: absolute;
        top: 0.5rem;
        left: 1.5rem;
      }
    }
  }

  @media screen and (max-width: $hd) {
    padding: 1.2rem 1.5rem;
  }

  @media screen and (max-width: $smartphone) {
    font-size: 1.2rem;
    padding-bottom: 1.1rem;
  }

  &-mobile {
    display: none;

    @media screen and (max-width: $tablet) {
      display: block;
      background: $bg-blue-gray;
      font-size: 1.3rem;
      padding: 0.8rem 1.5rem;

      a {
        display: block;
        position: relative;
        padding-left: 2rem;

        &:before {
          background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
          content: '';
          display: block;
          width: 1.2rem;
          height: 0.7rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: rotate(90deg) translateY(50%);
          margin-top: -0.3rem;
        }
      }
    }
  }
}

.breadcrumbs-box {
  margin-bottom: 1rem;
}
