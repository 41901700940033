$black: #000;
$white: #fff;
$blue: #01a6e6;
$orange: #ff9e1b;
$orange-dark: #ff7f09;
$bg-orange: #ffecd1;
$text: #3b3b48;
$blue-gray: #f2f4fa;
$bg-blue-gray: #f4f8fb;
$main-text: #3e4958;
$main-text2: #323232;
$main-text3: #292d49;
$add-text: #8f9bab;
$gray: #f9f9f9;
$gray-f2: #f2f2f2;
$gray-border: #dadeea;
$bg: #f7f8f9;
$bg-gray: #cdd2d9;
$green: #73b73b;
$green-border: #25af20;
$dark-green: #1f991b;
$red: #f3335c;
$red-light: #eb3345;
$bg-red: #d0624c;
$bg-red-light: #fff7f8;
$bg-btn: #f4f4f4;
$border: #e3eaf2;
$border-f7: #f7f7f7;
$gray-f9: #f9f9f9;
$bg-social: #f2f4fa;
$btn-text: #faf6f6;
$bg-pic: #e9edf8;
$disabled-blue: #bfe3f1;
$facebook: #3c5997;
$subcategory: #0b4a87;
$subcategory-light: #2469b5;
$subcategory-dark: #093868;
$catalog-item-border: #f1f1f1;

$shadow: #a0a0a0;
$placeholder: #8f9bab;

$alert-success-color: #155724;
$alert-success-background: #d4edda;
$alert-success-border: #c3e6cb;
$alert-danger-color: #721c24;
$alert-danger-background: #f8d7da;
$alert-danger-border: #f5c6cb;

$xl-hd: 1600px;
$l-hd: 1400px;
$hd: 1280px;
$desktop: 1024px;
$tablet: 991px;
$tablet-middle: 880px;
$tablet-small: 767px;
$smartphone: 640px;
$smartphone-middle: 575px;
$smartphone-small: 480px;
$smartphone-smallest: 374px;
