.search {
  background-color: rgba(1, 166, 230, 0.15);
  border-radius: 0 0 2.6rem 2.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  margin-bottom: 2rem;
  padding: 0.9rem 1rem 0.8rem 0.8rem;

  &-contact {
    display: flex;
    align-items: center;

    &_label {
      background-color: $subcategory;
      border: 0.1rem solid rgba(2, 190, 243, 0.3);
      box-shadow: 0 0.5rem 1rem 0 rgba(3, 129, 178, 0.2);
      border-radius: 0.4rem 3rem 3rem 3rem;
      color: $white;
      display: block;
      font-family: $font-family-second;
      font-size: 1.6rem;
      width: 20rem;
      height: 4.3rem;
      line-height: 4.1rem;
      margin-right: 1.9rem;
      text-align: center;
    }

    &_phone {
      font-weight: $font-medium;
      margin-left: 0.5rem;
    }
  }

  &-form {
    background-color: $white;
    box-shadow: 0 0 1.2rem rgba($text, 0.1);
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    max-width: 86rem;
    padding: 0.8rem;
    position: relative;
    z-index: 3;

    .my-input-search {
      background-color: transparent;
    }

    &_btn {
      background: $subcategory url(../../images/ic-search-w.svg) 50% 50% no-repeat;
      background-size: 1.6rem 1.6rem;
      border-radius: 0.4rem;
      padding: 0;
      flex: 0 0 4.8rem;
      width: 4.8rem;
      height: 4.8rem;
      position: relative;

      @media screen and (max-width: $smartphone) {
        flex: 0 0 4.5rem;
        width: 4.5rem;
        height: 4.5rem;
      }

      &-delete {
        background-color: $white;
        background-image: url(../../images/ic-close.svg);

        &:after {
          display: none;
        }
      }
    }

    .global-search {
      input[type='checkbox'] {
        display: none;
      }

      label {
        color: $add-text;
        cursor: pointer;
        font-size: 1.1rem;
        margin-bottom: 0;
        padding: 0 1.4rem 0 2.4rem;
        position: relative;
        white-space: nowrap;

        &:before {
          background-color: $white;
          border: 0.1rem solid $gray-border;
          border-radius: 0.2rem;
          content: '';
          display: block;
          width: 1.6rem;
          height: 1.6rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }

      input:checked + label:before {
        background: $subcategory url(../../images/ic-check-mark_w.svg) 50% 50% no-repeat;
        background-size: 1rem 0.57rem;
        border-color: $subcategory;
      }
    }

    &_mobile {
      background-color: $white;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.7rem 0 1.5rem;
      z-index: 1000;

      .search-form {
        flex: 2;
      }

      .close-search {
        flex: 0 0 3rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 1rem;
      }
    }
  }

  &-hint {
    background-color: $white;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.2);
    position: absolute;
    top: 100%;
    left: 50%;
    width: 77rem;
    font-size: 1.4rem;
    font-weight: $font-bold;
    overflow: hidden;
    opacity: 1;
    transform: translateX(-50%);
    transition: 400ms all ease-in;
    z-index: 20;
    margin-top: 0.3rem;
    max-height: calc(100vh - 10rem);
    overflow-y: scroll;

    @media screen and (max-width: $hd - 180) {
      left: 0;
      transform: none;
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
      max-height: 30rem;
    }

    .search-result-item {
      &:hover {
        cursor: pointer;
        background-color: rgb(242, 244, 244);
      }
    }

    .search-panel {
      background-color: rgb(242, 244, 244);
      border-bottom: 1px solid rgb(218, 222, 224);
      align-items: center;
      padding: 1.2rem 1.6rem;
      text-transform: uppercase;
    }

    .search-panel-show-all {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      padding: 1.2rem 1.6rem;

      .show-all {
        width: auto;
        text-transform: uppercase;
      }
    }

    .search-tag {
      background-color: #dddfe0;
      border-radius: 1.6rem;
      cursor: pointer;
      font-size: 1.4rem;
      font-weight: $font-regular;
      margin: 0 0 0.8rem 0.8rem;
      padding: 0.7rem 1.6rem 0.8rem;
      text-wrap: nowrap;

      &:hover {
        color: $text;
      }

      &_categories {
        position: relative;
        display: flex;
        flex: 0 0 auto;
        padding: 1.9rem 1.6rem;
        background: rgb(255, 255, 255);
        color: rgb(28, 37, 41);
        height: 5rem;

        .parent-category {
          font-weight: $font-regular;
        }

        &:hover {
          cursor: pointer;
          background-color: rgb(242, 244, 244);
        }

        a:hover {
          color: $text;
        }

        &::after {
          content: "›";
          position: absolute;
          top: 45%;
          right: 30px;
          color: rgb(93, 103, 108);
          font-size: 3rem;
          transform: translateY(-50%);
        }
      }

      &_list {
        display: flex;
        flex-wrap: wrap;
        margin: 1rem;

        li {
          height: auto;
          padding: 0;
        }
      }
    }

    .tags-container {
      .search-tag_list {
        display: flex;
        flex-wrap: nowrap;
        margin: 0.8rem 0 0;

        &.opened {
          height: auto;
          flex-wrap: wrap;
        }
      }

      .show-more {
        width: 18rem;
        padding-top: 1.7rem;
        padding-right: 2rem;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 25%);
        position: absolute;
        top: 4.2rem;
        right: 0;
        cursor: pointer;
        height: 5rem;
        text-align: right;

        &.opened {
          display: none;
        }
      }
    }

    li {
      display: flex;
      align-items: center;
      height: 9.5rem;
      padding: 0.8rem;

      @media screen and (max-width: $smartphone-middle) {
        display: block;
        height: auto;
        min-height: 9.5rem;
        padding-left: 7.6rem;
        position: relative;
      }
    }

    &_pic {
      flex: 0 0 8rem;
      max-width: 8rem;
      margin-right: 0.8rem;
      width: 100%;
      height: 8rem;
      position: relative;
      overflow: hidden;

      @media screen and (max-width: $smartphone-middle) {
        position: absolute;
        top: 0;
        left: 0.8rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .item-status {
        right: 0;
        font-size: 1.1rem;
        padding: 0.25rem 0.5rem;

        .sale-date {
          font-size: 0.7rem;
        }
      }
    }

    &_button {
      flex: 0 0 6.8rem;
      width: 6.8rem;
      padding: 0 1rem;

      @media screen and (max-width: $smartphone-middle) {
        flex: 0 0 6rem;
        width: 6rem;
        padding: 0;
      }

      .btn-buy {
        width: 4.8rem;
        height: 4rem;
        padding: 0;

        .icon-basket {
          margin-right: 0;
        }
      }
    }

    &_price {
      flex: 0 0 15rem;
      width: 15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      @media screen and (max-width: $smartphone-middle) {
        flex: 2;
        width: auto;
        justify-content: flex-start;
        margin: 0.8rem 0;
      }

      .price {
        &-old {
          color: $add-text;
          font-size: 1.1rem;
          text-decoration: line-through;
        }

        &-sale {
          color: $red;
          margin-left: 0.5rem;
        }
      }
    }

    &_description {
      flex: 2;

      @media screen and (max-width: $smartphone-middle) {
        display: block;
        margin-bottom: 1rem;
      }

      .weight-unit {
        background-color: $add-text;
        border-radius: 0.8rem;
        color: $white;
        display: inline-block;
        font-size: 1.2rem;
        font-weight: $font-semibold;
        padding: 0.2rem 0.6rem;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1rem;
        }
      }
    }

    .contains {
      color: $add-text;
    }

    .select-item {
      background-color: $bg-blue-gray;
    }

    &-show {
      opacity: 1;
      z-index: 20;
    }

    &_title {
      color: $add-text;
      margin-bottom: 0;
      padding: 1.1rem 3rem;
      text-transform: uppercase;

      @media screen and (max-width: $smartphone) {
        padding: 1rem 2rem 1.1rem;
      }
    }

    &_item {
      padding: 1.1rem 5.5rem 1.1rem 3rem;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .delete {
        background: url(../../images/ic-close.svg) 50% 50% no-repeat;
        background-size: 1.5rem 1.5rem;
        border-left: 0.1rem solid rgba($shadow, 0.2);
        display: block;
        width: 4.8rem;
        height: 4rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      &:nth-child(2n) {
        background-color: $bg-blue-gray;
      }

      @media screen and (max-width: $smartphone) {
        padding-left: 2rem;
      }
    }

    &_info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .quantity-form_inside {
        justify-content: flex-end;
        height: 3.5rem;
        margin-right: 0;

        input {
          height: 3.5rem;
        }
      }

      .btn-buy-sm {
        width: 3.5rem;

        &.btn-plus {
          color: #ffffff;
          font-size: 3rem;
          align-items: normal;
        }
      }
    }

    &_no {
      opacity: 0.5;
    }
  }

  &-main {
    height: 57rem;
    position: relative;

    &_pic {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      right: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    @media screen and (max-width: $tablet) {
      height: auto;
      padding: 4.4rem 0;
    }

    @media screen and (max-width: $tablet-small) {
      height: auto !important;
    }

    .container {
      display: flex;
      align-items: center;
      height: 100%;

      @media screen and (max-width: $tablet) {
        display: block;
      }

      h1 {
        font-size: 4.7rem;
        margin-bottom: 3rem;

        @media screen and (max-width: $tablet) {
          text-align: center;
        }
      }

      h2 {
        font-size: 2.8rem;
        font-weight: $font-regular;
        line-height: 1.2;
        margin-bottom: 3.5rem;
        text-align: left;

        @media screen and (max-width: $tablet) {
          text-align: center;
        }
      }
    }

    .search {
      &-form {
        margin-top: 0.5rem;

        &_block {
          width: 100%;
          max-width: 60rem;

          @media screen and (max-width: $tablet) {
            max-width: 86rem;
            margin: 0 auto;
          }

          .btn {
            width: 100%;
            max-width: 30rem;
            height: 5.5rem;
            font-size: 1.8rem;

            @media screen and (max-width: $tablet) {
              margin: 0 auto;
            }
          }
        }
      }
    }

    &_title {
      font-size: 2.6rem;
      font-weight: $font-bold;
      margin-bottom: 2.5rem;
    }
  }

  &-address {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    padding-left: 3rem;
    position: relative;

    &:before {
      background: url(../../images/ic-target.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.8rem;
      height: 2.1rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    a {
      color: $subcategory;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-time {
    a {
      color: $subcategory;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-result {
    &_no {
      p {
        max-width: 58rem;

        &.search-main_title {
          max-width: none;
        }
      }
    }

    &_yes,
    &_no {
      background-color: $white;
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
      padding: 2rem;
      width: 100%;
      max-width: 80rem;

      @media screen and (max-width: $tablet) {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        padding: 0 0 3rem;
      }
    }

    .catalog-item {
      padding: 0;
      padding-bottom: 0.7rem;

      @media screen and (max-width: $tablet) {
        margin-bottom: 0;
        padding: 0 0.4rem 1rem;
      }
    }
  }

  &-buttons {
    display: flex;
    margin: 0 -0.5rem;

    @media screen and (max-width: $smartphone-middle) {
      display: block;
    }

    li {
      padding: 0 0.5rem;

      @media screen and (max-width: $smartphone-middle) {
        padding-top: 1rem;
      }
    }
  }
}
