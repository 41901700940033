.owl {
  &-carousel {
    button.owl-dot {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 100%;
      background-color: $bg-gray;
      margin: 0 0.5rem;
      transition: all 0.2s linear;

      &.active {
        background-color: $text;
      }
    }

    .owl-stage {
      display: flex;
    }

    .item {
      display: flex;
      flex: 1 0 auto;
      height: 100%;
    }
  }

  &-stage-outer {
    padding: 0.5rem 0;
  }

  &-nav {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    margin-top: -1.8rem;

    button.owl {
      &-next,
      &-prev {
        background-color: $white;
        border-radius: 100%;
        box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
        color: $white;
        font: normal normal normal 2rem/1 FontAwesome;
        width: 4.8rem;
        height: 4.8rem;
        position: absolute;
        top: 0;
        transition: all 0.2s linear;

        &:before {
          color: $placeholder;
          transition: all 0.2s linear;
        }

        &:hover {
          box-shadow: 0 0 0.5rem rgba($black, 0.5);

          &:before {
            color: $text;
          }
        }

        &.disabled {
          cursor: default;
          opacity: 0;

          &:hover {
            &:before {
              color: $placeholder;
            }
          }
        }
      }

      &-prev {
        left: -0.9rem;

        @media screen and (max-width: $hd + 50) {
          left: 0.5rem;
        }

        &:before {
          content: '\f104';
          margin-left: -0.3rem;
        }
      }

      &-next {
        right: -0.9rem;

        @media screen and (max-width: $hd + 50) {
          right: 0.5rem;
        }

        &:before {
          content: '\f105';
          margin-right: -0.3rem;
        }
      }
    }
  }

  &-dots {
    flex-wrap: wrap;
    line-height: 1;
    padding-top: 2.5rem;
    position: relative;
    text-align: center;

    button,
    button.owl-dot {
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 100%;
      background-color: $bg-gray;
      border: none;
      margin: 0 0.5rem;
      transition: all 0.2s linear;

      &.active {
        background-color: $text;
      }
    }

    .react-multi-carousel-dot--active {
      button {
        background-color: $text;
      }
    }
  }
}

.react-multiple-carousel__arrow {
  background-color: $white;
  box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
  width: 4.8rem;
  height: 4.8rem;
  opacity: 0.5;
  z-index: 10;

  &:before {
    font-size: 1.4rem;
  }

  @media screen and (max-width: $smartphone) {
    display: none;
  }

  &:hover {
    background-color: $white;
    opacity: 1;
  }

  &--left {
    left: 0;

    &:before {
      color: $placeholder;
    }

    &:disabled {
      background-color: $white;

      &:before {
        opacity: 0.5;
      }
    }
  }

  &--right {
    right: 0;

    &:before {
      color: $placeholder;
    }

    &:disabled {
      background-color: $white;

      &:before {
        opacity: 0.5;
      }
    }
  }
}

.react-multi-carousel-dot {
  button {
    border: none;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 1rem;
    background-color: rgba($text, 0.3);
  }

  &--active {
    button {
      background-color: $subcategory;
    }
  }
}
