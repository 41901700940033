.pay {
  &-page {
    background-color: $bg;
    padding: 8rem 0 2rem;

    .container {
      max-width: 81.6rem;
    }

    h1 {
      margin-bottom: 2.4rem;
      text-align: center;
    }

    &_top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 2.5rem;

      h1 {
        margin: 0 1rem 0 0;
      }

      &-total {
        font-weight: $font-medium;

        span {
          margin-right: 1rem;
        }

        .price {
          color: $red;
          font-size: 2rem;
          font-weight: $font-bold;
          display: inline-block;

          &-prev {
            color: $main-text;
            text-decoration: line-through;
          }
        }
      }
    }

    &_thankyou {
      @media screen and (max-width: $tablet) {
        padding: 2.4rem 2.4rem 8rem;
      }

      .container {
        max-width: 132rem;
      }

      .checkout {
        &-thankyou {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          @media screen and (max-width: $tablet) {
            display: block;
            margin-top: 0;
          }

          .checkout-thankyou {
            &_block {
              flex: 0 0 62%;
              width: 62%;

              @media screen and (max-width: $tablet) {
                width: auto;
              }
            }

            &_btn {
              display: flex;
              padding-bottom: 1.4rem;

              @media screen and (max-width: $tablet) {
                justify-content: center;
                padding: 2rem 0 0;
              }

              .btn {
                width: auto;

                &:first-child {
                  margin-right: 1.6rem;
                }

                @media screen and (max-width: $smartphone-middle) {
                  font-size: 1.4rem;
                }
              }
            }
          }
        }

        &-info {
          font-size: 1.2rem;
          padding-left: 3.8rem;

          @media screen and (max-width: $smartphone-middle) {
            margin: 0 -1.5rem;
            padding: 0;
          }

          &_one {
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &_timer {
      color: $add-text;
      font-size: 1.4rem;
      font-weight: $font-medium;
      margin-bottom: 2rem;
      text-align: center;
    }

    &_fill {
      background-color: rgba($subcategory-light, 0.25);
      color: $subcategory;
      font-size: 1.4rem;
      font-weight: $font-medium;
      margin-bottom: 2rem;
      padding: 1rem;
      text-align: center;
    }

    &_header {
      background-color: $white;
      box-shadow: 0 0.4rem 0.4rem rgba($text, 0.04);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.4rem 3.6rem;
      z-index: 20;

      @media screen and (max-width: $smartphone-middle) {
        padding: 1.4rem 2.4rem;
      }
    }

    &_close {
      background: url(../../images/ic-close_b.svg) 0 0 no-repeat;
      background-size: cover;
      display: block;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
    }

    .footer-menu {
      display: none;

      @media screen and (max-width: $tablet) {
        display: block;
        background-color: $white;
        margin: 4rem 0 0;

        li {
          display: flex;
          align-items: center;
          border-top: 0.1rem solid rgba(213, 221, 224, 0.4);
          height: 6rem;
          padding: 0 2.4rem;

          &:first-child {
            border-top: none;
          }
        }
      }
    }
  }

  &-block {
    background-color: $white;
    border-radius: 0.8rem;
    font-size: 1.4rem;
    margin-bottom: 2.4rem;

    &_one {
      border-top: 1px solid #e9ebed;
      padding: 2.7rem 3.2rem 1.2rem;

      @media screen and (max-width: $smartphone-middle) {
        padding: 2.7rem 2.4rem 1.2rem;
      }

      @media screen and (max-width: $smartphone-smallest) {
        padding: 2.7rem 1.5rem 1.2rem;
      }

      &:first-child {
        border-top: none;
      }

      .form {
        &-item {
          label {
            color: #919ba9;
            font-size: 1.2rem;
            font-weight: $font-medium;
            margin-bottom: 0.6rem;
          }

          .profile {
            &-form {
              &-error {
                bottom: 0.2rem;
                padding: 0;
              }

              &-item {
                .profile-form-error {
                  bottom: -1.5rem;
                }
              }
            }

            &-info {
              &_row {
                margin-bottom: 0;

                @media screen and (max-width: $tablet) {
                  border-bottom: none;
                  padding-bottom: 0;
                }
              }
            }
          }
        }

        &-control {
          margin-bottom: 0;
        }
      }

      &.pay-block_card {
        padding-bottom: 4rem;

        form {
          .btn {
            margin: 2rem auto 0;
            width: auto;
          }
        }

        .paypal-card {
          iframe.component-frame {
            z-index: 2 !important;
          }
        }
      }
    }

    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: $font-medium;
      margin-bottom: 2rem;

      .title {
        display: flex;
        align-items: center;
      }

      .number {
        flex: 0 0 2.6rem;
        width: 2.6rem;
        height: 2.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $subcategory;
        border-radius: 100%;
        color: $white;
        font-size: 1.4rem;
        font-weight: $font-semibold;
        margin-right: 1.1rem;
      }
    }
  }

  &-dropdown {
    position: relative;

    .link {
      color: #2d3541;
      cursor: pointer;
      display: inline-block;
      font-size: 1.2rem;
      font-weight: $font-medium;
      text-align: right;
      text-decoration: underline;
    }

    &_block {
      background-color: $white;
      box-shadow: 0 0.1rem 0.4rem rgba($black, 0.25);
      border-radius: 0.4rem;
      font-size: 1.2rem;
      max-width: 26.6rem;
      max-height: 15.1rem;
      overflow-y: auto;
      padding: 1.2rem;
      position: absolute;
      top: 2.5rem;
      right: 0;
      z-index: 3;

      .info-row {
        margin-top: 1.3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        &:first-child {
          margin-top: 0;
        }

        span {
          margin-left: 0.35rem;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  &-contact {
    .pay-dropdown {
      &_block {
        max-width: 20.2rem;
      }
    }
  }

  &-coupon {
    padding-bottom: 1.8rem;

    &_form {
      display: flex;
      margin-top: 1.7rem;
      padding-bottom: 2rem;
      position: relative;

      @media screen and ((max-width: $smartphone-middle) or (max-width: $smartphone-small) or (max-width: $smartphone) or (max-width: $tablet-small)) {
        padding-bottom: 0;
      }

      &.input-group {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
      }

      input {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        border-right: none;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        @media screen and (max-width: $smartphone-small) {
          width: 100%;
          margin: 0 0 1.6rem !important;
        }
      }

      button {
        flex: 0 0 10rem;
        width: 8rem;
        border-radius: 0.4rem;
        font-size: 1.4rem;
        font-weight: $font-medium;
        position: relative;
        z-index: 2;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        &[disabled] {
          opacity: 0.4;
          background-color: $subcategory !important;
          border-color: $subcategory !important;
          color: $white;
        }

        @media screen and (max-width: $smartphone-small) {
          height: 3.5rem;
        }
      }
    }

    .form-check {
      &-label {
        padding-left: 3.8rem;
        font-size: 1.4rem;
        min-height: 2.4rem;
        margin-top: 0.5rem;

        &:before {
          background-color: $bg;
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      input[type='checkbox']:checked + label {
        &:before {
          background-color: $subcategory;
          border-color: $subcategory;
        }

        &:after {
          background-image: url(../../images/ic-check-mark_w.svg);
          width: 1.3rem;
          height: 0.9rem;
        }
      }
    }

    .coupon {
      &-success {
        background-color: $bg;
        font-weight: $font-bold;
        margin-bottom: 2rem;
        padding: 1rem 1rem 1rem 4.4rem;
        position: relative;

        &:before {
          background: url(../../images/ic-success.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 2.6rem;
          height: 2.6rem;
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-50%);
        }
      }

      &-error {
        background-color: rgba($subcategory, 0.15);
        font-weight: $font-bold;
        margin-bottom: 2rem;
        padding: 1rem 1rem 1rem 4.4rem;
        position: relative;

        &:before {
          background: url(../../images/ic-error.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 2.6rem;
          height: 2.65rem;
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translateY(-50%);
        }
      }
    }

    &_use {
      display: flex;
      align-items: center;
      background-color: #eceff2;
      margin-bottom: 1.5rem;
      padding: 1rem 2rem;

      @media screen and (max-width: $smartphone-middle) {
        flex-direction: column;
      }

      p {
        margin: 0 1rem 0 0;

        @media screen and (max-width: $smartphone-middle) {
          margin: 0 0 1rem;
        }
      }

      &-text {
        border-bottom: 0.1rem solid $border;
        color: $add-text;
        font-weight: $font-bold;
        margin: -0.5rem 0 2rem;
        padding-bottom: 1.5rem;
        text-align: center;
      }
    }
  }

  &-payment {
    &_form {
      display: flex;
    }

    .form-radio {
      margin-right: 3rem;

      label {
        font-size: 1.4rem;
        min-height: 2.4rem;
        padding: 0.4rem 0 0 3.4rem;

        &:before {
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      input[type='radio'] + label {
        font-weight: $font-medium;
      }
    }
  }

  &-total {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $smartphone-middle) {
      display: block;
      text-align: center;
    }

    .caption {
      font-weight: $font-medium;

      .price {
        font-size: 2rem;
        font-weight: $font-bold;
        margin-left: 0.5rem;
      }
    }

    .btn {
      flex: 0 0 14rem;
      width: 14rem;

      @media screen and (max-width: $smartphone-middle) {
        margin: 2rem auto 0;
        width: 100%;
        font-size: 1.8rem;
      }
    }
  }

  &-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 510;

    &:before {
      content: '';
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.5);
      filter: blur(20px);
      -webkit-filter: blur(20px);
      z-index: 5;
    }

    &_content,
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 6;

      .text {
        display: block;
        font-size: 2rem;
        margin-bottom: 2rem;
        text-align: center;
      }
    }
  }

  &-error {
    background-color: rgba($subcategory-light, 0.25);
    color: $subcategory;
    font-size: 1.6rem;
    font-weight: $font-semibold;
    margin-bottom: 3rem;
    padding: 1.5rem;
    text-align: center;
  }

  &-invoice-requisites {
    padding-bottom: 1.8rem;

    .form-check {
      margin-left: 1rem;

      &-label {
        padding-left: 3.8rem;
        font-size: 1.4rem;
        min-height: 2.4rem;
        font-weight: normal;

        &:before {
          background-color: $bg;
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      input[type='checkbox']:checked + label {
        &:before {
          background-color: $subcategory;
          border-color: $subcategory;
        }

        &:after {
          background-image: url(../../images/ic-check-mark_w.svg);
          width: 1.3rem;
          height: 0.9rem;
        }
      }
    }
  }
}
