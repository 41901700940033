.datepicker {
  &-dropdown {
    color: $main-text;
    font-family: $font-family-second;
    font-size: 1.6rem;
    margin: 0;
    width: auto;

    &:before,
    &:after {
      display: none;
    }
  }

  table tr td,
  table tr th {
    border-radius: 0.2rem;
  }

  table tr td.active.active,
  table tr td.active.highlighted.active,
  table tr td.active.highlighted:active,
  table tr td.active:active {
    background-color: $subcategory;
    border-color: $subcategory;
  }
}

.input-group {
  &.date {
    display: block;
    position: relative;

    .arrow {
      cursor: pointer;
      position: absolute;
      top: 0.5rem;
      right: 0.1rem;
      width: 4.8rem;
      height: 3rem;
      display: block;

      &:before {
        background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.2rem;
        height: 0.74rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media screen and (max-width: $tablet) {
          background: none;
          border-top: 0.6rem solid #919ba9;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
          border-bottom: none;
          width: 1rem;
          height: 0.6rem;
        }
      }

      @media screen and (max-width: $tablet) {
        top: 0.1rem;
        width: 3.4rem;
        height: 4.6rem;
      }
    }
  }
}

.date {
  &-hint_item {
    padding: 1rem 2rem;
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: $bg-blue-gray;
    }

    &-active {
      color: $subcategory;

      &::after {
        background: url(../../images/metro/ic-check-mark_b1.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.2rem;
        height: 1rem;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
      }
    }

    @media screen and (max-width: $tablet) {
      color: $main-text;
      font-weight: $font-regular;
      padding-left: 2rem;

      &-active {
        color: $main-text;
      }
    }
  }

  input {
    @media screen and (max-width: $tablet) {
      &:focus,
      &:active {
        position: relative;
        z-index: 2;
      }
    }
  }

  .search-hint {
    width: 100%;
    max-height: 20rem;
    display: none;
    z-index: 1;

    @media screen and (max-width: $tablet) {
      border: 0.1rem solid $subcategory;
      border-radius: 0 0 1rem 1rem;
      box-shadow: none;
      margin: -0.9rem 0 0 -0.1rem;
      padding-top: 0.9rem;
    }

    @media screen and (max-width: $smartphone) {
      max-height: 16rem;
    }

    &-show {
      display: block;
    }
  }
}
