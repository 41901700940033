@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');

$font-family-base: 'IBM Plex Sans', Arial, Helvetica, sans-serif;
$font-family-base-bold: 'FuturaPT Bold', Arial, Helvetica, sans-serif;
$font-family-second: Arial, Helvetica, sans-serif;

$font-size-base: 10px;

$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 600;
$font-extrabold: 800;
$font-black: 900;
