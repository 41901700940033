input,
button,
textarea,
select {
  background-color: $white;
  border: none;
  border-radius: 0;
  color: $text;
  font-family: $font-family-base;
  font-size: 1.4rem;
  -webkit-appearance: none;

  &::-webkit-input-placeholder {
    color: $placeholder;
  }

  &::-moz-placeholder {
    color: $placeholder;
  }

  &:-moz-placeholder {
    color: $placeholder;
  }

  &:-ms-input-placeholder {
    color: $placeholder;
  }
}

button:focus {
  outline: none !important;
}

input,
select {
  border: none;
  padding: 0 2rem;
  width: 100%;
  height: 3.5rem;

  &:-internal-autofill-selected {
    background-color: $white !important;
    color: $text !important;
  }
}

textarea {
  border: none;
  width: 100%;
  height: 10rem;
  padding: 1.5rem;
  resize: none;
}

label {
  font-size: 1.4rem;
  margin-bottom: 1rem;

  @media screen and (max-width: $smartphone-middle) {
    font-size: 1.4rem;
    margin-bottom: 0.6rem;
  }

  &.label {
    color: $add-text;
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
  }
}

@media (min-width: 576px) {
  .form-inline {
    label {
      margin-bottom: 1rem;
    }
  }
}

.show-password {
  position: absolute;
  top: 2.4rem;
  right: 0;
  width: 3.2rem;
  height: 3.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.form {
  &-control {
    border: 0.2rem solid $border;
    border-radius: 0.4rem !important;
    color: $text;
    height: 3.5rem;
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
    padding: 0 2rem;

    &::-webkit-input-placeholder {
      color: $placeholder;
      opacity: 0;
    }

    &::-moz-placeholder {
      color: $placeholder;
      opacity: 0;
    }

    &:-moz-placeholder {
      color: $placeholder;
      opacity: 0;
    }

    &:-ms-input-placeholder {
      color: $placeholder;
      opacity: 0;
    }

    &[readonly] {
      background-color: $white !important;

      &::-webkit-input-placeholder {
        color: $placeholder;
        opacity: 1;
      }

      &::-moz-placeholder {
        color: $placeholder;
        opacity: 1;
      }

      &:-moz-placeholder {
        color: $placeholder;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $placeholder;
        opacity: 1;
      }
    }

    &:focus,
    &:active {
      background-color: transparent;
      border-color: $gray-border;
      box-shadow: none;
      color: $text;
      outline: none !important;
    }

    &_date {
      padding-right: 5.5rem;
    }

    @media screen and (max-width: $tablet) {
      background-color: $white;
      border: 0.05rem solid #c8c8c8;
      border-radius: 1rem !important;
      font-size: 1.4rem;
      height: 3.5rem;

      &:focus,
      &:active {
        border-color: $subcategory;
      }

      &::-webkit-input-placeholder {
        color: $main-text;
        opacity: 1;
      }
      &::-moz-placeholder {
        color: $main-text;
        opacity: 1;
      }
      &:-moz-placeholder {
        color: $main-text;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: $main-text;
        opacity: 1;
      }
    }
  }

  &-check {
    padding-left: 0;

    input[type='checkbox'] {
      display: none;
    }

    &-label {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 1.8rem;
      min-height: 3rem;
      padding-left: 4.5rem;
      position: relative;

      &:before {
        background-color: $white;
        border: 0.1rem solid $border;
        border-radius: 0.2rem;
        content: '';
        display: block;
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:after {
        background: url(../../images/ic-check-mark_b.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        width: 1.2rem;
        height: 1rem;
        position: absolute;
        top: 0.7rem;
        left: 0.6rem;
        display: none;
      }

      @media screen and (max-width: $smartphone) {
        font-size: 1.4rem;
      }
    }

    input[type='checkbox']:checked + label:after {
      display: block;
    }
  }

  &-radio {
    input[type='radio'] {
      display: none;
    }

    label {
      cursor: pointer;
      margin-bottom: 2rem;
      min-height: 2rem;
      padding-left: 3rem;
      position: relative;

      @media screen and (max-width: $tablet) {
        min-height: 2.4rem;
        margin-bottom: 1.6rem;
        padding: 0.2rem 0 0 3.4rem;
        text-transform: lowercase;

        &:first-letter {
          text-transform: capitalize;
        }
      }

      &:before {
        background-color: $white;
        border: 0.1rem solid $border;
        border-radius: 100%;
        content: '';
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 0;
        left: 0;

        @media screen and (max-width: $tablet) {
          background-color: $bg;
          padding-top: 0.2rem;
        }
      }
    }

    input[type='radio']:checked + label {
      &:before {
        background-color: $subcategory;
        border-color: $subcategory;
      }

      &:after {
        background-color: $white;
        border-radius: 100%;
        content: '';
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        top: 0.6rem;
        left: 0.6rem;
      }
    }
  }

  &-note {
    color: $add-text;
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  &-item {
    padding-bottom: 1.8rem;
    position: relative;

    &.error {
      .form-control {
        border-color: $red;
      }
    }

    &_note {
      .form-note,
      .profile-form-error {
        width: 50%;

        @media screen and (max-width: $tablet) {
          width: 100%;
        }
      }
    }

    .react-tel-input {
      .form-control {
        background-color: $border !important;

        @media screen and (max-width: $tablet) {
          background-color: $white !important;
        }
      }

      .flag-dropdown {
        background-color: $border !important;

        @media screen and (max-width: $tablet) {
          background-color: $white !important;
        }
      }
    }
  }

  .error {
    &-text {
      color: $red;
      font-size: 1.2rem;
      margin: 0;
      position: absolute;
      top: 4.8rem;
      right: 0;
      width: 100%;
      text-align: right;
    }

    &-form-border {
      border-color: $red !important;
    }
  }

  &-group {
    &_password {
      position: relative;

      input[type='password'] {
        padding-right: 5.5rem;
      }

      .btn-copy,
      .btn-view-password {
        position: absolute;
        top: 0.5rem;
        right: 0.2rem;
      }
    }
  }

  &-switch {
    position: relative;
    display: flex;
    font-size: 2rem;

    &_label {
      display: block;
      flex: 0 0 9rem;
      width: 9rem;
      text-align: center;

      &:first-child {
        color: $subcategory;
      }
    }

    &_slider {
      display: block;
      position: relative;
      cursor: pointer;
      background-color: $subcategory;
      border-radius: 5rem;
      width: 5.1rem;
      height: 3.1rem;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      &:before {
        position: absolute;
        content: '';
        height: 2.8rem;
        width: 2.8rem;
        left: 0.22rem;
        top: 0.185rem;
        background-color: $white;
        border-radius: 5rem;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input {
        display: none;
      }
    }

    input:checked + .form-switch_slider:before {
      -webkit-transform: translateX(1.9rem);
      -ms-transform: translateX(1.9rem);
      transform: translateX(1.9rem);
    }
  }

  &-dark {
    background-color: $border;
    border-radius: 0.4rem;

    @media screen and (max-width: $tablet) {
      background-color: $white;
      border: 0.05rem solid #c8c8c8;
      border-radius: 1rem;

      input {
        &::-webkit-input-placeholder {
          color: $main-text;
        }

        &::-moz-placeholder {
          color: $main-text;
        }

        &:-moz-placeholder {
          color: $main-text;
        }

        &:-ms-input-placeholder {
          color: $main-text;
        }
      }
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.4rem;
    }

    &:active,
    &:focus {
      background-color: $white;
      border: 0.1rem solid $subcategory;
    }
  }
}

.react-tel-input {
  .form-control {
    border: 0.2rem solid $border !important;
    border-radius: 0.4rem !important;
    color: $text;
    height: 3.5rem !important;
    font-size: 1.4rem !important;
    margin-bottom: 1.6rem !important;
    padding: 0 2rem;
    width: 100% !important;

    &:active,
    &:focus {
      background-color: $white !important;
      border: 0.1rem solid $border !important;
    }

    @media screen and (max-width: $tablet) {
      background-color: $white !important;
      border: 0.05rem solid #c8c8c8 !important;
      border-radius: 1rem !important;
      font-size: 1.4rem !important;
      font-weight: $font-semibold;
      padding-left: 6.2rem !important;

      &::-webkit-input-placeholder {
        color: $main-text;
      }
      &::-moz-placeholder {
        color: $main-text;
      }
      &:-moz-placeholder {
        color: $main-text;
      }
      &:-ms-input-placeholder {
        color: $main-text;
      }

      &:active,
      &:focus {
        border-color: #f1a240 !important;
      }
    }
  }

  .flag-dropdown {
    background-color: $border !important;
    border: 0.2rem solid $border !important;
    border-radius: 0.4rem 0 0 0.4rem !important;

    @media screen and (max-width: $tablet) {
      background-color: $white !important;
      border: 0.05rem solid #c8c8c8 !important;
      border-right: none !important;
      border-radius: 1rem 0 0 1rem !important;
    }

    &:active,
    &:focus {
      background-color: $white;
      border: 0.1rem solid $subcategory;
    }

    .selected-flag {
      &:hover,
      &:focus,
      &:active {
        background-color: $white !important;
        border-width: 0.1rem !important;
      }

      @media screen and (max-width: $tablet) {
        .flag {
          left: 1.5rem;
        }

        .arrow {
          border-top: 0.6rem solid #919ba9;
          border-left-width: 0.5rem;
          border-right-width: 0.5rem;
          left: 2.3rem;
        }
      }
    }

    &.open {
      z-index: 115 !important;

      .selected-flag {
        border-radius: 0.9rem 0 0 0.9rem !important;
      }
    }
  }

  .country-list {
    @media screen and (max-width: $smartphone-small - 120) {
      width: 29rem !important;
    }

    .search {
      border-radius: 0;
      padding-right: 1rem !important;

      &-box {
        padding: 0 2rem !important;
      }
    }
  }
}

textarea.form-control {
  height: 8.9rem;
  padding: 1rem 2rem;

  &::-webkit-input-placeholder {
    color: transparent;
  }

  &::-moz-placeholder {
    color: transparent;
  }

  &:-moz-placeholder {
    color: transparent;
  }

  &:-ms-input-placeholder {
    color: transparent;
  }

  @media screen and (max-width: $tablet) {
    &::-webkit-input-placeholder {
      color: $main-text;
    }
    &::-moz-placeholder {
      color: $main-text;
    }
    &:-moz-placeholder {
      color: $main-text;
    }
    &:-ms-input-placeholder {
      color: $main-text;
    }
  }
}

input[type='number'].hide-arrows {
  -moz-appearance: textfield;
}

input.hide-arrows::-webkit-outer-spin-button,
input.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
