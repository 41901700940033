.faq {
  &-content {
    max-width: 90.2rem;
    margin: 1rem auto 3rem auto;

    h1 {
      border-bottom: 0.1rem solid $border;
      font-size: 4rem;
      margin-bottom: 3rem;
      padding-bottom: 2rem;
      text-align: center;

      @media screen and (max-width: $tablet) {
        border-bottom: none;
        font-size: 2.4rem;
        padding-bottom: 0;
      }

      .number {
        color: $add-text;
        font-family: $font-family-base;
        margin-left: 1rem;
      }
    }

    .static-links {
      margin-top: 3rem;
    }

    @media screen and (max-width: $tablet) {
      margin-top: 0;
      margin-bottom: 10rem;
    }
  }

  &-answer {
    font-size: 1.6rem;
    font-weight: $font-bold;
    margin-bottom: 1rem;
  }

  &-question {
    &_text {
      padding: 2.5rem 3rem;

      p {
        margin-bottom: 0;
      }

      @media screen and (max-width: $tablet-small) {
        display: block;
      }

      @media screen and (max-width: $smartphone) {
        font-size: 1.4rem;
        line-height: 1.71;
        padding: 1rem 1.5rem;
      }
    }

    &_small {
      font-size: 1.8rem;
      margin-bottom: 4rem;
      padding: 0;
      text-align: center;

      @media screen and (max-width: $smartphone) {
        margin-bottom: 2rem;
      }
    }
  }

  &-main {
    .faq-content {
      margin: 0 auto;
    }
  }
}
