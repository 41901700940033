.paging {
  display: flex;
  justify-content: center;
  font-family: $font-family-second;
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
}

.pagination {
  border-radius: 0;
  justify-content: center;
}

.page {
  &-link {
    border-color: $border;
    border-radius: 0.4rem;
    color: $add-text;
    font-family: $font-family-second;
    font-size: 1.4rem;
    line-height: 3.2rem;
    margin-left: 0;
    padding: 0;
    width: 4.8rem;
    height: 3.4rem;
    text-align: center;

    &:hover,
    &.active {
      background-color: $text;
      border-color: $text;
      color: $white;

      path {
        fill: $white;
      }
    }

    @media screen and (max-width: $smartphone-middle + 8) {
      width: auto;
      padding: 0 1.2rem;
    }
  }

  &-item {
    padding: 0 0.2rem;
  }

  &-prev {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    display: block;

    &:before {
      background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.2rem;
      height: 0.74rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);
    }

    @media screen and (max-width: $smartphone-middle + 8) {
      position: relative;
      margin-right: 0.5rem;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-next {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    display: block;

    &:before {
      background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.2rem;
      height: 0.74rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    @media screen and (max-width: $smartphone-middle + 8) {
      position: relative;
      margin-left: 0.5rem;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  @media screen and (max-width: $smartphone-middle) {
    display: none;
  }
}
