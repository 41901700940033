.category {
  &-main {
    &_title {
      cursor: pointer;
      color: $white;
      flex: 1 1 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 0 0.4rem;
      line-height: 1.2;
      text-align: center;

      &:hover,
      &:focus {
        color: $white;
      }

      @media screen and (max-width: $hd) {
        font-size: 1.45rem;
      }
    }
  }

  &-popup {
    background-color: $white;
    box-shadow: 0 0.5rem 0.5rem rgba($text, 0.2);
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    position: absolute;
    top: 4.5rem;
    left: 0;
    width: 100%;
    z-index: 501;

    &_pic {
      flex: 0 0 6rem;
      width: 6rem;
      height: 6rem;
      margin-right: 1.5rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &_content {
      flex: 2;
    }

    &_title {
      font-size: 1.6rem;
      font-weight: $font-bold;
      margin-bottom: 0.6rem;

      a:hover,
      a:focus {
        color: $text;
      }
    }

    &_subcategory {
      li {
        padding: 0.2rem 0;

        a:hover,
        a:focus {
          color: $text;
        }
      }
    }

    &_category {
      flex: 0 0 33.33333%;
      width: 33.333333%;
      display: flex;
      padding: 1.5rem;

      a:hover,
      a:focus {
        color: $text;
      }

      .btn-more {
        font-size: 1.4rem;
      }
    }
  }

  &-page {
    padding: 0 0 8rem;
    background-color: $bg;
    width: 100%;

    .breadcrumb {
      background: transparent;
      color: $white;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;

      a,
      &-item.active {
        color: $white;
      }

      &-item + .breadcrumb-item::before {
        background-image: url(../../images/ic-angle_w.svg);
      }
    }

    .section-main {
      &.no-banner-crumbs {
        .breadcrumb {
          background-color: $bg-blue-gray;
          width: 100%;
          position: relative;

          a {
            color: $main-text;
          }

          &-item.active {
            color: $add-text;
          }

          &-item + .breadcrumb-item::before {
            background-image: url(../../images/ic-angle.svg);
          }
        }
      }

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    .search-main {
      font-size: 1.8rem;
      padding: 4rem 0 2rem;

      @media screen and (max-width: $tablet) {
        &_pic {
          display: block;
        }
      }

      .container {
        & > .row {
          flex: 2;
        }

        h1 {
          margin-bottom: 3rem;
        }
      }
    }

    &_art {
      padding-top: 3rem;
      position: relative;
      text-align: center;

      @media screen and (max-width: $tablet-middle) {
        margin-bottom: 1.5rem;
      }

      &:before {
        background: url(../../images/check-mark.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .search-result {
      border-top: 0.8rem solid $bg-blue-gray;
      //padding: 1.5rem 0;

      @media screen and (max-width: $tablet) {
        border-top: none;
        padding: 0 0.9rem;
      }

      @media screen and (max-width: $smartphone-smallest) {
        padding: 0;
      }
    }
  }

  &-tabs {
    margin-bottom: 3rem;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    .row {
      margin: 0;
      padding-left: 0.1rem;

      .breadcrumb-categories {
        list-style: none;
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 1rem;

        .breadcrumb-item:not(:first-child)::before {
          background: url(../../images/ic-angle.svg) 0 0 no-repeat;
          background-size: 100% 100%;
          content: '';
          margin-right: 1.3rem;
          padding-right: 0;
          width: 0.8rem;
          height: 0.6rem;
          transform: rotate(-90deg);
          position: absolute;
          top: 0.5rem;
          left: 1.5rem;
        }
      }
    }

    &_col {
      flex: 0 0 20%;
      width: 20%;
      @media screen and (max-width: $desktop) {
        flex: 0 0 33.333333%;
        width: 33.333333%;
      }

      @media screen and (max-width: $tablet-small) {
        flex: 0 0 100%;
        width: 100%;
      }
    }

    &_one {
      background: $white;
      border: 0.1rem solid $border;
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: $font-bold;
      height: 8rem;
      margin: 0 0 -0.1rem -0.1rem;
      padding: 1rem 11rem 1rem 1.7rem;
      position: relative;

      a:hover,
      a:focus {
        color: $text;
      }

      @media screen and (max-width: $tablet-small) {
        border-left: none;
        border-right: none;
        height: 4rem;
        padding-right: 1.7rem;
      }
    }

    &_pic {
      width: 8.5rem;
      height: 7.8rem;
      position: absolute;
      top: 0;
      right: 1.7rem;

      @media screen and (max-width: $tablet-small) {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .btn-more {
      margin: 1rem 0 0 1.7rem;
    }
  }

  &-radio {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0 0 -4rem;

    .form-radio {
      margin-left: 4rem;
    }

    &.sorting-items {
      .form-radio label {
        padding-top: 0.4rem;
      }
    }
  }

  &-filters {
    &_list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.7rem;

      @media screen and (max-width: $tablet) {
        margin-bottom: 0;
      }
    }

    &_active {
      background-color: rgba($subcategory, 0.1);
      padding: 1rem 0 0;
      border-radius: 0.6rem;

      @media screen and (max-width: $tablet) {
        padding-bottom: 1rem;
      }

      .container {
        display: flex;
        align-items: center;
      }

      .caption-title {
        flex: 0 0 8rem;
        max-width: 8rem;
        font-size: 1.4rem;
        font-weight: $font-bold;
        margin-bottom: 0.6rem;
      }

      .category-filters_list {
        margin: 0 !important;
      }
    }

    &_item {
      background-color: #dddfe0;
      border-radius: 1.6rem;
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: $font-regular;
      margin: 0 0 0.8rem 0.8rem;
      padding: 0.7rem 1.6rem 0.8rem;
      position: relative;

      .filter-close {
        background: url(../../images/ic-close_b.svg) 50% 50% no-repeat;
        background-size: cover;
        cursor: pointer;
        margin-left: 0.5rem;
        padding: 0;
        width: 1rem;
        height: 1rem;
      }

      .mobile-filter_checkbox-list {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 4;
        background-color: $white;
        box-shadow: 0 0.1rem 0.6rem 0 rgba($shadow, 0.4);
        overflow-y: auto;
        max-height: 30rem;
        overflow-x: hidden;

        .mobile-filter_checkbox {
          border-bottom: none;
          font-weight: $font-regular;
          height: 3rem;
          white-space: nowrap;
          padding: 0 2rem;

          label {
            padding-left: 2.5rem;

            &:before {
              width: 1.2rem;
              height: 1.2rem;
            }

            &:after {
              width: 0.8rem !important;
              height: 0.6rem !important;
              left: 0.2rem !important;
              top: 0.6rem !important;
            }
          }

          &:first-child {
            height: 3.5rem;
            padding-top: 0.5rem;
          }

          &:last-child {
            height: 3.5rem;
            padding-bottom: 0.5rem;
          }
        }

        .filter-search {
          padding: 1.5rem 2rem 0;
        }

        .form-control {
          font-size: 1.4rem;
          height: 3.5rem;
          padding: 0 1.5rem;

          &::-webkit-input-placeholder {
            opacity: 1;
          }

          &::-moz-placeholder {
            opacity: 1;
          }

          &:-moz-placeholder {
            opacity: 1;
          }

          &:-ms-input-placeholder {
            opacity: 1;
          }
        }
      }

      &-more {
        font-weight: $font-bold;
        padding-right: 2.3rem;

        &:after {
          background: url(../../images/ic-arrow-drop.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 0.9rem;
          height: 0.5rem;
          position: absolute;
          top: 50%;
          right: 1.2rem;
          transform: translateY(-50%);
          z-index: 1;
        }
      }
    }

    &_check {
      margin: 0.5rem 0 0.8rem 1.5rem;

      .form-check {
        &-label {
          font-size: 1.4rem;
          font-weight: $font-bold;
          min-height: 2.4rem;
          padding-left: 3rem;

          &:before {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
    }

    &_search {
      margin-left: 1.5rem;
      position: relative;

      input {
        font-size: 1.4rem;
        height: 3.25rem;
        margin-bottom: 0;
        padding: 0 1rem;

        &::-webkit-input-placeholder {
          opacity: 1;
        }

        &::-moz-placeholder {
          opacity: 1;
        }

        &:-moz-placeholder {
          opacity: 1;
        }

        &:-ms-input-placeholder {
          opacity: 1;
        }
      }

      .clear {
        position: absolute;
        top: 0.8rem;
        right: 0.5rem;
        display: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 1.7rem;
        height: 1.7rem;
        font-size: 1rem;
      }
    }
  }

  &-first {
    @media screen and (max-width: $tablet) {
      .mobile-categories_top + .container {
        margin: 0;
        padding: 0;
      }
    }
  }

  &-title {
    margin: 1rem 0 1rem;
    text-align: center;
  }
}

.subcategory {
  &-list {
    margin: 0 -0.5rem;

    @media screen and (max-width: $hd + 10) {
      margin: 0 -1.5rem 1.5rem;

      .react-multi-carousel-list {
        padding: 0 1rem;
      }
    }

    @media screen and (max-width: $tablet) {
      margin: 0 -1.5rem 0 -0.6rem;
    }

    .react-multi-carousel-item {
      width: auto !important;
    }

    a {
      background-color: $bg-blue-gray;
      border-radius: 0.4rem;
      display: block;
      font-size: 1.4rem;
      margin: 0 0.5rem;
      padding: 0.6rem 1rem;
      white-space: nowrap;

      @media screen and (max-width: $tablet) {
        background-color: #dddfe0;
        border-radius: 1.6rem;
        font-size: 1.2rem;
      }
    }
  }
}
