.noUi {
  &-target {
    background: $bg-social;
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: 0.4rem;
  }

  &-horizontal {
    .noUi-handle {
      background-color: $subcategory;
      border: 0.1rem solid $white;
      border-radius: 100%;
      box-shadow: none;
      width: 2.5rem;
      height: 2.5rem;
      top: -1rem;

      &:after,
      &:before {
        display: none;
      }
    }
  }

  &-tooltip {
    background: transparent;
    border: none;
    border-radius: 0;
    font-size: 1.4rem;
    font-weight: $font-bold;
    padding: 0;
    bottom: 2.1rem;
  }

  &-pips {
    color: $add-text;
    font-size: 1.4rem;
  }

  &-connects {
    border-radius: 0;
  }

  &-marker-horizontal.noUi-marker {
    display: none;
  }
}
