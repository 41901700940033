.history {
  &-man {
    font-size: 1.8rem;
    margin-bottom: 5rem;

    @media screen and (max-width: $tablet) {
      font-size: 1.6rem;
    }

    & > .row {
      align-items: center;
    }

    &_pic {
      overflow: hidden;
      position: relative;
      width: 100%;

      .logo-pelagia {
        position: absolute;
        left: 3rem;

        bottom: 3rem;
        img {
          width: 10rem;
        }

        @media screen and (max-width: $smartphone) {
          left: 2rem;
          bottom: 2rem;

          img {
            width: 6rem;
          }
        }
      }

      img {
        width: 60%;
        height: 60%;
        object-fit: cover;
      }

      @media screen and (max-width: $tablet-small) {
        margin-bottom: 1.5rem;
      }
    }

    &_content {
      .subtitle {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }

      h2 {
        line-height: 1.25;
        margin-top: 3rem;
        text-align: left;

        &:first-child {
          margin-top: 0;
        }
      }

      ul {
        li {
          padding-left: 1.7rem;
          position: relative;

          &:before {
            background-color: $main-text;
            border-radius: 100%;
            content: '';
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            position: absolute;
            top: 1rem;
            left: 0;
          }
        }
      }

      .btn {
        display: inline-flex;
        margin-top: 2rem;
        width: auto;
      }
    }

    &_text {
      line-height: 1.44;
      position: relative;

      @media screen and (max-width: $tablet) {
        margin-bottom: 2rem;
      }
    }

    .link-more {
      padding-top: 1.6rem;

      a {
        border-bottom: 0.1rem dashed $text;
      }
    }

    .download-doc {
      font-size: 1.4rem;
      margin-bottom: 5rem;

      img {
        max-width: 7rem;
        height: auto;
      }
    }

    h4 {
      margin-bottom: 2rem;
    }

    .contact-one {
      font-size: 1.4rem;
      margin-bottom: 2rem;

      a {
        color: $subcategory;
      }
    }

    .info-more {
      font-size: 1.2rem;

      p {
        margin-bottom: 0;
      }

      a {
        color: $subcategory;
      }
    }
  }

  &-main {
    background-color: $bg-blue-gray;
    font-size: 2.4rem;
    line-height: 1.58;
    margin-bottom: 5rem;
    padding: 3.3rem;
    text-align: center;
    display: none;

    p {
      margin: 0 auto;
      max-width: 78rem;
    }

    @media screen and (max-width: $tablet) {
      font-size: 2rem;
      line-height: 1.4;
      margin-bottom: 1rem;
    }

    @media screen and (max-width: $tablet-small) {
      padding: 3rem 0;
    }
  }

  &-years {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.5rem;

    @media screen and (max-width: $tablet) {
      display: block;
    }

    &_content {
      max-width: 90.1rem;
      margin: 0 3.2rem 0 0;

      @media screen and (max-width: $tablet) {
        margin: 0 1.5rem 0 0;
      }

      @media screen and (max-width: $tablet) {
        margin: 0;
      }
    }

    &_right {
      flex: 0 0 43.5rem;
      width: 43.5rem;

      @media screen and (max-width: $tablet) {
        flex: 0 0 35rem;
        width: 35rem;
      }

      @media screen and (max-width: $tablet) {
        flex: 0 0 100%;
        width: 100%;
      }
    }

    &_one {
      background-color: $white;
      box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
      border-radius: 0.8rem;
      line-height: 1.5;
      margin-bottom: 1rem;
      padding: 2.2rem 4rem 2.2rem 12.6rem;
      position: relative;

      p {
        margin-bottom: 0;
      }

      @media screen and (max-width: $tablet) {
        padding-left: 4rem;
      }

      @media screen and (max-width: $smartphone-small) {
        padding: 1.5rem 2.4rem 2.4rem;
      }
    }

    &_year {
      color: $subcategory;
      font-weight: $font-bold;
      font-size: 2.4rem;
      padding-left: 2.9rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @media screen and (max-width: $tablet) {
        display: block;
        padding-left: 0;
        position: relative;
        top: auto;
        transform: none;
      }
    }

    &_pic {
      margin-bottom: 2rem;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: $smartphone) {
        margin-bottom: 1rem;
      }
    }

    &_slogan {
      background-color: $bg-blue-gray;
      font-size: 1.8rem;
      line-height: 1.56;
      padding: 2.5rem 2.5rem 2.6rem;
      text-align: center;

      @media screen and (max-width: $smartphone) {
        font-size: 1.9rem;
        line-height: 1.4;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }

  &-propose {
    background-color: $bg-blue-gray;
    font-size: 1.8rem;
    margin-bottom: 6rem;
    padding: 4rem 0;

    @media screen and (max-width: $smartphone) {
      margin-bottom: 2rem;
    }

    &_left {
      font-weight: $font-bold;
      line-height: 1.42;
      margin-bottom: 2rem;
      max-width: 55.5rem;

      .title {
        font-size: 3rem;
        margin-bottom: 1.5rem;
        text-transform: uppercase;

        @media screen and (max-width: $smartphone) {
          font-size: 2.4rem;
        }
      }
    }

    &_list {
      line-height: 1.33;

      li {
        margin-top: 2rem;
        padding-left: 6.4rem;
        position: relative;

        &:first-child {
          margin-top: 0;
        }

        &:before {
          background-color: $add-text;
          content: '';
          display: block;
          width: 3.3rem;
          height: 0.1rem;
          position: absolute;
          left: 0;
          top: 1.2rem;
        }

        @media screen and (max-width: $tablet) {
          padding-left: 4rem;
        }

        @media screen and (max-width: $smartphone) {
          padding-left: 3rem;

          &:before {
            width: 2.3rem;
            top: 1.4rem;
          }
        }
      }
    }
  }

  &-tradition {
    &_pic {
      margin-bottom: 3.1rem;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: $smartphone) {
        margin-bottom: 1.5rem;
      }
    }

    &_text {
      font-size: 1.8rem;
      line-height: 1.56;
      padding-left: 6.4rem;

      p {
        margin-bottom: 0;
      }

      @media screen and (max-width: $tablet) {
        padding-left: 0;
      }
    }

    &_content {
      line-height: 1.5;
      max-width: 76rem;
      margin-bottom: 3rem;

      h3 {
        font-weight: $font-bold;
        font-size: 2.2rem;
        margin-bottom: 3rem;
        text-align: left;

        @media screen and (max-width: $smartphone) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}

.section-about {
  .history-man_pic img {
    width: 100%;
    height: 100%;
  }
}
