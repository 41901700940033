//carousel

.rc-slider-mark-text {
  width: 5rem !important;
}

.delivery-row_right {
  box-shadow: none;
}

.btn-scroll {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &:active {
    box-shadow: 20px 0px 20px 20px #ffffffe0 !important;
  }

  &-right {
    right: 0;

    &:active {
      box-shadow: -20px 0px 20px 20px #ffffffe0 !important;
    }
  }
}

.form-check-label {
  display: inline-flex;
}

.icon-reload-anim {
  animation-name: anim-reload;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
}

@keyframes anim-reload {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

.cover-products {
  position: absolute;
  background-color: #ffffff70;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
}

.quantity-form_inside input {
  padding: 0;
}

.betatest {
  max-width: 80%;
  margin: 2rem auto;
  text-align: center;
  position: relative;
  font-size: 2.5rem;
  padding: 2rem 0;
  background: #06a6e538;
  box-shadow: 0px 0rem 1.1rem 1.7px rgba(160, 160, 160, 0.2);

  p {
    margin: 0;
    margin-right: 5rem;
  }

  .btn-delete {
    border: none;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.section-checkout {
  .form-check {
    display: inline-flex;
  }
}

///modal

.basket-block_comment {
  textarea {
    border: 1px solid $blue-gray;
  }
}

.loading-center {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
}

.checkout-content {
  .breadcrumb-mobile {
    display: block;

    a {
      display: block;
      cursor: pointer;

      position: relative;
      padding-left: 2.8rem;

      &::before {
        background: url(../images/ic-arrow.svg) 0 0 no-repeat;
        content: '';
        display: block;
        width: 1.7rem;
        height: 1.1rem;
        position: absolute;
        top: 50%;
        left: 0;
        transform: rotate(180deg) translateY(50%);
      }
    }
  }
}

.profile-form-item {
  position: relative;
  flex: 1;

  &.phone-number {
    .react-tel-input {
      margin-bottom: 1.6rem;
    }
  }

  @media screen and (max-width: $tablet) {
    padding-bottom: 0.1rem;
  }
}

.profile-register .form-control {
  margin-bottom: 2.5rem;
}

.error-block-code {
  position: absolute;
  top: 100%;
  left: 0;
  color: $red;
  font-size: 1.6rem;
}

.restore-code_block {
  position: relative;
}

///error
.error-con {
  position: relative;
  display: flex;

  input,
  button {
    margin-bottom: 2.5rem !important;
  }
}

.profile-form-item,
.profile-form .form-group_password {
  display: flex;
}

.form-group_password {
  flex-wrap: wrap;
}

.checkout-delivery_address-full {
  .profile-form-error {
    display: none;
  }

  .form-part .profile-form-error {
    display: initial;
  }

  .col-lg-6:nth-child(2) {
    input {
      margin: 0;
    }
  }
}

.profile-form-error {
  & ~ .checkout-delivery_address-street {
    border: 1px solid #dc3545;
  }

  & ~ .checkout-delivery_address > .form-control {
    border: 1px solid #dc3545;
  }
}

.profile-register .profile-info_row {
  position: relative;
}

.form-control:focus,
.form-control:active {
  background: #fff;
}

.info_modal {
  .modal_add-card .modal-footer .btn {
    width: 23rem;
  }

  .modal_add-card .modal-footer {
    justify-content: center;
  }

  .modal_add-card .modal-header {
    justify-content: center;
  }
}

.modal_assortmant {
  display: flex;
  justify-content: center;
  align-items: center;

  .delivery-row_right {
    height: fit-content;
  }

  .modal-delivery {
    height: 80vh;
  }

  @media screen and (max-width: 640px) {
    .modal-delivery {
      height: 100vh;
    }
  }

  .modal-dialog {
    .modal-content {
      height: 100%;
    }
  }

  .delivery-row {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 540px) {
  .modal_assortmant {
    .delivery-row {
      margin-bottom: 25rem;
    }
  }
}

@keyframes show-error {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//time
.block-time-range {
  display: flex;
  align-items: center;
  height: 5rem;
  position: relative;

  .separate {
    font-size: 2.1rem;
    margin: 0;
    padding: 0 1rem;
  }

  .rc-time-picker-input {
    border: none;
    font-size: 1.7rem;
    color: inherit;
    text-align: center;
  }

  .rc-time-picker-clear {
    display: none;
  }
}

pre {
  font-family: 'FuturaPT Medium', Arial, Helvetica, sans-serif;
}

.time-input {
  padding: 1rem 2rem;
  cursor: pointer;
  border: 0.1rem solid #dadeea;
  position: relative;
  max-width: 113px;
  margin: 0;
}

.datepicker-modal {
  position: fixed !important;

  .datepicker.default .datepicker-wheel {
    border-top: 1px solid #00a6e7;
    border-bottom: 1px solid #00a6e7;
  }

  .datepicker.default .datepicker-navbar-btn {
    color: #00a6e7;
  }
}

//date picker
.react-date-picker__wrapper {
  @extend .form-control;
  margin-bottom: 0;
}

.form-control_date {
  padding: 0;
  flex: 1;
}

.calendar-custom {
  .react-calendar__month-view__days__day--weekend {
    color: $main-text;
  }

  .react-calendar__tile--active {
    background-color: $subcategory;
  }
}

.react-date-picker__calendar {
  z-index: 300;
}

.checkout-profile .btn-2 {
  top: 3.9rem;
}

.checkout-delivery_address .btn-map {
  z-index: 1;
}

.search-hint_item {
  cursor: pointer;
  background-color: white;
}

.search-hint {
}

.checkout-delivery_address-full .form-part label {
  z-index: 10;
  transition: all 300ms;
  pointer-events: none;
}

.checkout-delivery_address-full .checkout-delivery_address-street {
  .input-group {
    margin: 0;
  }

  input {
    border: none;
  }

  .input-group.date .arrow {
    border: none;
  }

  .search-hint {
    left: -1px;
    width: calc(100% + 2px);
    max-height: 20rem;
    top: calc(100% + 1px);
  }
}

//search from categories

.footer-contact {
  width: 22rem;
  padding-left: 0;
}

//address-drop-down
.checkout-delivery_form .checkout-delivery_address {
  margin-bottom: 0;
}

.checkout-delivery_form {
  .profile-info_row {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: 575px) {
    .profile-info_row:last-child {
      padding: 0;
    }
  }
}

.form-address-con {
  .checkout-delivery_address-full {
    display: flex;
  }

  .checkout-delivery_address {
    display: flex;
  }

  .checkout-delivery_address-full {
    padding-left: 0;
  }
}

.address-con {
  position: relative;
}

.address-drop-down {
  top: calc(100% - 1px);
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #dadeea;
}

.order-postition-last {
  order: 10;
}

.input-group {
  .arrow {
    &::before {
      transition: transform 300ms;
      transform-origin: center;
    }
  }

  .arrow-focus {
    z-index: 10;

    &::before {
      transform: translate(-50%, -50%) rotate(180deg) !important;
    }
  }
}

///edit receiver
.checkout-delivery_user.edit-user {
  padding: 1rem 4.8rem 1rem 1rem;
  font-size: 1.6rem;
}

///slider-tip
.slider-tip {
  border-radius: 1px;
  box-shadow: 0 0.5px 3px 0 rgba(160, 160, 160, 0.2);
  border: solid 0.5px #dadeea;
  background-color: #ffffff;
  width: 7rem;
  padding: 0.4rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5rem;
  left: -2.5rem;
  color: #01a6e6;
  font-size: 1.6rem;

  &::before {
    position: absolute;
    display: block;
    content: '';
    width: 2rem;
    height: 2rem;
    box-shadow: 0 0.5px 3px 0 rgba(160, 160, 160, 0.2);
    z-index: -1;
    top: 60%;
    border: solid 0.5px #dadeea;
    transform: rotate(45deg);
  }

  &::after {
    position: absolute;
    display: block;
    content: '';
    width: 1.8rem;
    height: 1.9rem;
    z-index: 1;
    background-color: white;
    top: 60%;
    transform: rotate(45deg) translateX(0px);
  }

  .tip {
    z-index: 2;
  }
}

@keyframes anim-tip {
  from {
    opacity: 0;
    transform: rotate(45deg) scale(0.5);
  }
  50% {
    transform: rotate(45deg) scale(1.2);
  }
  to {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
}

//scroll-category
.scroll-category {
  position: relative;
  background-color: white;
  width: auto;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;

  &:hover {
    .prev,
    .next {
      opacity: 1;
      transform: scale(1.4);
    }
  }

  .prev,
  .next {
    background-color: transparent;
    opacity: 0.3;
    transition: opacity,
    transform 300ms;
    font-size: 2rem;
    transform: scale(1.2);
  }
}

.checkout-delivery_card {
  cursor: pointer;
  padding: 2rem 0 2rem 2.5rem;
  margin: 0 1rem 1rem 1rem;
}

.checkout-delivery_card-default {
  background-color: rgba(0, 166, 231, 0.1);
}

/* .global-search {
  position: relative;
  margin: 0;
  width: 100%;
  top: 100%;
  max-width: 17rem;
  margin-right: 1rem;
  cursor: pointer;
  color: #1e2e67;
  border-bottom: 0.1rem solid #dadeea;
  transition: border 300ms;
  &:hover {
    border-bottom: 0.1rem solid #1e2e67;
  }
} */

.section-static .tab-content {
  position: relative;

  .tab-pane {
    position: relative;
  }
}

.tab-content > .tab-pane {
  display: block;
}

.nav-link {
  cursor: pointer;
}

/// form
.modal-form-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .profile-info_row {
    flex-basis: 48%;
    margin: 0;
    display: block;
    position: relative;
  }

  .profile-form-error {
    bottom: 0;
    padding: 0;
  }

  .profile-info_label {
    width: auto;
    flex: auto;
  }

  &.modal-form-profile-pass {
    .pass-row {
      display: grid;

      .btn-show-password,
      .btn-hide-password {
        top: auto;
        margin-top: 2.8rem;
        right: 4rem;
        height: 1.4rem;
        @media screen and ((max-width: $smartphone) or (max-width: $smartphone-middle) or (max-width: $smartphone-small) or (max-width: $tablet)) {
          margin-top: 3.5rem;
          height: 1.6rem;
        }
      }

      .btn-hide-password {
        margin-top: 2.4rem;
        height: 1.7rem;
        @media screen and ((max-width: $smartphone) or (max-width: $smartphone-middle) or (max-width: $smartphone-small) or (max-width: $tablet)) {
          margin-top: 3.6rem;
        }
      }

      input::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 1.3rem;
        line-height: 1.6rem;
        color: #8f9bab;
        opacity: 0.7;
      }
    }
  }
}

.modal-form-profile-email,
.modal-form-profile-pass {
  display: block;
}

.btn-delete-address {
  &:before {
    background: url(../images/ic-close.svg) 50% 50% no-repeat;
    width: 1.6rem;
  }
}

.input-phone {
  padding: 0;
}

.link-blue {
  line-height: 5rem;
}

.profile-change-pass input[type='password'] {
  margin-bottom: 2.5rem;
}

.profile-form-password {
  margin-bottom: 3rem;
}

.util-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 7rem;
  height: 7rem;
  z-index: 100;
  background-color: rgba($white, 0.5);
  opacity: 0.3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms;

  &:hover {
    opacity: 1;
  }

  img {
    width: 70%;
  }

  span {
    position: absolute;
    color: white;
    top: 2.1rem;
    left: 1.9rem;
    width: 4rem;
    font-size: 1.6rem;
    text-align: center;
  }
}

body {
  overflow-x: hidden;
}

///lang
.block-lang {
  .lang {
    background-color: white;
    color: $subcategory;
    font-weight: bold;
    font-size: 2.2rem;
    padding: 0.2rem 1rem;
    cursor: pointer;
    text-transform: uppercase;

    &-active {
      background-color: $subcategory;
      color: white;
    }
  }
}

.checkout-delivery_card {
  position: relative;

  .btn-delete {
    position: absolute;
    top: 1px;
    right: 0;
    border: none;
    background-color: transparent;
    z-index: 111;

    &:hover {
      &::before {
        transition: transform 300ms;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }
  }
}

.checkout-result .name:after {
  content: '';
}

.checkout-delivery_block {
  position: relative;
}

.tooltip-products {
  cursor: pointer;
  background-color: rgb(234, 246, 253);
  border-radius: 5px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2000;
  padding: 1rem;

  p {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 30rem;
    margin: 0;
  }
}

.profile-info_row {
  .link-blue {
    line-height: initial;
  }
}

.pagination-orders {
  .load-more {
    margin: 3rem 0 0;
  }
}

.paging {
  margin-top: 5rem;
}

.number-reject {
  text-decoration: line-through;
}

.profile-order_description,
.profile-order_history-link {
  cursor: pointer;
}

.preloader-item {
  box-shadow: 0 0.1rem 0.6rem 0 rgba(160, 160, 160, 0.2);

  &-category {
    position: absolute;
  }
}

.form-select-contact {
  margin: auto;
  display: flex;
  justify-content: center;

  .profile-info-addresses {
    max-height: none;
  }
}

.footer-phone {
  display: block;
}

.checkout-delivery_delivery-right .basket-block_comment {
  margin-top: 2rem;
  font-size: 2rem;
}

.btn-save::before {
  height: 1.2rem;
}

.checkout-delivery_no-product .no-product_hint {
  display: block;
  z-index: 22222;
  background-color: white;
}

.basket-block_comment-form .form-control,
.basket-block_comment-form .react-date-picker__wrapper {
  margin: 1rem 0 1.15rem;
  height: auto;
}

.basket-block_comment-form .btn-send {
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 0.1rem;
  display: flex;
  height: initial;
}

.no-match {
  color: #a0a9ba !important;
}

.dropdown-menu ul {
  border-bottom: none;
}

.form-switch {
  display: inline-flex;
}

.form-switch_label:first-child {
  color: inherit;
}

.form-switch_label-color {
  color: #00a6e7 !important;
}

.rc-slider-disabled {
  background-color: white;
}

.error-after-signup {
  border: 1px solid red;
  padding: 1.5rem 3rem;
  font-weight: bold;
  font-size: 18px;
}

.quantity-form_inside .btn-minus,
.quantity-form_inside .btn-plus {
  flex: 0 0 3.1rem;
  width: 3.1rem;
}

.product-content_shop .product-content_quantity .weight-choose_form {
  display: flex;
}

.form-switch {
  padding: 3rem 0;
}

.footer-lang_list {
  a {
    cursor: pointer;
  }

  .disabled {
    cursor: initial;
    background-color: #dadeea;

    a {
      border: 1px #dadeea solid !important;
      color: inherit !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.checkout-delivery input[type='radio']:checked + label {
  cursor: initial;
}

.checkout-delivery_address-full .form-part {
  width: 100%;

  .profile-form-item {
    input::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 1.3rem;
      line-height: 1.6rem;
      color: $placeholder;
      opacity: 0.7;
    }
  }
}

.checkout-delivery_address-full .checkout-delivery_address-street {
  margin-bottom: 3rem;
}

.section-system-error {
  width: 100%;
  max-width: 55%;
  height: auto;
  margin: 0 auto;

  img {
    width: 100%;
  }
}

.product-weight {
  .rc-slider-handle {
    background-color: rgb(1, 166, 230);
    width: 20px;
    height: 20px;
    margin-top: -8px;
    border: 1px solid white;
  }
}

.form-switch_slider {
  width: 5.2rem;

  &::before {
    height: 2.7rem;
    width: 2.7rem;
    left: 0.32rem;
    top: 0.185rem;
  }
}

.carousel-item-category {
  padding: 0 1rem;
  width: auto !important;
}

.checkout-btn-row .form-check {
  height: auto;
}

.checkout-certificate .dropdown {
  max-width: 41.5rem;
  margin: auto;
}

.modal-dialog.modal-delivery {
  .modal-content {
    position: static;

    overflow: auto;
  }

  .modal-customScrollbar {
    &::-webkit-scrollbar-track {
      background-color: $main-text;
    }

    &::-webkit-scrollbar {
      width: 2px;
      background-color: $main-text;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $white;
    }
  }
}

.customScrollbarSellPoint {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    height: 3px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $subcategory;
  }
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.shop-about_info .info-time {
  margin-bottom: 0;
  font-size: 2rem;
}

.menu_item_category {
  background-color: #01a6e6;
  color: #fff;
  font-size: 2.8rem;
  margin-bottom: 2rem;
  padding: 0.9rem 1rem 0.8rem;
  text-align: center;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
}

.menu-page {
  margin-top: 2rem;

  .header-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
}

.no-body-menu {
  padding-top: 0;
  padding-bottom: 0;

  footer {
    position: relative;
  }
}

.basket-block_comment {
  .basket-block_comment-form {
    .form-check input[type='checkbox']:checked + label {
      color: rgba(160, 169, 186, 1);
    }

    .comment-services {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 2rem;

      .form-check-input {
        display: none;
      }
    }

    .form-check {
      flex-basis: 50%;
      width: 50%;
    }

    .form-check-label {
      margin-bottom: 1rem;
    }

    p {
      padding-left: 2rem;
      margin-bottom: 0;
    }

    .form-control {
      border: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .product-content_shop .product-content_quantity {
    flex: auto;
    width: auto;
  }

  .scrollbar-outer {
    max-width: 100%;
  }

  .footer-contact {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .main-categories_pic {
    height: 27rem;
  }

  //  .basket-popup {
  //    overflow-y: auto;
  //  }
  .basket-block_scroll {
    height: auto;
  }
  .header-right_delivery .header-delivery {
    display: flex;
  }

  .catalog-filter_search .search-form {
  }
}

@media screen and (max-width: 767px) {
  .product-content_shop .product-content_quantity .weight-choose_form {
    margin-right: 4rem;
  }
  .main-categories_pic {
    height: 22rem;
  }
  .product-content_shop .product-content_quantity {
    display: flex;
  }
  .pagination-orders {
    .paging {
      display: flex;
    }
  }
  .catalog-left.open .catalog-left_mobile {
    height: 100%;
  }
  .search-form:before {
    content: none;
  }
}

@media screen and (max-width: 640px) {
  .header-right_delivery .header-delivery {
    display: none;
  }
  .product-content_shop .product-weight {
    margin-bottom: 4.5rem;
  }
  .catalog-filter_btn {
    margin: 0;
  }
  .checkout-profile .btn-2 {
    top: 2.9rem;
  }
  .section-tabs h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  .catalog-filter {
    margin-bottom: 3.5rem;
  }
  .modal-body-custom {
    .modal_add-card {
      width: 100%;
    }

    .checkout-delivery_address-street,
    .checkout-delivery_address {
      margin-bottom: 3rem;
    }
  }
  .address-order {
    width: 100%;
  }
  .checkout-pay {
    margin: 2.4rem 0 1rem;
  }
  .modal_add-card {
    .modal-footer {
      &-card {
        justify-content: center;
      }
    }
  }

  .dropdown-menu-sort {
    width: 115%;
    left: -4rem;
  }

  .modal-dialog.modal-delivery {
    .modal-content {
      height: 100vh;
    }
  }

  .modal-delivery .modal-footer {
    position: fixed;
  }
  .delivery-row {
    padding-bottom: 20rem;
  }

  .catalog-left.open .catalog-menu_link {
    font-size: 2rem;
    padding: 1.5rem 1.5rem;
  }
  .catalog-menu a {
    font-size: 2.3rem;
  }
  .catalog-menu {
    ul > li {
      padding: 0.5rem;
    }
  }

  .item-food_in_menu {
    padding-bottom: 5rem;

    .item-food_price .price {
      padding: 0;
    }
  }
}

@media screen and (max-width: 575px) {
  .modal-form-profile {
    display: block;
  }
  .main-categories_pic {
    height: 30rem;
  }
}

@media screen and (max-width: 480px) {
  .catalog-filter-con {
    margin-bottom: 3.7rem;
  }
}

.howToCookFast {
  background-color: $gray-f9;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  padding: 1.3rem 1.9rem 1.3rem 2.5rem;
  align-items: center;

  .video {
    width: 90px;
    height: 51px;
    margin-left: 1rem;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;

    .playIcon {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $subcategory;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 57%;
        transform: translate(-50%, -50%);
        width: 13px;
        height: 13px;
        clip-path: polygon(100% 50%, 0 0, 0 100%);
        background-color: $white;
      }
    }
  }
}

.videoFrameProduct {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(50, 58, 110);
  background: linear-gradient(90deg, rgba(50, 58, 110, 0.6867749419953597) 100%, rgba(0, 212, 255, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1100;

  .content {
    width: 1106px;
    height: 622px;
    position: relative;

    @media screen and (max-width: $l-hd) {
      width: 806px;
      height: 453px;
    }

    @media screen and (max-width: $hd) {
      width: 690px;
      height: 390px;
    }

    @media screen and (max-width: $desktop) {
      width: 588px;
      height: 331px;
    }

    @media screen and (max-width: $tablet) {
      width: 569px;
      height: 320px;
    }

    @media screen and (max-width: $tablet-small) {
      width: 440px;
      height: 248px;
    }

    @media screen and (max-width: $smartphone) {
      width: 367px;
      height: 207px;
    }

    @media screen and (max-width: $smartphone-middle) {
      width: 329px;
      height: 185px;
    }

    @media screen and (max-width: $smartphone-small) {
      width: 274px;
      height: 156px;
    }

    .positionCloseVideo {
      right: -50px;

      @media screen and (max-width: $smartphone-small) {
        right: 0;
        top: -5rem;
      }
    }
  }
}

.width100Procent {
  width: 100%;
}

.header-menu_left {
  .activeLink {
    color: $add-text;
  }
}

.blue-hover {
  &:hover {
    color: $subcategory !important;
  }
}

.item-food_add {
  p {
    margin-bottom: 0;
  }
}

.errorContainerForCode {
  position: absolute;
  bottom: -3rem;
  width: 100%;
}

.containerDeliveryCity {
  @media screen and (max-width: $hd) {
    display: flex !important;
    justify-content: space-between;
    gap: 1rem;
  }

  @media screen and (max-width: $smartphone-small) {
    flex-direction: column;
    height: 20rem !important;
  }
}

.selectDeliveryCity_container {
  flex: 1 1 27rem !important;

  .arrow {
    background: url(../images/ic-arrow_w.svg) 0 0 no-repeat;
    background-size: cover;
    width: 2.8rem;
    height: 1.69rem;
    position: absolute;
    top: 2.5rem;
    right: 2.3rem;
    cursor: pointer;
    display: none !important;
    color: $white !important;
  }

  .padding-frame {
    padding: 1.3rem 2.2rem !important;
    line-height: 1;
    width: 100%;
    background-color: inherit;

    &:hover {
      background-color: $subcategory;
      border: 0.1rem solid $white;
      color: $white;

      span {
        color: $white;
      }

      .arrow {
        display: block !important;
        cursor: pointer;
      }
    }

    .cityTitle {
      color: $white;
    }

    span {
      padding: 0;

      &::after {
        display: none;
      }
    }

    .check-ok {
      background: $white url(../images/ic-check-mark_b.svg) 50% 50% no-repeat;
      background-size: 1.35rem 0.88rem;
      border-radius: 100%;
      display: block;
      width: 5rem;
      height: 5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.2rem;
    }
  }

  .cityTitle {
    font-size: 24px;
  }
}

.img-delivery_castom {
  @media screen and (max-width: $hd) {
    display: block !important;
  }

  @media screen and (max-width: $tablet) {
    display: none !important;
  }
}

.btn-attach {
  cursor: pointer;
}

.disableDeliveryCity {
  &:hover {
    background-color: inherit !important;
  }

  .padding-frame {
    background: #393e5e;
    opacity: 0.3;
    border: 1px solid rgba(218, 222, 234, 0.500191);
    box-sizing: border-box;

    &:hover {
      background-color: inherit !important;
      border: 0.1rem solid rgba(218, 222, 234, 0.500191) !important;
      color: inherit !important;

      .arrow {
        display: none !important;
      }
    }
  }

  .arrow {
    display: none !important;
  }
}

.reviewMessage {
  display: contents;
  width: max-content;
  padding-top: 1rem;
  color: $blue;
}

.description_text {
  font-size: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: $tablet) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: $smartphone-middle) {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: $tablet-small) {
  .productTimeToPrepare {
    margin-bottom: 2.5rem !important;
    padding: 0;

    .weight-choose {
      display: block !important;
    }
  }
}

.border-link {
  border-bottom: 0.1rem dashed #3b3b48;
}

.mobile-category_item--flex {
  @media screen and (max-width: $smartphone-small) {
    display: flex;

    .mobile-category-text__center {
      align-self: center;
      width: 100%;

      &::before {
        // background: url(/static/media/ic-arrow.c084c244.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.2rem;
        height: 0.75rem;
        position: absolute;
        top: 50%;
        right: 3rem;
        transform: translateY(-50%) rotate(-90deg);
      }
    }

    .mobile-subTitle-hide {
      display: none;
    }
  }
}

.tab-categories {
  text-align: center;
}

@media screen and (max-width: 1430px) {
  .tab-categories h1 {
    max-width: 61rem;
    margin: 0 auto 3.6rem;
  }
}

@media screen and (max-width: 1200px) {
  .tab-categories h1 {
    max-width: 81.2rem;
  }
}

.tab-categories {
  text-align: center;

  @media screen and (max-width: $l-hd + 30) {
    h1 {
      max-width: 61rem;
      margin: 0 auto 3.6rem;
    }
  }

  @media screen and (max-width: $hd - 80) {
    h1 {
      max-width: 81.2rem;
    }
  }
}

.section-category {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.2rem;
  text-align: left;
}

.section-category div[class*='col-'] {
  margin-bottom: 0.4rem;
  padding: 0;
}

@media screen and (max-width: 880px) {
  .section-category div[class*='col-'] {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .section-category div[class*='col-'] {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0;
  }
}

.popup-city_before {
  @media screen and (max-width: $tablet-middle) {
    &::before {
      left: 10% !important;
    }
  }
}

.custom-search-city {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
  border-bottom: 1px solid #e3eaf2;

  .inputCity {
    padding-left: 8px;
    line-height: 18px;

    &::placeholder {
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      color: #8f9bab;
    }
  }
}

.custom-scroll_wrapper {
  width: 100%;
  height: 209px;
  overflow-y: scroll;
  position: relative;

  .city-list {
    li {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      padding: 8px;
      margin-top: 4px;
      margin-bottom: 4px;
      cursor: pointer;

      &:hover {
        background-color: #e3eaf2;
      }
    }
  }

  &::-webkit-scrollbar-track {
    background-color: #e3eaf2;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: #e3eaf2;
  }

  &::-webkit-scrollbar-thumb {
    max-height: 90px !important;
    background-color: #3b3b48;
  }
}

.popup-city_before {
  @media screen and (max-width: $tablet-middle) {
    &::before {
      left: 10% !important;
    }
  }
}

.address-district {
  font-size: 14px;
  margin-left: 1rem;
  color: #a3a9ac;
}

.choose-number-container {
  width: 100%;
  padding: 1rem;
  background-color: #d01101;
  border-radius: 0.8rem;
  position: absolute;
  top: 100%;
  left: 0;
}

.choose-number-error {
  color: #fff;
  width: 100%;
}

.search-main .search-form {
  margin-bottom: 0.3rem;
}

.search-form_block {
  position: relative;
}

.catalog-popup-item-li {
  display: flex;

  &.active {
    cursor: pointer;

    .category-main_title {
      border-radius: 4px 4px 0 0;
      background: #fff;
      color: #000;
      z-index: 999;

      &:hover,
      &:focus {
        color: $text;
      }
    }
  }
}

.custom-drop-down {
  position: absolute;
  right: 0;
  background-color: #fff;
  z-index: 10;
  padding: 16px;
  border-radius: 4px 0px 4px 4px;
  //TODO:
  // box-shadow: rgb(0, 0, 0 / 30%) 1px 2px 6px 0px;
}

.custom-drop-down_more {
  display: flex !important;
  cursor: pointer;

  &:hover {
    color: $subcategory !important;
  }
}

.custom-drop-down_item {
  padding: 8px 16px;
  border-radius: 4px;
}

.custom-drop-down_item:hover {
  background: rgb(242, 244, 244);
}

.custom-drop-down_link {
  padding: 0 !important;
}

.menu-list-item {
  &-active,
  &:hover,
  &:focus {
    border-radius: 4px;
    background: rgb(242, 244, 244);
  }
}

.mobile-menu-container {
  .btn-back {
    border-bottom: 0.1rem solid $border;
    color: $add-text;
    display: block;
    padding: 1rem 1.5rem 1rem 3.5rem;
    position: relative;

    &:before {
      background: url(../images/ic-angle.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.2rem;
      height: 0.74rem;
      position: absolute;
      top: 1.7rem;
      left: 1.5rem;
      transform: rotate(90deg);
    }
  }
}

.categories-mobile-container {
  background-color: $bg-blue-gray;
  border-bottom: 0.1rem solid $border;
  box-shadow: inset 0 0.4rem 0.4rem rgba($text, 0.04);
}

.more-btn-menu {
  border-left: 1px solid #e7eaeb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 6rem;
  width: 6rem;
}

.all-categories-menu-mobile {
  box-shadow: inset 0 -0.4rem 0.4rem rgba($text, 0.04);

  .btn-wishlist {
    border-bottom: 0.1rem solid $border;
    display: block;
    padding: 1rem 1.5rem 1rem 4.5rem;
    position: relative;

    &:before {
      background: url(../images/ic-heart.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 2rem;
      height: 1.9rem;
      position: absolute;
      top: 1.1rem;
      left: 1.5rem;
    }
  }
}

.all-categories-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem 6rem 1rem 0;
  position: relative;

  & > li {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;

    &:first-child {
      border-right: 0.1rem solid $border;
    }

    &:last-child {
      position: absolute;
      top: 0;
      right: 0;
      width: 6rem;
      height: 100%;
      padding: 0;
    }
  }
}

.own-categories-close-menu {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid $border;
}

.close-menu-item {
  font-size: 1.3rem;

  &.wishlist {
    padding-top: 2.3rem;
    position: relative;

    &:before {
      background: url(../images/ic-heart.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 2rem;
      height: 1.9rem;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.category-menu-item {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0 1.5rem;
}

.own-categories-mobile-container {
  display: flex;
  flex-direction: column;
}

.own-category-item {
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 0 1.5rem;
  width: 100%;
}

.notification {
  &-open {
    max-height: 95vh;
    height: 100%;
    overflow-y: auto;
  }

  &-close {
    max-height: 7.5rem;
  }
}

.info-name:hover {
  cursor: pointer;
  color: $subcategory;
}

.low-price-error {
  color: $red;
  font-size: 1.4rem;
}

.product-title-item:hover {
  cursor: pointer;
  color: $subcategory;
}

.dont-show-again-btn {
  text-decoration: underline;
}

.dont-show-again-btn:hover {
  cursor: pointer;
}

.pay-form-container {
  padding: 0 0 4rem 4rem;

  @media screen and (max-width: $tablet) {
    padding: 0 1.5rem;

    button {
      width: 100%;
      font-weight: $font-semibold;
      height: 4.8rem;
    }

    .paypal-buttons {
      z-index: 1;
    }
  }
}

.slider-hover:hover {
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .grecaptcha-badge {
    bottom: 3px !important;
  }
}

.own-categories-close-menu img {
  width: 50px;
  height: 50px;
  margin-top: 5px;
}

.return-mobile-btn {
  background: url('../images/metro/ic-arrow_b.svg') 0 0 no-repeat;
  content: '';
  display: block;
  width: 1.5rem;
  height: 1rem;
  position: absolute;
  top: 50%;
  left: 2.7rem;
  transform: translateY(-50%);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}

.date .search-hint {
  z-index: 100;
}

@media screen and (max-width: 991px) {
  .mobile-menu {
    z-index: 11;
  }
}

@media screen and (max-width: 991px) {
  .pay-form-container {
    padding-bottom: 1.5rem;
  }
}

.category-page .search-result .infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.4rem;
  padding-top: 1rem;
}

.header-search-close-icon:hover {
  cursor: pointer;
}

.header-search-close-icon {
  margin-right: 0.5rem;
}

.category-tab-item:hover {
  cursor: pointer;
}

//.banner-home-mobile img {
//  object-fit: fill !important;
//}
.category-filters_item-more:hover {
  cursor: pointer;
}

.modal-filter_container.step-2 {
  height: 100vh;
}

.mobile-filter_checkbox:hover,
.select-filter-label {
  cursor: pointer;
}

.footer-payment_list {
  li {
    display: flex;
    align-items: center;

    img {
      width: 3rem;
      margin-right: 1rem;
    }
  }
}

.footer-contact:hover {
  color: #d3d3d3;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.brands-logo:hover {
  cursor: pointer;
}

.pay-dropdown_block .info-row:hover {
  cursor: pointer;
}

.brands-search .search-close:hover {
  cursor: pointer;
}

.product-pic_pic-more img:hover {
  cursor: pointer;
}

.login-popup {
  overflow: auto;
}

.pay-price-sale {
  color: $red;
  font-weight: bold;
}

.pay-price-old {
  text-decoration: line-through;
}

.zero-result {
  font-weight: bold;
  margin: 0;
}

.no-search-result {
  margin: 10rem;
}

.mobile-filter-only {
  width: 50%;
  border-right: 0.1rem solid #d5dde0;
}

.subcategory-link {
  background-color: #f4f8fb;
  border-radius: 0.4rem;
  display: block;
  font-size: 1.4rem;
  margin: 0 0.5rem;
  padding: 0.6rem 1rem;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: $text;
  }
}

.btn-more:hover,
.custom-drop-down_link:hover,
.custom-drop_link:hover,
.slider-main-title:hover {
  cursor: pointer;
  color: $text;
}

.breadcrumb-item {
  color: #8f9bab;

  &:hover {
    cursor: pointer;
  }

  a,
  a:hover {
    color: #8f9bab;
  }
}

.profile-info_row {
  position: relative;
}

.search-filter {
  border: none;
  box-shadow: 0 0 1.2rem rgba(11, 74, 135, 0.6);
}

.search-filter:focus-visible {
  border: none;
  box-shadow: 0 0 1.2rem rgba(11, 74, 135, 0.6);
}

.inactive:hover {
  cursor: auto;
  color: $main-text;
}

.profile-order-not-available {
  color: $red;
  text-align: right;

  @media screen and (max-width: $tablet) {
    margin-top: 1rem;
  }

  @media screen and (max-width: $tablet-small) {
    text-align: left;
  }
}

.change-zip-link {
  font-size: 1.2rem;
  color: $subcategory-light;
  text-decoration: underline;
  cursor: pointer;
}

.info-row {
  &:hover {
    color: $black;
    text-decoration: underline;
    text-decoration-style: dotted;
  }

  &-tooltip {
    display: block;
    position: absolute;
    min-width: 27rem;
    max-width: 35rem;
    right: 27rem;
    background-color: $add-text;
    border-radius: 0.5rem;
    color: $white;
    font-size: 1.1rem;
    font-weight: $font-bold;
    padding: 0.5rem 0.8rem;
    z-index: 1;
    transform: translateY(10px);

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }
}

.profile-tooltip {
  right: 21rem;
}

.section-results {
  background-color: $bg;
}

label.required:after {
  content: " *";
  color: $red;
}
