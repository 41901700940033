.delivery {
  &-row {
    display: flex;
    height: calc(100% - 101px);
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: $tablet) {
      display: block;
    }

    .title {
      color: $subcategory;
      font-size: 3.2rem;
      line-height: 0.94;
      margin-bottom: 4.2rem;
      text-transform: uppercase;
    }

    &_form {
      display: flex;

      @media screen and (max-width: $hd) {
        display: block;
      }

      .dropdown {
        flex: 0 0 19.1rem;
        height: 7.3rem;
        border-radius: 0.4rem;
        margin: 0 1.2rem 2.9rem 0;
        padding: 0;
        text-align: left;

        @media screen and (max-width: $hd) {
          margin-right: 0;
        }

        &-toggle {
          border: 0.1rem solid rgba($gray-border, 0.5);
          box-shadow: 0 0 2rem rgba($shadow, 0.05);
          border-radius: 0.4rem;
          color: $add-text;
          align-items: center;
          height: 100%;
          font-size: 1.6rem;
          padding: 2.35rem 2rem 2.35rem 0;
          text-align: left;

          span {
            display: block;
            padding: 0 5.3rem 0 2.2rem;
            vertical-align: middle;

            &:after {
              background-image: url(../../images/ic-arrow-drop-w.svg);
            }
          }
        }

        &:hover {
          .dropdown-toggle {
            color: $main-text;

            span:after {
              background-image: url(../../images/ic-arrow-drop.svg);
            }
          }
        }
      }

      .arrow {
        background: url(../../images/ic-arrow_b.svg) 50% 50% no-repeat;
        background-size: 2.6rem 1.6rem;
        position: absolute;
        top: 0.1rem;
        right: 0.1rem;
        width: 6.8rem;
        height: 7rem;
      }

      .form-item {
        flex: 0 0 34.3rem;
        padding-bottom: 2.9rem;

        input {
          border: 0.1rem solid rgba($gray-border, 0.5);
          box-shadow: 0 0 2rem rgba($shadow, 0.2);
          border-radius: 0.4rem;
          font-family: $font-family-base;
          font-size: 1.6rem;
          height: 7.3rem;
          padding: 0 2.7rem;

          &:focus,
          &:active {
            border-color: $subcategory;

            & + .arrow {
              display: none;
            }
          }
        }
      }
    }

    &_pic {
      padding-top: 2.7rem;
      position: relative;

      @media screen and (max-width: $hd) {
        display: none;
      }

      &:before {
        background-color: $main-text2;
        border-radius: 0.4rem;
        content: '';
        display: block;
        width: 100%;
        height: 25.4rem;
        position: absolute;
        top: 0;
        left: 0;
      }

      img {
        width: 34.6rem;
        height: auto;
        margin: 0 auto;
        position: relative;
      }
    }

    &_left {
      flex: 0 0 65.3rem;
      width: 65.3rem;
      height: fit-content;
      padding: 4.8rem 3.8rem 0 6.5rem;

      @media screen and (max-width: $hd) {
        flex: 2 0 0;
        width: auto;
        padding: 4rem 4rem 0;
      }

      @media screen and (max-width: $smartphone-middle) {
        padding: 4rem 2rem 0;
      }
    }

    &_right {
      flex: 0 0 61.2rem;
      width: 61.2rem;
      height: fit-content;
      background-color: $main-text;
      box-shadow: -0.2rem 28rem 2.9rem 0.4rem rgba($main-text3, 0.3);
      padding: 4.8rem 6.5rem 3rem 3.9rem;

      @media screen and (max-width: $hd) {
        flex: 0 0 58.9rem;
        width: 58.9rem;
        padding: 4rem;
      }

      @media screen and (max-width: $tablet) {
        box-shadow: none;
      }

      @media screen and (max-width: $smartphone) {
        flex: 0 0 100%;
        width: 100%;
      }

      @media screen and (max-width: $smartphone-middle) {
        padding: 4rem 2rem;
      }
    }
  }

  &-place {
    &_one {
      flex: 0 0 26rem;
      height: 17.1rem;
      margin: 0 0 1.4rem;
      padding-left: 1.2rem;

      @media screen and (max-width: $smartphone) {
        flex: 0 0 50%;
      }

      @media screen and (max-width: $smartphone-small) {
        flex: 0 0 100%;
        height: 14rem;
      }

      input {
        display: none;
      }
    }

    &_label {
      border: 1px solid rgba($white, 0.5);
      box-shadow: 0 0 2rem rgba($shadow, 0.05);
      border-radius: 0.4rem;
      color: $placeholder;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      font-size: 1.4rem;
      line-height: 1.29;
      margin-bottom: 0;
      padding: 1rem 2.3rem;
      position: relative;

      .name {
        color: $white;
        font-size: 2.4rem;
        line-height: 1.292;
        margin-bottom: 1.5rem;
      }

      .arrow {
        background: url(../../images/ic-arrow_w.svg) 0 0 no-repeat;
        background-size: cover;
        display: none;
        width: 2.8rem;
        height: 1.69rem;
        position: absolute;
        top: 2.3rem;
        right: 2.3rem;
      }

      .check-ok {
        background: $white url(../../images/ic-check-mark_b.svg) 50% 50% no-repeat;
        background-size: 1.35rem 0.88rem;
        border-radius: 100%;
        display: block;
        width: 5rem;
        height: 5rem;
        position: absolute;
        top: 1.4rem;
        right: 1.2rem;
      }
    }

    &_form {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1.2rem;

      .delivery-place_one {
        input:checked + .delivery-place_label {
          background-color: $subcategory;
          border: 0.1rem solid $white;
          color: $white;

          .arrow {
            display: block;
          }
        }
      }
    }
  }
}
