.nav {
  &-tabs {
    border-bottom: none;
    flex-wrap: nowrap;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: $font-bold;

    .nav {
      &-link {
        border: none;
        border-bottom: 0.2rem solid transparent;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        height: 100%;
        padding: 0 3rem;
        text-align: center;
        position: relative;
        white-space: nowrap;

        &:before {
          background-color: $border;
          content: '';
          display: block;
          width: 0.1rem;
          height: 2.4rem;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        &:hover {
          //                    background-color: $bg-social;
        }

        &:focus,
        &:active,
        &.focus {
          //                    background-color: $bg-social;
          border-bottom-color: $subcategory !important;
          color: $subcategory !important;
        }

        &.active {
          border-bottom-color: $subcategory;
          color: $subcategory;
        }
      }

      &-item {
        height: 5.6rem;
        margin-bottom: 0;

        &:first-child {
          .nav-link {
            &:before {
              display: none;
            }
          }
        }
      }
    }

    &_static {
      &-row {
        border-bottom: 0.8rem solid $bg-blue-gray;
        margin-bottom: 3.7rem;
        position: relative;
        overflow: auto;

        @media screen and (max-width: $smartphone-middle) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.tab {
  &-content {
    text-align: left;

    > .tab-pane {
      padding: 0;

      @media screen and (max-width: $tablet) {
        &.active {
        }
      }
    }
  }

  &-pane {
    h3 {
      font-size: 1.6rem;
      margin-bottom: 2.5rem;
    }

    table {
      th {
        background-color: $gray;
        border-bottom: 0.1rem solid $border;
        border-left: 0.1rem solid $border;
        padding: 1rem;

        &:first-child {
          border-left: none;
        }

        @media screen and (max-width: $smartphone-small) {
          padding: 1rem 0.5rem;
        }
      }

      td {
        border-bottom: 0.1rem solid $border;
        border-left: 0.1rem solid $border;
        padding: 1rem;

        @media screen and (max-width: $smartphone-small) {
          padding: 1rem 0.5rem;
        }
      }

      thead {
        th {
          border-bottom-width: 0.2rem;
          border-bottom-color: $add-text;
        }
      }

      tbody tr:last-child {
        th,
        td {
          border-bottom: none;
        }
      }
    }
  }
}
