* {
  margin: 0;
  padding: 0;
  outline: none !important;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  &:focus,
  &:active {
    outline: none !important;
  }
}

html {
  font-size: $font-size-base;
  height: 100%;
}

body {
  min-height: 100%;
  background-color: $white;
  color: $main-text;
  font-family: $font-family-base;
  font-size: 1.4rem;
  font-weight: $font-regular;
  line-height: 1.25;
  position: relative;

  @media screen and (max-width: $tablet) {
    background-color: $bg;
  }
}

a,
.text-link {
  color: $text;
  transition: all 0.2s linear;

  &:hover {
    color: $subcategory;
  }
}

img {
  display: block;
}

ul,
ul li {
  list-style: none;
}

ul,
ol {
  margin: 0;
}

section {
  position: relative;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}

//
//br {
//    content: '';
//    display: block;
//    margin: 1rem 0;
//}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

h1,
.h1 {
  font-size: 2.4rem;
  font-weight: $font-bold;
  line-height: 1.167;
  margin-bottom: 3.6rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: $font-bold;
  line-height: 1.67;
  margin-bottom: 2.4rem;
  text-align: center;
}

h3 {
  font-size: 1.8rem;
  font-weight: $font-bold;
  margin-bottom: 1.6rem;
  position: relative;
  text-align: center;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

h4 {
  font-size: 1.8rem;
  font-weight: $font-bold;
}

.content {
  padding-top: 15.1rem;

  @media screen and (max-width: $tablet) {
    padding-top: 0 !important;
  }
}

.content.pay-page {
  padding-top: 0;
}

@media screen and (min-width: $smartphone-middle) {
  .container {
    max-width: none;
  }
}

@media screen and (min-width: $hd) {
  .container {
    max-width: 132rem;
  }
}
