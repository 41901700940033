.main-categories {
  background-color: $bg-blue-gray;
  padding: 2.1rem 0 4.4rem;

  .row-content {
    & > div[class*='col-'] {
      margin-bottom: 0.4rem;
      padding: 0;
    }
  }

  &_pic {
    height: 19rem;
    margin: 0 1rem 0.5rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: $smartphone) {
      margin: 0 0 1rem;
    }
  }

  &_title {
    display: block;
    font-weight: $font-bold;
    padding: 0 1rem;
    position: relative;

    @media screen and (max-width: $smartphone) {
      padding-right: 2rem;

      &:before {
        background: url(../../images/ic-arrow-plus.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 0.7rem;
        height: 1.2rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }

  &_one {
    padding: 2.5rem 0 2rem;

    @media screen and (max-width: $smartphone) {
      padding-top: 2rem;
    }
  }

  .location {
    font-weight: $font-bold;
    margin-bottom: 2rem;
  }

  &_item {
    background-color: $white;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    height: 100%;
    min-height: 11.2rem;
    margin: 0 0.2rem;
    padding: 2.4rem;
  }
}

.carousel-con {
  position: relative;
  user-select: none;

  @media screen and (max-width: $tablet) {
    padding: 0.2rem 0 0.6rem;

    .btn-buy {
      margin: 0 auto;
    }
  }

  .react-multi-carousel-item {
    .item-food {
      width: 21rem;

      @media screen and (max-width: $tablet) {
        width: 100%;
        max-width: 21rem;
      }
    }

    @media screen and (max-width: $tablet) {
      .catalog-item {
        height: 100%;
        padding: 0 0.5rem;
      }
    }
  }

  .react-multi-carousel-item.carousel-item-popup-li {
    .item-food {
      width: auto !important;
    }
  }
}

.carousel-item-li {
  .item {
    display: flex;
    flex: 1 0 auto;
    height: 100%;
  }
}

.section {
  &-main {
    padding-bottom: 3rem;

    @media screen and (max-width: $tablet) {
      display: none;
    }

    &.no-banner-crumbs {
      padding-bottom: 1.5rem;
    }
  }

  &-tabs {
    margin: 0 0 2.2rem;

    @media screen and (max-width: $l-hd - 2) {
      overflow: hidden;
      margin: 0 0 3.4rem;

      .owl-stage-outer {
        overflow: visible;
      }
    }

    .nav-tabs {
      margin-bottom: 2rem;
    }

    .tab-carousel {
      margin-left: -1rem;
      padding: 1rem;

      @media screen and (max-width: $l-hd - 2) {
        overflow: visible;
        margin-left: 0;
        padding: 0;
      }

      &_section {
        padding: 4rem 0 0;
        position: relative;

        h3 {
          display: none;
        }

        .scroll-wrapper > .scroll-content {
          overflow-y: hidden !important;
        }

        @media screen and (max-width: $smartphone) {
          padding: 1.5rem 0 0;

          .nav-tabs {
            display: none;
          }

          .tab-content > .tab-pane {
            display: block;
            margin-top: 3rem;

            &:first-child {
              margin-top: 0;
            }
          }

          h3 {
            display: block;
            font-size: 2rem;
            margin-bottom: 1rem;
            text-transform: uppercase;
          }

          .item-food {
            margin-bottom: 0;
          }
        }
      }

      &_one {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: $smartphone) {
      h3 {
        margin-top: 3rem;
      }
    }
  }

  &-also {
    border-top: 0.8rem solid $bg-blue-gray;
    padding: 1.5rem 0;
    position: relative;

    @media screen and (max-width: $tablet) {
      border-top: none;
      padding: 2.5rem 0 0;
    }

    .container {
      position: relative;

      @media screen and (max-width: $hd + 50) {
        .carousel-con {
          margin: 0 1.5rem;
        }
      }

      @media screen and (max-width: $tablet) {
        .carousel-con {
          margin: 1.5rem -1.5rem 0;
        }
      }
    }

    h2 {
      font-size: 1.8rem;
      padding: 0 10rem;
      margin-bottom: 1rem;

      @media screen and (max-width: $tablet) {
        line-height: 1.28;
        margin: 0 0.9rem 1.4rem;
        padding: 0 15rem 0 0;
        text-align: left;
      }
    }

    .btn {
      &-more {
        font-size: 1.4rem;
        position: absolute;
        top: 0.3rem;
        right: 1.5rem;

        &:hover {
          color: $text;
        }

        @media screen and (max-width: $tablet) {
          border-bottom: none;
          color: $subcategory;
          font-size: 1.2rem;
          font-weight: $font-bold;
          right: 2.4rem;
          padding-right: 2.2rem;

          &:after {
            background: url(../../images/metro/ic-arrow_b.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 1.5rem;
            height: 1rem;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }

  &-checkout {
    h1 {
      margin-bottom: 2rem;
    }

    @media screen and (max-width: $tablet) {
      background-color: $bg;
      padding-bottom: 8rem;
    }
  }

  &-faq {
    background-color: $bg-blue-gray;
    padding: 4.8rem 0 6.8rem;

    @media screen and (max-width: $tablet) {
      padding: 1.8rem 0.9rem 8rem;
    }
  }

  &-static {
    padding: 5rem 0 8rem;

    @media screen and (max-width: $tablet) {
      padding: 1.8rem 0.9rem 8rem;
    }

    h1 {
      text-align: center;
    }
  }

  &-confident {
    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.4rem;
      padding-bottom: 10rem;
    }
  }

  &-catalog {
    padding-bottom: 4.8rem;
    background-color: $bg;

    &.section-product {
      background-color: $white;
    }

    @media screen and (max-width: $tablet) {
      padding-bottom: 8rem;
    }

    .search-main {
      font-size: 1.8rem;
      padding: 4rem 0 2rem;

      @media screen and (max-width: $tablet) {
        &_pic {
          display: block;
        }
      }

      .breadcrumb {
        background: transparent;
        color: $white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;

        a,
        &-item.active {
          color: $white;
        }

        &-item + .breadcrumb-item::before {
          background-image: url(../../images/ic-angle_w.svg);
        }
      }

      .container {
        & > .row {
          flex: 2;
        }

        &_pic {
          display: block;
        }
      }

      .container {
        & > .row {
          flex: 2;
        }

        h1 {
          margin-bottom: 3rem;
        }
      }
    }

    &_main {
      padding-top: 1.4rem;
    }

    .section-seo {
      margin: 4.8rem 0 -4.8rem;
    }
  }

  &-404 {
    font-size: 3rem;
    padding: 0 1.5rem 9.7rem;
    text-align: center;

    &_pic {
      margin-bottom: 3.4rem;

      img {
        width: 100%;
        max-width: 45.3rem;
        height: auto;
        margin: 0 auto;
      }

      @media screen and (max-width: $smartphone) {
        margin-bottom: 2.4rem;

        img {
          max-width: 22.7rem;
        }
      }
    }

    &_text {
      margin-bottom: 3rem;

      p {
        font-weight: $font-medium;
        margin-bottom: 0.5rem;
      }
    }

    .btn {
      max-width: 31.8rem;
      display: inline-flex;
    }

    @media screen and (max-width: $smartphone) {
      font-size: 2rem;
      padding: 3.4rem 1.5rem 3rem;
    }
  }

  &-history {
    background-color: $white;

    @media screen and (max-width: $tablet) {
      padding-bottom: 3rem;
      padding-top: 1rem;
    }
  }

  &-seo {
    background-color: $bg-blue-gray;
    line-height: 1.55;
    padding: 6.5rem 0;

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.4rem;
      margin: 3rem 0;
    }

    .subtitle {
      font-weight: $font-bold;
      margin-bottom: 2.5rem;
    }

    ul {
      margin-bottom: 1rem;

      li {
        padding-left: 2rem;
        position: relative;

        &:before {
          background-color: $main-text;
          border-radius: 100%;
          content: '';
          display: block;
          width: 0.3rem;
          height: 0.3rem;
          position: absolute;
          top: 1.3rem;
          left: 0;
        }
      }
    }

    ol {
      margin-bottom: 1rem;
      padding-left: 1.4rem;
    }
  }

  &-register {
    border-top: 0.8rem solid $bg-blue-gray;
    padding-top: 4.8rem;

    @media screen and (max-width: $tablet) {
      background-color: $bg;
      border-top: none;
    }

    h1 {
      text-align: center;
    }
  }

  &-wishlist {
    border-top: 0.8rem solid $bg-blue-gray;
    padding: 4.8rem 0;

    @media screen and (max-width: $tablet) {
      padding: 1rem 0 8rem;

      .section-also {
        padding: 0 2.2rem;

        .catalog-item {
          padding: 0 0.5rem;
        }
      }
    }

    @media screen and (max-width: $smartphone-smallest) {
      padding: 1rem 0 8rem;

      h2 {
        margin-bottom: 1rem;
      }
    }

    .catalog-item {
      padding: 0.6rem 0.4rem;

      .item-food_wishlist {
        width: 100%;
        max-width: 22rem;
      }
    }

    .react-multi-carousel-item {
      @media screen and (max-width: $smartphone-middle) {
        .catalog-item {
          padding: 1.2rem 0.4rem;
        }
      }
    }
  }

  &-category {
    @media screen and (max-width: $smartphone-small) {
      margin: 0 -1.5rem;
    }

    &_item {
      background-color: $white;
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
      height: 100%;
      min-height: 11.2rem;
      margin: 0 0.2rem;
      padding: 2.4rem;

      @media screen and (max-width: $smartphone-small) {
        border-bottom: 0.1rem solid $border;
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        min-height: 7.2rem;
        padding: 1.6rem 6rem 1.6rem 1.8rem;
        position: relative;

        &:before {
          background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 1.2rem;
          height: 0.75rem;
          position: absolute;
          top: 50%;
          right: 3rem;
          transform: translateY(-50%) rotate(-90deg);
        }
      }

      .title {
        font-weight: $font-bold;
        line-height: 2rem;
        margin-bottom: 0.8rem;

        @media screen and (max-width: $smartphone-small) {
          margin-bottom: 0.4rem;
        }
      }

      .quantity {
        color: $add-text;
        font-size: 1.3rem;
        line-height: 1.6rem;
      }

      .list {
        line-height: 2.4rem;
        margin: 1.6rem 0 0.8rem;

        li {
          padding: 0 0 0.8rem 1.3rem;
          position: relative;

          @media screen and (max-width: 480px) {
            display: none;

            & + a {
              display: none;
            }
          }

          &:before {
            background-color: $add-text;
            border-radius: 100%;
            content: '';
            display: block;
            width: 0.4rem;
            height: 0.4rem;
            position: absolute;
            top: 1.1rem;
            left: 0;
          }
        }
      }

      .all {
        color: $subcategory;
      }
    }
  }

  &-adv {
    padding: 4rem 0 2rem;
    text-align: center;

    .adv-one {
      margin-bottom: 4rem;

      &_pic {
        display: flex;
        justify-content: center;
        width: 7rem;
        height: 7rem;
        margin: 0 auto 1rem;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      &_title {
        font-size: 3rem;
        font-weight: $font-bold;
      }
    }
  }

  &-small {
    padding: 6rem 0;
    text-align: center;

    &_content {
      font-size: 2.4rem;
      line-height: 1.58;
      max-width: 78rem;
      margin: 0 auto;

      p {
        margin-bottom: 1.5rem;
      }

      .btn {
        display: inline-flex;
        width: auto;
      }
    }
  }

  &-results {
    .catalog-item {
      margin-bottom: 0;
      padding: 0;
    }
  }

  &-product {
    @media screen and (max-width: $tablet) {
      background-color: $white;
      padding: 0 0.9rem 0;
    }

    @media screen and (max-width: $smartphone-smallest) {
      padding: 0;
    }
  }

  &-banner-mobile {
    display: block;
    margin: 0 1.5rem;

    .search-main {
      padding: 2rem 0 1rem;

      &_pic {
        position: relative;
        border-radius: 2rem;
      }
    }
  }
}
