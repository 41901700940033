.btn {
  background: $subcategory;
  border: 0.1rem solid $subcategory;
  border-radius: 0.4rem;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: $font-bold;
  height: 3.5rem;
  width: 100%;
  line-height: 1;
  padding: 0 1.5rem;
  text-align: center;

  @media screen and (max-width: $tablet) {
    border-radius: 1rem;
    font-weight: $font-semibold;
  }

  &:first-letter {
    text-transform: uppercase;
  }

  &:focus,
  &:active,
  &.focus {
    outline: none !important;
    box-shadow: none;
    text-decoration: none;
  }

  &:hover,
  &:active {
    background-color: $subcategory-dark;
    border-color: $subcategory-dark;
    color: $white;
  }

  @media (hover: hover) {
    &:hover {
      background-color: $subcategory-dark;
      border-color: $subcategory-dark;
      color: $white;
    }
  }

  &:disabled {
    background-color: $subcategory-light;
    border-color: $subcategory-light;

    @media screen and (max-width: $tablet) {
      background-color: $border;
      border-color: $border;
      color: $add-text;
    }
  }

  &-border {
    background: $white;
    border-color: $border;
    color: $subcategory;

    &_w {
      background: transparent;
      border-color: $white;
      color: $white;
    }

    &:hover,
    &:active {
      background-color: $subcategory-dark;
      border-color: $subcategory-dark;
      color: $white;
    }
  }

  &-simple {
    background: transparent;
    border-color: transparent;
    color: $subcategory;
    padding: 0;
    width: auto;

    &:hover,
    &:active {
      background: transparent;
      border-color: transparent;
      color: $main-text;
    }
  }

  &-orange-border {
    border-color: $subcategory;
    color: $subcategory;
  }

  &-green {
    background-color: $green;
    border: none;
    border-radius: 0.6rem;
    box-shadow: 0 5px 10px 0 rgba(24, 170, 19, 0.4);
    color: $btn-text;

    &:hover {
      background-color: $dark-green;
      color: $white;
    }

    &:active {
      background-color: $green;
      box-shadow: none;
    }
  }

  &-login {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.6rem;

    .loggedin {
      background-color: $bg-social;
      border-color: $bg-social;
    }
  }

  &-basket {
    background: $white;
    border-color: $border;
    color: $subcategory;
    position: relative;

    &_number {
      padding-left: 2.7rem;
      position: relative;

      &:before {
        background: url(../../images/metro/ic-basket.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.9rem;
        height: 1.9rem;
        position: absolute;
        top: -0.2rem;
        left: 0;
      }
    }

    &_items {
      background-color: $subcategory;
      border-radius: 0.4rem;
      color: $white;
      display: block;
      font-size: 1.2rem;
      line-height: 1.5rem;
      min-width: 2.4rem;
      height: 1.6rem;
      padding: 0 0.3rem;
      text-align: center;
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
    }

    @media screen and (max-width: $smartphone) {
      width: 4.8rem;
      padding: 0;

      &_number {
        overflow: hidden;
        white-space: nowrap;
        text-indent: -25rem;

        &:before {
          left: 0.3rem;
        }
      }
    }

    &:hover {
      .btn-basket_number {
        &:before {
          background-image: url(../../images/ic-basket_w.svg);
        }
      }
    }
  }

  &-map,
  &-copy,
  &-send {
    background-color: $white;
    border-left: 0.1rem solid $gray-border;
    width: 4.7rem;
    height: 4rem;
    position: relative;

    &:before {
      background: url(../../images/ic-map.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.33rem;
      height: 1.6rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $smartphone) {
      width: 3.4rem;
      height: 3rem;
    }
  }

  &-show-password {
    background: url(../../images/ic-eye.svg) 0 0 no-repeat;
    display: block;
    width: 1.9rem;
    height: 1.3rem;
    position: absolute;
    top: 1.2rem;
    right: 1.8rem;

    &:hover {
      cursor: pointer;
    }
  }

  &-hide-password {
    background: url(../../images/ic-eye_hide.svg) 0 0 no-repeat;
    display: block;
    width: 1.9rem;
    height: 1.7rem;
    position: absolute;
    top: 0.8rem;
    right: 1.8rem;

    &:hover {
      cursor: pointer;
    }
  }

  &-copy {
    &:before {
      background: url(../../images/ic-copy.svg) 0 0 no-repeat;
      width: 1.75rem;
      height: 1.75rem;
    }
  }

  &-send {
    &:before {
      background-image: url(../../images/ic-send.svg);
      width: 1.75rem;
      height: 1.75rem;
    }

    &:disabled {
      &:before {
        background-image: url(../../images/ic-send-disabled.svg);
      }
    }
  }

  &-next,
  &-prev {
    background-color: $white;
    border: 0.1rem solid $gray-border;
    border-radius: 100%;
    box-shadow: none;
    width: 5rem;
    height: 5rem;
    position: relative;
    transition: all 0.2s linear;

    &:before {
      background: url(../../images/ic-arrow.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.6rem;
      height: 1rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background-color: $subcategory-dark;
      box-shadow: 0 0.25rem 0.5rem 0 rgba(3, 129, 178, 0.3);

      &:hover {
        &:before {
          background-image: url(../../images/ic-arrow_w.svg);
        }
      }
    }
  }

  &-prev {
    &:before {
      transform: translate(-50%, -50%) rotate(-180deg);
    }
  }

  &-edit,
  &-save {
    background-color: $subcategory-dark;
    display: block;
    width: 4rem;
    height: 4rem;
    position: relative;
    text-indent: -10rem;
    overflow: hidden;

    &:before {
      background: url(../../images/ic-edit.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.3rem;
      height: 1.5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $smartphone) {
      width: 3rem;
      height: 3rem;
    }
  }

  &-save {
    &:before {
      background: url(../../images/ic-check-mark_w.svg) 0 0 no-repeat;
      width: 1.4rem;
      height: 0.95rem;
    }
  }

  &-delete {
    background-color: $white;
    border: 0.1rem solid $gray-border;
    display: block;
    width: 4rem;
    height: 4rem;
    position: relative;
    text-indent: -10rem;
    overflow: hidden;

    &:before {
      background: url(../../images/ic-close.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.3rem;
      height: 1.3rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $smartphone) {
      width: 3rem;
      height: 3rem;
    }
  }

  &-attach {
    color: $subcategory;
    padding-left: 3.7rem;
    position: relative;

    &:before {
      background: url(../../images/ic-attach.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 2.4rem;
      height: 2.45rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      text-decoration: underline;
    }
  }

  &-buy {
    padding: 0 1rem;
    text-align: left;

    .icon-basket {
      flex: 0 0 2.1rem;
      width: 2.1rem;
      margin-right: 1.1rem;
    }

    @media screen and (max-width: $smartphone-middle) {
      width: 4.8rem;
      padding: 0;

      span {
        display: none;
      }

      .icon-basket {
        margin-right: 0;
      }
    }
  }

  &-logout {
    background: url(../../images/ic-logout.svg) 0 50% no-repeat;
    background-size: 2rem 1.8rem;
    color: $text;
    font-weight: $font-bold;
    padding-left: 3.2rem;

    &:hover {
      background: url(../../images/ic-logout_o.svg) 0 50% no-repeat;
      color: $subcategory;
    }
  }

  &-wish {
    background: transparent;
    border: none;

    &:hover {
      background: transparent url(../../images/ic-heart_o.svg) 50% 50% no-repeat;
      background-size: 2rem 1.9rem;

      img {
        display: none;
      }
    }

    @media screen and (max-width: $tablet) {
      &:hover {
        background: none;

        img {
          display: block;
        }
      }
    }
  }

  &-more {
    border-bottom: 0.1rem dashed $text;
    transition: all 0.2s linear;

    &:hover {
      border-bottom-color: $subcategory;
      color: $subcategory;
    }
  }

  &-red {
    background-color: $subcategory;
    border-color: $subcategory;

    &:hover,
    &:active {
      background-color: darken($subcategory, 5%);
      border-color: darken($subcategory, 5%);
    }
  }

  &-small {
    width: auto;
    height: 3.2rem;

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.4rem;
    }
  }
}

.link {
  &-orange {
    color: $subcategory;
  }

  &-dashed {
    border-bottom: 0.1rem dashed $text;
  }

  &-enter {
    display: inline-block;
    font-weight: $font-bold;
    margin-left: 2.5rem;
    padding-left: 2.6rem;
    position: relative;
    transition: none;

    &:before {
      background: url(../../images/ic-login.svg) 0 0 no-repeat;
      background-size: cover;
      content: '';
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      top: 0.2rem;
      left: 0;
    }

    &:hover {
      &:before {
        background-image: url(../../images/ic-login_o.svg);
      }
    }
  }
}

.register {
  &-facebook {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    padding: 1rem;
    width: 100%;
    text-align: center;

    span[class*='icon-'] {
      margin-right: 2rem;
    }

    span[class*='font-'] {
      margin-left: 0.5rem;
    }
  }
}
