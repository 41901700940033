.time {
  background: url(../../images/ic-time.svg) 0 50% no-repeat;
  background-size: 1.8rem 2rem;
  color: $add-text;
  font-size: 1.3rem;
  height: 2rem;
  line-height: 2rem;
  padding-left: 2.5rem;

  @media screen and (max-width: $tablet) {
    background-size: 1.8rem 2rem;
    padding-left: 2.5rem;
  }
}

.recipe-text {
  font-size: 1.4rem;
  height: 10.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.see-more {
  margin-bottom: 0;
  padding-top: 4rem;
  text-align: center;

  a {
    max-width: 30rem;
    margin: 0 auto;
  }
}

.mobile-categories_slider {
  overflow: hidden;
  padding-top: 3rem;
  position: relative;

  h2 {
    font-size: 2.2rem;
    padding: 0 10rem 0 1.5rem;
    position: relative;
    text-align: left;
  }

  .react-multi-carousel-list {
    overflow: visible;
  }

  &-one {
    background-color: #e6f4f5;
    border-radius: 0.8rem;
    margin: 0 1rem;
    padding: 1rem;
    overflow: hidden;
    text-align: center;

    .title {
      display: block;
      font-size: 1.8rem;
      font-weight: $font-bold;
      height: 5.5rem;
    }

    .pic {
      width: 100%;
      height: 14.3rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &-prev,
  &-next {
    background: url(../../images/ic-arrow-drop.svg) 0 0 no-repeat;
    background-size: cover;
    cursor: pointer;
    width: 1.55rem;
    height: 0.8rem;
    position: absolute;
    top: -4.6rem;
    right: 1.5rem;
    transform: rotate(-90deg);
  }

  &-prev {
    transform: rotate(-270deg);
    right: 4.5rem;
  }
}

.react-multi-carousel-list {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
