.shipment-tax {
  font-size: 1.2rem;
  margin: -1rem 0 2rem;

  .shipping-cost-info {
    color: $subcategory;
    cursor: pointer;
  }

  .percent-item {
    margin-right: 0.2rem;
  }
}

.product {
  &-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 2.6rem 0.5rem 0;
    width: 100%;
    height: 100%;
    max-height: 50rem;
    position: relative;
    z-index: 5;

    .item-status {
      font-size: 1.8rem;
      top: 2rem;
      z-index: 5;
    }

    .organic {
      &-items {
        position: absolute;
        top: 0;
        left: -0.5rem;
        display: flex;
        flex-wrap: wrap;
        z-index: 5;

        li {
          display: flex;
          align-items: flex-start;
          margin-left: 0.5rem;
          width: 5rem;
          height: 5rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    &.item-sale {
      .organic-items {
        max-width: 28rem;

        @media screen and (max-width: $tablet) {
          max-width: none;
          margin-right: 6.5rem;
        }

        @media screen and (max-width: $tablet-small) {
          margin-right: 7.5rem;
        }

        @media screen and (max-width: $smartphone-middle) {
          margin-right: 6rem;
        }
      }

      &_big {
        .organic-items {
          margin-right: 9rem;

          @media screen and (max-width: $tablet) {
            margin-right: 7rem;
          }

          @media screen and (max-width: $smartphone-middle) {
            margin-right: 5.5rem;
          }
        }
      }
    }

    &_zoom {
      width: 100%;
    }

    #zoom-picture {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 36rem;

      & > div {
        width: 30rem !important;
        height: 30rem !important;

        & > img {
          width: 100% !important;
          height: 100% !important;
          object-fit: contain;
        }
      }
    }

    &-may-differ {
      text-align: center;
      font-size: 1.1rem;

      @media screen and (max-width: $tablet) {
        margin-top: 10px;
      }
    }

    &_pic {
      &-more {
        padding-top: 15px;
        display: flex;

        li {
          flex: 0 0 8rem;
          width: 8rem;
          height: 8rem;
          margin: 0 1rem;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &_scroll {
          overflow-x: auto;
        }
      }
    }

    @media screen and (max-width: $hd) {
      margin-bottom: 1rem;
      padding-right: 0;

      .item-status {
        right: 0;
      }
    }

    @media screen and (max-width: $tablet) {
      display: block;
      height: 30rem;
      padding: 0;
      margin-bottom: 3rem;

      &_slider {
        height: 100%;

        .react-multi-carousel-list {
          height: 100%;
        }

        .react-multi-carousel-track {
          height: 26.3rem;
        }

        li {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .btn-wish {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 4.8rem;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    @media screen and (max-width: $tablet-small) {
      margin-bottom: 25px;
    }

    @media screen and ((max-width: $smartphone-middle) or (max-width: $smartphone-small) or (max-width: $smartphone) or (max-width: $tablet-small)) {
      .item-status {
        font-size: 1.4rem;
        padding-top: 0.1rem;
        padding-bottom: 0 !important;

        .sale-date {
          font-size: 1.1rem;
        }
      }
    }
  }

  &-card {
    padding-top: 2rem;
    position: relative;

    h1 {
      margin-bottom: 0.8rem;
      padding-right: 5rem;
      text-align: left;

      @media screen and (max-width: $tablet) {
        font-size: 1.8rem;
      }
    }

    .btn-wish {
      position: absolute;
      top: 1.1rem;
      right: 0;
      width: 4.8rem;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &-content {
    font-size: 1.4rem;
    margin-bottom: 4.2rem;

    @media screen and (max-width: $tablet) {
      margin-bottom: 1.5rem;

      hr {
        background-color: #d5dde0 !important;
        border-color: #d5dde0 !important;
      }
    }

    &_info {
      margin-bottom: 2rem;

      li {
        margin-top: 0.5rem;

        @media screen and (max-width: $tablet-small) {
          &:first-child {
            margin-top: 0;
          }
        }

        &.flex {
          display: flex;

          @media screen and (max-width: $tablet) {
            display: block;
          }
        }
      }

      .caption {
        color: $add-text;
        display: inline-block;
        margin: 0 3rem 0 0;

        @media screen and (max-width: $tablet) {
          margin: 0 0 1.5rem;
        }
      }

      .text {
        flex: 2;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1.2rem !important;
        }
      }
    }

    &_weight {
      font-weight: $font-medium;
      margin-bottom: 1.5rem;
    }

    &_quantity {
      color: $main-text;
      flex: 1 0 0;

      .caption {
        font-family: $font-family-second;
        margin-bottom: 1.9rem;
      }
    }

    &_add {
      font-size: 1.8rem;
      margin-bottom: 4rem;

      p {
        font-weight: $font-medium;
        font-size: 2rem;
      }

      @media screen and (max-width: $tablet-small) {
        margin-bottom: 1.7rem;
      }
    }

    &_shop {
      .product-content_quantity {
        flex: 0 0 50%;
        width: 50%;
        padding-left: 1.5rem;

        .btn {
          text-transform: uppercase;
        }

        .weight-choose_form {
          display: none;
        }

        @media screen and (max-width: $hd) {
          padding: 0 1.5rem 0 0;
        }

        @media screen and (max-width: $tablet) {
          flex: 0 0 100%;
          width: 100%;
          padding: 0;
        }

        @media screen and (max-width: $tablet-small) {
          padding-left: 0;

          .weight-choose_form {
            display: flex;
            flex: 0 0 11.6rem;
            width: 11.6rem;
            margin-right: 0.9rem;

            input {
              font-size: 1.6rem;
              padding: 0;
            }

            .prev,
            .next {
              flex: 0 0 2.8rem;
              width: 2.8rem;
            }
          }
        }
      }

      @media screen and (max-width: $tablet-small) {
        position: relative;

        .product-weight {
          margin-bottom: 6.5rem;
        }

        .weight-choose {
          display: none;
        }
      }
    }

    &_switch {
      padding-top: 2.5rem;

      .form-switch {
        margin-bottom: 1.9rem;
      }

      .product-weight .weight-choose {
        flex: 0 0 31.7rem;

        &_form {
          width: 23.2rem;
        }

        @media screen and (max-width: $smartphone) {
          flex: 0 0 20rem;

          &_form {
            width: 15rem;
          }
        }
      }

      @media screen and (max-width: $tablet-small) {
        .form-switch {
          border: 0.1rem solid $border;
          border-radius: 0.2rem;
          padding: 0.9rem 0;
          justify-content: center;
          margin-bottom: 1.9rem;
        }

        .product-switch_weight {
          .product-total {
            display: flex;

            .product-content_quantity {
              .quantity-form_inside {
                display: none;
              }
            }
          }
        }
      }

      @media screen and (max-width: $smartphone-small) {
        .product-switch_weight {
          .product-total {
            display: block;
          }
        }
      }
    }

    &_block {
      .item-food_price {
        margin: 0.5rem 0 2rem;
        padding: 0;
      }
    }
  }

  &-weight {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    padding-top: 0.9rem;

    .weight-choose {
      flex: 0 0 20rem;
      width: 20rem;

      .caption {
        color: $add-text;
      }
    }

    &.productTimeToPrepare {
      margin-bottom: 1.5rem;

      .weight-choose {
        flex: 2;
        display: flex;

        .caption {
          margin: 0 0.5rem 0 0;
        }
      }
    }

    .slider {
      flex-grow: 2;
    }

    @media screen and (max-width: $tablet) {
      display: block;

      .weight-choose {
        margin-bottom: 2rem;
      }
    }
  }

  &-price {
    .price {
      font-weight: $font-bold;
      font-size: 3rem;

      @media screen and (max-width: $tablet) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-size: 2.4rem;

        .item-food_price {
          .packaging {
            display: flex;
            justify-content: flex-end;

            .number-unit.sale {
              display: block;
            }
          }
        }
      }

      @media screen and (max-width: $smartphone-small) {
        display: block;

        .item-food_price {
          .packaging {
            justify-content: flex-start;
          }
        }
      }
    }

    &_sale {
      .number {
        color: $add-text;
        display: inline-block;
        font-size: 2.4rem;
        font-weight: $font-regular;
        position: relative;
        text-decoration: line-through $red;

        @media screen and (max-width: $tablet) {
          font-size: 1.8rem;
          font-weight: $font-semibold;

          &-sale {
            margin: 0 1rem;
          }
        }

        &-sale {
          color: $red;
        }
      }

      &-date {
        color: $red;
        display: block;
        font-size: 1.8rem;
        font-weight: $font-regular;
        margin: 2rem 0 -1rem;
      }

      @media screen and (max-width: $tablet) {
        flex: 0 0 50%;
        width: 50%;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }

    &_table {
      width: 100%;
      margin-bottom: 2rem;

      @media screen and (max-width: $tablet) {
        font-size: 1.3rem;
      }

      th {
        background-color: $gray-f2;
        color: $add-text;
        padding: 0.5rem 0.8rem 0.3rem;

        &:last-child {
          text-align: right;
        }
      }

      td {
        border-top: 0.1rem solid $border;
        padding: 0.5rem 0.8rem;

        &.percent {
          width: 8rem;

          span.percent-value {
            background-color: #fdd6de;
            border-radius: 0.4rem;
            color: $red;
            display: block;
            font-weight: $font-bold;
            padding: 0.25rem 1rem;
            text-align: center;
          }
        }

        &:first-child {
          width: 50%;

          @media screen and (max-width: $smartphone-smallest) {
            width: 48%;
          }
        }

        &:last-child {
          text-align: right;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      margin-bottom: 1.6rem;

      &_number {
        display: flex;
        font-size: 1.6rem;
        font-weight: $font-regular;
        line-height: 3rem;
        text-align: right;

        .old {
          color: $add-text;
          text-decoration: line-through;
          margin-right: 0.5rem;
        }
      }
    }

    & + .item-food_price.sale + ul {
      .price {
        font-size: 3.8rem;
        font-weight: $font-bold;
      }
    }
  }

  &-schedule {
    font-size: 1.4rem;
    padding-top: 2rem;

    .caption {
      color: $add-text;
      margin-bottom: 0.8rem;

      @media screen and (max-width: $smartphone) {
        margin-bottom: 0.4rem;
      }
    }

    @media screen and (max-width: $tablet-small) {
      border-top: 0.1rem solid $border;
      padding-top: 1.6rem;
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.2rem;
    }
  }

  &-total {
    border-bottom: 0.1rem solid $border;
    padding-bottom: 2.7rem;

    &_finish {
      align-items: center;

      .caption {
        color: $shadow;
        padding-right: 4.8rem;
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
      }
    }

    @media screen and (max-width: $hd) {
      display: block;

      .product-price {
        margin-bottom: 1.5rem;
      }
    }

    @media screen and (max-width: $tablet-small) {
      border-bottom: none;
      padding-bottom: 2rem;
    }
  }

  &-switch {
    &_quantity {
      display: none;
      padding-top: 1.5rem;
    }
  }

  &-reviews {
    padding-top: 1.5rem;
    text-align: center;

    @media screen and (max-width: $tablet) {
      border-top: 0.1rem solid #e9e9e9;
      font-size: 1.2rem;
      margin-top: 2.3rem;
      padding-top: 1.6rem;
      text-align: left;

      hr {
        background-color: #d5dde0 !important;
        border-color: #d5dde0 !important;
      }
    }

    .nav {
      &-tabs {
        background-color: $border;
        border-bottom: none;
        border-radius: 0.4rem;
        display: inline-block;
        height: 4.8rem;
        margin-bottom: 1.5rem;
        padding: 0.4rem 0.6rem;

        @media screen and (max-width: $tablet) {
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          height: 100%;
          position: relative;
        }
      }

      &-item {
        display: inline-block;
        height: 100%;
        margin: 0 -0.1rem;

        @media screen and (max-width: $tablet-small) {
          display: flex;
          width: 100%;
        }

        @media screen and (max-width: $smartphone-small) {
          width: 50%;
        }
      }

      &-link {
        background: transparent;
        border: none;
        border-radius: 0.2rem;
        color: $text;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        padding: 0 2.5rem;
        text-align: center;

        @media screen and (max-width: $tablet-small) {
          flex: 1 1 100%;
          padding: 0 1.5rem;
          white-space: break-spaces;
        }

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1.2rem;
          padding: 0 1rem;
        }

        &:before {
          display: none;
        }

        &.active {
          color: $text;
        }
      }
    }

    &_top {
      display: flex;
      font-size: 1.3rem;
      line-height: 1.6rem;
      margin-bottom: 2rem;

      ul {
        margin: 0 1.5rem 0 0;
      }
    }

    .section-seo {
      background-color: transparent;
      padding: 0 0 0.18rem;

      @media screen and (max-width: $tablet) {
        margin-top: 0;
      }

      p {
        margin-bottom: 2.6rem;

        @media screen and (max-width: $tablet) {
          margin-bottom: 1rem;
        }
      }
    }

    &_author {
      font-weight: $font-bold;
      margin-bottom: 0.8rem;
    }

    &_date {
      color: $add-text;
      font-size: 1.3rem;
    }

    &_rating {
      display: flex;
      margin-bottom: 0.9rem;

      li {
        padding-right: 0.14rem;
      }

      img {
        width: 1.4rem;
        height: auto;
      }
    }

    &_text {
      max-width: 118rem;

      .pic-attach_one {
        display: inline-block;
        width: 4.8rem;
        height: 4.8rem;
        margin-left: 1rem;
        overflow: hidden;
        vertical-align: middle;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &_one {
      background-color: $white;
      box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
      border-radius: 0.8rem;
      line-height: 2.4rem;
      max-width: 80rem;
      margin: 0 auto 2.4rem;
      padding: 2.4rem;
    }

    &_answer {
      background-color: $bg-blue-gray;
      border-radius: 0.2rem;
      border-left: 0.2rem solid $subcategory;
      margin-top: 1.2rem;
      padding: 1rem 1.8rem 1.6rem;

      .product-reviews_author {
        margin-bottom: 0;
      }
    }

    &_bottom {
      margin: 2rem auto 2rem;
      scroll-margin-top: 15rem;

      h3 {
        text-align: left;
        text-transform: none;
      }

      &_head {
        display: table;
        width: 100%;

        div {
          display: table-cell;
          @media screen and ((max-width: $tablet-small) or (max-width: $tablet-middle) or (max-width: $smartphone) or (max-width: $tablet)) {
            &.product-reviews_bottom_head_button {
              max-width: 6rem;
            }
          }
        }

        &_button {
          text-align: right;
          vertical-align: top;
        }

        &_rate, &_rate_title {
          font-weight: $font-regular;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          vertical-align: top;
        }

        &_rate_title {
          font-weight: $font-light;
          @media screen and ((max-width: $tablet-small) or (max-width: $tablet-middle) or (max-width: $smartphone) or (max-width: $tablet)) {
            display: inline-block;
            max-width: 10rem;
          }
        }
      }

      &_body {
        display: table;
        width: 100%;
        margin-bottom: 0.5rem;

        div {
          display: table-cell;
        }

        &_stars {
          width: 9rem;
          padding-right: 0.5rem;
          border-right: solid 1px $border;
        }

        &_text {
          padding-left: 1rem;

          .description {
            padding-right: 0.5rem;
            font-size: $font-size-base;

            .last-name-title {
              margin-left: 0.2rem;
            }

            .label-title {
              font-weight: bold;
            }

            .label-value {
              margin-left: 0.3rem;
            }
          }

          .reply-box {
            .reply-text {
              display: table;

              span {
                display: table-cell;
                padding-right: 0.5rem;
              }
            }

            .reply-author {
              font-size: $font-size-base;
              padding-left: 2.3rem;
            }
          }
        }
      }

      .name-container {
        margin: 1rem 0;

        &_anonym-title {
          font-style: italic;
        }

        &_anonym-message {
          font-size: 1.2rem;
          color: $subcategory;
          padding-left: 1rem;
          cursor: pointer;
        }
      }

      @media screen and (max-width: $tablet) {
        margin: 0;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.2rem;

        .link-enter {
          padding-left: 2rem;

          &:before {
            width: 1.4rem;
            height: 1.4rem;
            top: 0.1rem;
          }
        }
      }

      .title {
        font-weight: $font-bold;
        font-size: 1.6rem;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1.4rem;
        }
      }
    }

    &_form {
      &-rating {
        align-items: center;
        padding-bottom: 2.5rem;

        .caption {
          color: $add-text;
          font-size: 1.4rem;
          margin-right: 2.2rem;
        }

        .product-reviews_rating {
          margin-bottom: 0;

          img {
            width: 2.2rem;
            height: auto;
          }
        }
      }

      textarea {
        height: 8rem;

        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          color: $placeholder;
        }
      }

      .btn {
        max-width: 31.8rem;
        text-transform: uppercase;
      }

      &-bottom {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        align-items: center;

        @media screen and (max-width: $smartphone) {
          display: block;
        }

        .pic-attach {
          display: flex;
          align-items: center;

          @media screen and (max-width: $smartphone) {
            margin-bottom: 2rem;
          }

          &_one {
            margin-right: 2.5rem;
            position: relative;

            .pic {
              width: 4.8rem;
              height: 4.8rem;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .delete {
              background: $main-text url(../../images/ic-close_w.svg) 50% 50% no-repeat;
              background-size: 0.8rem 0.8rem;
              border-radius: 100%;
              cursor: pointer;
              display: block;
              width: 2rem;
              height: 2rem;
              position: absolute;
              top: -0.6rem;
              right: -0.6rem;
            }
          }
        }
      }
    }

    &_info-block {
      background-color: #fffbe6;
      border: 1px solid #ffe58f;
      color: #faad14;
      font-size: 15px;
      font-weight: $font-medium;
      padding: 1.5rem 1rem;
    }

    table tr {
      td,
      td p,
      td span,
      td a {
        font-family: $font-family-base !important;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1.2rem !important;
        }
      }
    }

    &_article {
      .subtitle {
        font-size: 1.7rem;
        font-weight: $font-medium;
      }
    }

    &_additional_data {
      padding-left: 3px;
      font-size: 1.2rem;
      vertical-align: top;
    }

    &_header_product_title {
      color: #818181;
      margin: 0 0.5rem;
    }

    .tab-content .tab-pane {
      display: none;

      &.active {
        display: block;
      }

      .nutritional-table, .characteristics-table {
        width: 100%;
      }

      .tab-content-title {
        font-size: 2.1rem;
        margin-bottom: 1.5rem;

        span {
          color: #818181;
        }
      }
    }
  }

  &-attention {
    background: rgba($red-light, 0.04);
    border-radius: 0.2rem;
    color: $red;
    font-size: 1.4rem;
    line-height: 1.3;
    margin-bottom: 4.5rem;
    padding: 2.2rem 2.5rem;

    .title {
      font-weight: $font-medium;
      font-size: 2rem;
      line-height: 1.3;
    }
  }

  &-subname {
    font-size: 2rem;

    span {
      font-weight: $font-bold;
    }
  }

  &-page {
    padding-bottom: 8rem;

    @media screen and (max-width: $tablet) {
      background-color: $white;
    }
  }

  &-modal {
    background-color: $white;
    border-radius: 0.8rem;
    max-width: 94rem;
    margin: 0 auto;
    padding: 4rem;

    @media screen and (max-width: $tablet) {
      border-radius: 2rem 2rem 0 0;
      width: 100%;
      max-width: none;
      top: 3rem;
      right: auto;
      left: 0;
      bottom: 0;
      overflow: hidden;
      padding: 3.9rem 0 6.8rem;

      .top-close {
        background: url(../../images/ic-arrow.svg) 50% 50% no-repeat;
        background-size: 1.7rem 1.1rem;
        border-radius: 2rem 2rem 0 0;
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 3.9rem;
        opacity: 0.3;
        z-index: 5;
      }

      &_scroll {
        height: 100%;
        overflow: auto;
        padding: 0 2.4rem;
      }

      .product {
        &-pic {
          .btn-wish {
            position: absolute;
            width: 4.8rem;
            height: 4.8rem;
            bottom: 0;
            right: 0;
          }
        }

        &-short {
          font-size: 1.2rem;
        }

        &-card {
          h1 {
            font-size: 1.8rem;
          }
        }

        &-price {
          .number {
            font-size: 1.8rem;
            font-weight: $font-semibold;

            &.normal {
              color: #919ba9;
              font-weight: $font-regular;
            }

            &:before {
              background-color: #919ba9;
            }
          }

          .price {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            color: $subcategory;
            font-size: 2.4rem;

            .product-price {
              &_sale,
              &_number {
                flex: 0 0 50%;
                width: 50%;
                display: flex;
                align-items: center;
                white-space: nowrap;
              }

              &_sale {
                .number-sale {
                  margin: 0 1rem;
                }
              }

              &_number {
                color: $main-text;
                justify-content: flex-end;
                font-size: 1.6rem;
                font-weight: $font-regular;
                line-height: 3rem;
                text-align: right;

                .old {
                  display: none;
                }
              }
            }

            .item-food_price {
              align-self: center;
            }
          }
        }

        &-button {
          display: flex;
          align-items: center;
          background-color: $white;
          box-shadow: 0 0 1.3rem rgba($black, 0.15);
          border-radius: 0.5rem 0.5rem 0 0;
          padding: 1rem 2.2rem;
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          z-index: 5;

          .buttons {
            flex: 2;
            display: flex;
            padding-right: 2.2rem;

            .btn-close_mobile {
              background: url(../../images/ic-close_b.svg) 50% 50% no-repeat;
              background-size: 1.2rem 1.2rem;
              border: none;
              flex: 0 0 4.8rem;
              width: 4.8rem;
              margin: 0 0.6rem 0 -1.6rem;
            }

            .quantity-form_inside {
              border: 0.1rem solid $subcategory;
              border-radius: 0.8rem;
              margin-right: 0;
              padding: 0.8rem 0.6rem;
              width: auto;
              flex: 2;

              input {
                flex: 2;
                background-color: transparent;
                border: none;
                color: $subcategory;
                height: 3.2rem;
              }

              .btn {
                &-minus,
                &-plus {
                  border: none;
                  flex: 0 0 4.8rem;
                  width: 4.8rem;
                  height: 3.2rem;
                  line-height: 3.2rem;
                }

                &-minus {
                  color: #919ba9;
                }

                &-plus {
                  background-color: $subcategory;
                  border-radius: 0.8rem;
                  color: $white;
                }
              }
            }

            &-div {
              flex: 2;
              display: flex;
            }
          }
        }

        &-reviews {
          border-top: none;
          font-size: 1.4rem;
          margin-top: 2.3rem;
          padding: 0;
          text-align: left;

          &_article {
            margin-bottom: 2.5rem;
          }

          .nav-tabs {
            flex-wrap: nowrap;
          }

          .subtitle {
            font-size: 1.7rem;
            font-weight: $font-medium;
          }
        }
      }
    }

    @media screen and (max-width: $smartphone-middle) {
      .product {
        &-price {
          .price {
            .product-price {
              &_number {
                .old {
                  margin: 0 0.5rem 0 0;
                }
              }
            }
          }
        }

        &-button {
          .buttons {
            padding-right: 1.5rem;

            .quantity-form_inside {
              input {
                font-size: 1.2rem;
              }
            }
          }
        }

        &-reviews {
          font-size: 1.2rem;
        }
      }
    }

    @media screen and (max-width: $smartphone-small) {
      .product-price {
        .price {
          display: block;

          .item-food_price.sale {
            margin-top: 0.3rem;
          }
        }
      }
    }
  }

  &-carousel-title {
    margin-top: 2em;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
    position: relative;
  }

  @media screen and (max-width: $tablet) {
    &-short {
      font-size: 1.2rem;
    }
  }
}

.quantity-form {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: $tablet) {
    display: block;
  }

  &_inside {
    display: flex;
    flex: 0 0 15rem;
    width: 15rem;
    margin-right: 3.2rem;

    input {
      width: 4.4rem;
      background-color: $subcategory;
      border: 0.1rem solid $subcategory;
      color: $white;
      font-weight: $font-medium;
      padding: 0;
      text-align: center;

      &:focus,
      &:active {
        background-color: $white !important;
        box-shadow: none;
        color: $main-text;
        outline: none !important;
      }

      &:-internal-autofill-selected {
        background-color: $white !important;
        color: $main-text !important;
      }
    }

    .btn-minus,
    .btn-plus {
      background-color: $white;
      border: 0.1rem solid $border;
      border-radius: 0.4rem 0 0 0.4rem;
      color: $subcategory;
      flex: 0 0 3.6rem;
      width: 3.6rem;
      font-size: 2.8rem;
    }

    .btn-plus {
      border-radius: 0 0.4rem 0.4rem 0;
    }

    @media screen and (max-width: $smartphone) {
      margin-right: 0.9rem;
    }
  }

  .btn {
    max-width: none;

    @media screen and (max-width: $tablet) {
      margin-top: 1.5rem;
    }
  }
}

.weight-choose_form {
  display: flex;
  width: 15rem;

  input {
    background-color: $subcategory;
    border: 0.1rem solid $subcategory;
    color: $white;
    text-align: center;

    &:focus,
    &:active {
      background-color: $white !important;
      box-shadow: none;
      color: $main-text;
      outline: none !important;
    }

    &:-internal-autofill-selected {
      background-color: $white !important;
      color: $text !important;
    }
  }

  .prev,
  .next {
    background-color: $white;
    border: 0.1rem solid $border;
    border-radius: 0 0.4rem 0.4rem 0;
    color: $subcategory;
    flex: 0 0 3.1rem;
    width: 3.1rem;
    position: relative;

    &:before {
      background: url(../../images/ic-arrow-plus.svg) 50% 50% no-repeat;
      background-size: 0.7rem 1.2rem;
      content: '';
      display: block;
      width: 0.7rem;
      height: 1.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .prev {
    border-radius: 0.4rem 0 0 0.4rem;

    &:before {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}
