.checkout {
  &-top {
    border-right: 0.8rem solid $bg-blue-gray;
    padding: 4.8rem 4rem 3rem 1.5rem;

    @media screen and (max-width: $tablet) {
      background-color: $bg;
      border-right: none;
      padding: 0 1.5rem;
      position: static;

      h1 {
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 6rem;
      }
    }

    .btn {
      &-prev,
      &-next {
        position: absolute;
        bottom: 5.6rem;
      }

      &-prev {
        left: 15px;
      }

      &-next {
        right: 15px;
      }
    }

    @media screen and (max-width: $tablet-small) {
      margin-bottom: 2rem;
    }
  }

  &-steps {
    margin: 0 0 2rem;

    @media screen and (max-width: $tablet) {
      display: none;

      &_mobile {
        display: block;
        color: #919ba9;
        font-size: 1.4rem;
        font-weight: $font-semibold;
        position: absolute;
        top: 50%;
        right: 2.3rem;
        transform: translateY(-50%);
      }
    }

    a {
      border: 0.1rem solid $border;
      border-radius: 0.4rem;
      color: $add-text;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      position: relative;
    }

    li {
      display: inline-block;
      padding: 0 3.3rem;
      position: relative;

      &:before {
        background-color: $border;
        content: '';
        display: block;
        width: 3.2rem;
        height: 0.1rem;
        position: absolute;
        top: 50%;
        left: -1.7rem;
        transform: translateY(-50%);
      }

      &:first-child {
        padding-left: 0;

        &:before {
          display: none;
        }
      }

      &:last-child {
        padding-right: 0;
      }

      &.active {
        &:before {
          background-color: $subcategory;
        }

        a {
          background-color: $text;
          border-color: $text;
          color: $white;

          &:before {
            background-color: $subcategory;
          }
        }

        &.done {
          a {
            &:before {
              background: $subcategory url(../../images/ic-check-mark_w.svg) 50% 50% no-repeat;
              background-size: 1.4rem 0.9rem;
              border-radius: 0.35rem;
              content: '';
              display: block;
              width: 4rem;
              height: 4rem;
              position: absolute;
              top: -0.1rem;
              left: -0.1rem;
            }
          }
        }
      }
    }
  }

  &-block {
    margin-bottom: 1rem;
    padding: 3.5rem 5rem;
    position: relative;
    z-index: 2;

    &_title {
      font-weight: $font-medium;
      font-size: 2rem;
      margin-bottom: 2.5rem;
    }

    &_date {
      display: flex;
      margin-left: -3rem;

      input {
        max-width: 30rem;
        margin-left: 3rem;
      }
    }

    &_note {
      color: $add-text;
      margin: 0;
      max-width: 66rem;
    }
  }

  &-delivery {
    padding: 0;

    @media screen and (max-width: $smartphone) {
      margin-bottom: 2rem;
    }

    &_block {
      color: $text;
      margin-bottom: 1rem;
      padding: 0 5rem;
    }

    &_label {
      background-color: $white;
      cursor: pointer;
      display: block;
      margin: 0 -5rem;
      padding: 2rem 5rem 2rem 8.3rem;
      position: relative;

      @media screen and (max-width: $tablet) {
        background-color: transparent;
      }

      &:before {
        border: 0.1rem solid $border;
        border-radius: 1.6rem;
        content: '';
        display: block;
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        top: 1.8rem;
        left: 3.1rem;

        @media screen and (max-width: $tablet) {
          display: none;
        }
      }

      @media screen and (max-width: $smartphone) {
        .shop {
          display: block;
          margin-top: 0.5rem;
        }
      }
    }

    &_content {
      .place {
        font-weight: $font-bold;
        margin-bottom: 0;
        min-height: 2.4rem;
      }

      @media screen and (max-width: $tablet) {
        & + .checkout-delivery_more {
          padding: 0;
        }

        .address-add {
          padding: 1rem 0 1rem 2rem;

          .checkout-delivery_address-full {
            padding-top: 1rem;
          }
        }
      }
    }

    &_delivery {
      &-right {
        padding-left: 4.5rem;
        position: relative;
        display: none;

        &:before {
          background-color: $border;
          content: '';
          display: block;
          width: 0.1rem;
          height: 100%;
          position: absolute;
          top: 0;
          left: 1.5rem;
        }

        .select2-container {
          margin-bottom: 1.8rem;

          &--default {
            .select2-selection--single {
              .select2-selection {
                &__rendered {
                  font-weight: $font-medium;
                  font-size: 2rem;
                  padding-right: 6.5rem;
                  text-transform: uppercase;

                  @media screen and (max-width: $smartphone) {
                    font-size: 1.4rem;
                    padding-right: 1.2rem;
                    text-align: center;
                  }
                }

                &__arrow {
                  border-left: none;
                  width: 6.5rem;

                  &:before {
                    width: 2rem;
                    height: 1.1rem;
                    left: 1.4rem;
                    transform: translate(0, -50%);
                  }

                  @media screen and (max-width: $smartphone) {
                    display: none;
                  }
                }
              }
            }
          }
        }

        @media screen and (max-width: $tablet) {
          padding-left: 6.8rem;

          &:before {
            display: none;
          }
        }
      }

      .title {
        font-weight: $font-bold;
        margin-bottom: 0;

        @media screen and (max-width: $tablet) {
          display: none;
        }
      }

      @media screen and (max-width: $tablet) {
        .delivery-label {
          display: none;
        }
      }

      @media screen and (max-width: $smartphone) {
        .link-blue {
          font-size: 1.4rem;
        }
      }

      .coupon-success {
        color: $subcategory;
        font-weight: $font-bold;
        margin-bottom: 3.5rem;
        text-align: center;
      }
    }

    &_map {
      .caption {
        font-size: 1.8rem;
      }

      .map {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          @media screen and (max-width: $tablet) {
            display: none;
          }
        }

        a {
          display: none;

          @media screen and (max-width: $tablet) {
            display: block;
          }
        }
      }
    }

    &_address {
      width: 100%;
      position: relative;
      display: none;

      .caption {
        margin: -0.3rem 0 0;

        @media screen and (max-width: $smartphone) {
          background: url(../../images/ic-info.svg) 0 0 no-repeat;
          background-size: 1.8rem 1.8rem;
          font-size: 1.4rem;
          line-height: 1.8rem;
          margin: 0;
          padding-left: 3rem;
        }
      }

      input[type='text'] {
        padding-right: 5.5rem;

        @media screen and (max-width: $smartphone) {
          padding-right: 3.5rem;
        }
      }

      .btn-map {
        background-color: $white;
        border-left: 0.1rem solid $gray-border;
        position: absolute;
        right: 0.15rem;
        top: 0.5rem;
        width: 4.6rem;
        height: 4rem;

        @media screen and (max-width: $smartphone) {
          width: 3.3rem;
          height: 3rem;
        }
      }

      &-full {
        .mobile-filter_checkbox {
          border-bottom: none;
        }
      }

      @media screen and (max-width: $hd) {
        max-width: none;
      }

      @media screen and (max-width: $tablet) {
        margin-bottom: 2rem;
      }
    }

    &_more {
      padding: 2.5rem 0 0 5.3rem;
      display: none;

      @media screen and (max-width: $tablet) {
        padding: 1.3rem 0 0;

        &-inner {
          & + .checkout-delivery_address-full {
            padding-top: 0;
          }
        }
      }

      .title {
        font-weight: $font-bold;
      }

      .input-group {
        margin-bottom: 1.8rem;
      }

      select,
      .select2-container {
        width: 100% !important;
      }

      .note {
        color: $add-text;
        font-size: 1.4rem;
        margin-bottom: 0;

        &-mobile {
          display: none;
        }

        @media screen and (max-width: $tablet) {
          font-size: 1.2rem;

          &-desktop {
            display: none;
          }

          &-mobile {
            display: block;
          }
        }
      }

      .checkout-delivery_address-full {
        padding-left: 0;
      }
    }

    &_user {
      border: 0.1rem solid $gray-border;
      font-size: 2rem;
      width: 100%;
      max-width: 36.5rem;
      margin-bottom: 2rem;
      position: relative;
      padding: 2.3rem 3rem 1.6rem;

      ul {
        margin-bottom: 0.8rem;
      }

      li {
        padding-bottom: 0.8rem;
      }

      .name {
        font-weight: $font-medium;
        font-size: 2.4rem;
        padding-bottom: 1.1rem;

        @media screen and (max-width: $smartphone) {
          font-size: 1.8rem;
          padding-bottom: 0;
        }
      }

      .note {
        color: $add-text;
        font-size: 1.4rem;
        margin-bottom: 0;
      }

      @media screen and (max-width: $smartphone) {
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
        padding: 1.2rem 2rem 1.5rem;

        .address {
          font-weight: $font-medium;
        }
      }
    }

    &_form {
      max-width: 32rem;

      .form-control {
        margin-bottom: 1rem;
      }

      .checkout-delivery_address {
        display: block;
        max-width: none;
        margin-bottom: 3rem;

        @media screen and (max-width: $smartphone) {
          margin-bottom: 1.5rem;
        }
      }
    }

    &_card {
      background-color: $white;
      border: 0.1rem solid $gray-border;
      border-radius: 0.2rem;
      box-shadow: 0 0.1rem 0.6rem rgba($shadow, 0.2);
      color: $main-text;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      padding: 2.1rem 2.5rem;
      width: 31.8rem;

      &-info {
        padding: 0 0 2.3rem 5.3rem;
        position: relative;

        @media screen and (max-width: $smartphone) {
          padding: 0 0 1.3rem 4.3rem;
        }
      }

      .card {
        &-logo {
          position: absolute;
          top: 0.7rem;
          left: 0;

          &_mc {
            width: 3.8rem;
            height: 2.4rem;

            @media screen and (max-width: $smartphone) {
              width: 3.2rem;
              height: 2rem;
            }
          }

          @media screen and (max-width: $smartphone) {
            top: 0.5rem;
          }
        }

        &-name {
          font-weight: $font-medium;
          font-size: 2.4rem;
          margin: 0 0 -0.3rem;

          @media screen and (max-width: $smartphone) {
            font-size: 1.8rem;
          }
        }

        &-note {
          color: $add-text;
          font-size: 1.6rem;
          margin: 0 0 0.6rem;

          @media screen and (max-width: $smartphone) {
            font-size: 1.2rem;
          }
        }
      }

      @media screen and (max-width: $smartphone) {
        margin-bottom: 1rem;
        padding: 1rem 1.5rem 1.1rem;
        width: 100%;

        .term {
          display: none;
        }
      }
    }

    input[type='radio']:checked + label {
      background-color: $white;
      box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
      border-radius: 0.8rem;
      margin-bottom: 3rem;
      padding: 2.7rem 3rem;

      @media screen and (max-width: $tablet) {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
        padding: 0 0.9rem;
      }

      &:before {
        border-color: $subcategory;
        top: 2.6rem;

        @media screen and (max-width: $tablet) {
          display: none;
        }
      }

      &:after {
        background-color: $subcategory;
        border-radius: 1.5rem;
        content: '';
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        top: 3.2rem;
        left: 3.7rem;

        @media screen and (max-width: $tablet) {
          display: none;
        }
      }

      .title {
        @media screen and (max-width: $tablet) {
          display: none;
        }
      }

      .place {
        font-weight: $font-medium;
        padding-left: 5.3rem;

        @media screen and (max-width: $tablet) {
          font-weight: $font-semibold;
          padding-left: 0;

          & + .checkout-delivery_address-full {
            padding-top: 1rem;
          }
        }
      }

      .checkout-delivery {
        &_delivery {
          .title,
          .checkout-delivery_address {
            padding-left: 5.3rem;

            @media screen and (max-width: $smartphone) {
              padding-left: 3.1rem;
              margin-bottom: 0;
            }
          }

          .title {
            @media screen and (max-width: $smartphone) {
              margin-bottom: 1.2rem;
            }
          }

          &-right {
            display: block;

            @media screen and (max-width: $tablet-small) {
              display: none;
            }
          }
        }

        &_address,
        &_more {
          display: block;

          &-full {
            display: flex;
          }
        }
      }
    }

    &_address {
      &-full {
        padding: 1.5rem 0 0 5.3rem;
        display: none;

        .checkout-delivery_address {
          select {
            width: 100%;
          }

          &-street {
            border: 0.1rem solid $border;
            padding-right: 4.7rem;
            position: relative;

            .select2-container--default .select2-selection--single {
              border: none;
              height: 4.75rem;

              .select2-selection__arrow {
                border-left: none;
              }

              @media screen and (max-width: $smartphone) {
                height: 3.75rem;
              }
            }

            .btn-map {
              position: absolute;
              top: 0.4rem;
              right: 0;
            }
          }
        }

        .form-part {
          margin-bottom: 2.6rem;
          position: relative;

          label {
            color: #919ba9;
          }

          .caption {
            font-family: $font-family-base;

            @media screen and (max-width: $tablet) {
              font-size: 1.4rem;
            }
          }

          @media screen and (max-width: $smartphone) {
            margin-bottom: 2rem;
          }
        }

        .title {
          font-size: 1.6rem;
          font-weight: $font-bold;
          color: $main-text;
          margin-bottom: 0;
        }

        @media screen and (max-width: $tablet) {
          margin-bottom: 0;
          padding-left: 0;
        }

        @media screen and (max-width: $tablet-small) {
          .col-6 {
            &:nth-child(2n) {
              padding-right: 5px;
            }

            &:nth-child(2n + 1) {
              padding-left: 5px;
            }
          }
        }
      }
    }

    &_block {
      &.disabled {
        .checkout-delivery {
          &_label {
            cursor: default;
            padding-right: 16rem;

            @media screen and (max-width: $smartphone-small) {
              padding-right: 1.7rem;
            }
          }

          &_content {
            color: $gray-border;

            .place {
              .main-text {
                color: $gray-border;
              }
            }
          }
        }
      }
    }

    &_no-product {
      position: absolute;
      top: 50%;
      right: 3rem;
      transform: translateY(-50%);

      @media screen and (max-width: $smartphone-small) {
        position: relative;
        top: auto;
        right: auto;
        transform: none;
        margin-top: 2rem;
        text-align: right;
      }

      .no-product {
        &_title {
          color: $red-light;
          font-family: $font-family-second;
          font-size: 1.2rem;
          margin: 0;
        }

        &_info {
          background-color: $bg-blue-gray;
          border: 0.1rem solid $gray-border;
          border-radius: 100%;
          color: $main-text;
          cursor: pointer;
          display: inline-block;
          font-size: 1rem;
          line-height: 1.65rem;
          margin-left: 0.6rem;
          width: 1.8rem;
          height: 1.8rem;
          text-align: center;
        }

        &_hint {
          position: absolute;
          bottom: 100%;
          right: -1.7rem;
          background-color: $white;
          border: 0.1rem solid $gray-border;
          border-radius: 0.2rem;
          box-shadow: 0 0.1rem 0.3rem rgba($shadow, 0.2);
          font-weight: $font-medium;
          font-size: 1.6rem;
          margin-bottom: 1.5rem;
          width: 41.6rem;
          padding: 2rem 3rem 2.8rem;
          display: none;

          &:before,
          &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            border-width: 1rem 0.7rem 0;
            border-top-color: $white;
            position: absolute;
            bottom: -1rem;
            right: 1.8rem;
            z-index: 2;
          }

          &:after {
            border-width: 1.1rem 0.8rem 0;
            border-top-color: $gray-border;
            bottom: -1.2rem;
            right: 1.7rem;
            z-index: 1;
          }

          &.show {
            display: block;
          }

          .shop {
            font-size: 2rem;
          }

          .product-list {
            text-transform: uppercase;

            a {
              color: $subcategory;
            }
          }

          @media screen and (max-width: $smartphone-small) {
            right: -1.8rem;
            font-size: 1.4rem;
            padding: 1rem 2rem 2rem;
            width: 30rem;

            &:before {
              right: 1.9rem;
            }

            &:after {
              right: 1.8rem;
            }

            .shop {
              font-size: 1.8rem;
            }
          }
        }
      }
    }

    @media screen and (max-width: $smartphone) {
      &_get {
        border-bottom: 0.1rem solid $gray-border;
        font-size: 1.8rem;
        padding-bottom: 1.5rem;

        .title {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  &-profile {
    background-color: $white;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    border-radius: 0.8rem;
    margin-bottom: 3rem;
    padding: 2.7rem 3rem;
    position: relative;

    @media screen and (max-width: $tablet) {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      margin-bottom: 0;
      padding: 0 0.9rem;

      .checkout-delivery_delivery {
        & > .row {
          flex-direction: column-reverse;
        }

        &-right {
          margin-bottom: 0.9rem;
          padding: 0 1.6rem 0 1.5rem;

          .checkout-delivery_map {
            border-bottom: 0.1rem solid $gray-border;
            padding-bottom: 1.5rem;
          }

          .caption {
            line-height: 1.3;
            margin-bottom: 0;

            span {
              font-weight: $font-medium;
            }
          }

          .map {
            font-weight: $font-medium;
            font-size: 1.4rem;
            margin-top: 0.9rem;

            a {
              background: url(../../images/ic-map_b.svg) 0 0 no-repeat;
              background-size: 1.2rem 1.5rem;
              padding-left: 2.3rem;
            }
          }
        }
      }
    }

    @media screen and (max-width: $tablet-small) {
      margin: 0 auto 3rem;
      max-width: 45rem;
    }

    @media screen and (max-width: $smartphone-middle) {
      max-width: none;
    }

    .checkout-delivery {
      &_delivery {
        .title {
          margin-bottom: 1.8rem;
        }

        &-right {
          display: block;
        }
      }
    }

    &_register {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $main-text;
      font-weight: $font-medium;
      font-size: 2rem;
      max-width: 66rem;
      margin: 2rem auto;
      text-align: center;

      @media screen and (max-width: $smartphone) {
        font-size: 1.6rem;
      }

      p {
        margin-bottom: 2rem;
      }

      .btn {
        width: auto;
        padding: 0 2rem;
      }
    }

    .btn {
      &-edit,
      &-delete,
      &-save {
        position: absolute;
        top: -0.1rem;
        right: -0.1rem;
      }
    }

    .title-mobile {
      display: none;

      @media screen and (max-width: $tablet) {
        display: block;
        font-size: 1.6rem;
        font-weight: $font-bold;
        margin-bottom: 2.4rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.4rem;
      }
    }
  }

  &-btn-row {
    display: none;

    .form-check {
      height: 5rem;
      margin: 1.1rem 0 0 3.4rem;

      @media screen and (max-width: $hd) {
        margin: 0;
      }
    }
  }

  &-result {
    background-color: $bg-blue-gray;
    margin-bottom: 1rem;
    padding: 1.6rem 3rem 1.2rem;

    p {
      margin-bottom: 0;
      padding-bottom: 0.8rem;
    }

    .title {
      color: $add-text;
    }

    .name {
      padding-right: 1.5rem;
      position: relative;

      &:after {
        content: '/';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    @media screen and (max-width: $tablet) {
      background-color: #eeeff0;
      color: #2d3541;
      margin: 0 -1.5rem 3.3rem;
      padding: 1.8rem 2.4rem 1rem;

      .title,
      .shop {
        display: block;
      }

      .title {
        color: #919ba9;
      }

      .name {
        display: block;
        padding-right: 0;

        &:after {
          display: none;
        }
      }
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.4rem;
    }
  }

  &-code {
    padding: 0 0 2rem;

    &_section {
      max-width: 88rem;
      margin: 0 auto;
      width: 100%;
    }

    &_code {
      color: $add-text;
      max-width: 64rem;
      margin: 0 auto 3.5rem;

      .title {
        color: $main-text;
        font-weight: $font-medium;
        font-size: 2.4rem;
      }
    }

    &_form {
      background-color: $bg-social;
      border: 0.1rem solid $border;
      border-radius: 0.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3rem;

      input {
        flex: 0 0 40rem;
        width: 40rem;
        margin: 0 2rem 0 0;
      }
    }
  }

  &-certificate {
    .dropdown {
      font-weight: $font-bold;
      padding: 0;

      @media screen and (max-width: $smartphone) {
        font-size: 1.4rem;
      }

      &-toggle {
        border: 0.1rem solid $border;
        padding: 1rem 3rem;
        text-align: left;

        span {
          width: 100%;

          &:after {
            margin: 0;

            @media screen and (max-width: $smartphone) {
              width: 1.5rem;
              height: 0.9rem;
            }

            @media screen and (max-width: $smartphone-small) {
              display: none;
            }
          }

          @media screen and (max-width: $smartphone-small) {
            padding-right: 0;
            text-align: center;
          }
        }

        @media screen and (max-width: $smartphone) {
          padding: 1rem 3rem;
        }
      }

      &-menu {
        border-radius: 0 0 0.2rem 0.2rem;
        margin: -0.1rem 0 0;
        padding: 2rem 3rem 2.9rem;

        &:before,
        &:after {
          display: none;
        }

        .caption {
          font-weight: $font-medium;
          font-size: 2rem;
          margin-bottom: 1.9rem;

          @media screen and (max-width: $smartphone) {
            font-size: 1.4rem;
          }
        }

        .btn {
          text-transform: uppercase;
        }
      }

      &:hover {
        background-color: $white;

        .dropdown {
          &-toggle {
            border-color: $subcategory;
            color: $subcategory;

            span {
              &:after {
                background-image: url(../../images/ic-down.svg);
              }
            }
          }
        }
      }

      &.show {
        .dropdown {
          &-toggle {
            border-color: $subcategory;
            color: $subcategory;

            span {
              &:after {
                background-image: url(../../images/ic-down.svg);
                margin: -1.1rem 0 0;
              }
            }
          }

          &-menu {
            border-color: $subcategory;
          }
        }
      }
    }

    @media screen and (max-width: $hd) {
      border-top: 0.1rem solid $border;
      margin-top: 1.8rem;
      padding-top: 1.9rem;
    }
  }

  &-thankyou {
    height: 100%;

    @media screen and (max-width: $tablet) {
      height: auto;
      margin-top: -6rem;
    }

    h1 {
      display: none;
    }

    &_block {
      background-color: $white;
      box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
      border-radius: 0.8rem;
      margin-bottom: 4rem;
      padding: 2rem 3rem 1rem 5rem;
      position: relative;

      @media screen and (max-width: $tablet) {
        background-color: $white;
        box-shadow: none;
        border-radius: 0;
        margin: 0 -1.5rem 3rem;
        padding: 10.3rem 2.4rem 3rem;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 1.7rem;
        position: absolute;
        top: 2.3rem;
        left: 1.5rem;

        @media screen and (max-width: $tablet) {
          background: url(../../images/ic-success.svg) 0 0 no-repeat;
          background-size: cover;
          width: 4.5rem;
          height: 4.5rem;
          top: 3rem;
          left: 50%;
          transform: translateX(-50%);
        }

        &-check {
          width: 2rem;
          height: 1.7rem;

          @media screen and (max-width: $tablet) {
            display: none;
          }
        }
      }

      .thankyou {
        font-size: 2rem;
        margin-bottom: 2rem;

        span {
          display: block;
        }

        @media screen and (max-width: $tablet) {
          color: #2d3541;
          font-size: 1.8rem;
          font-weight: $font-bold;
          line-height: 1.2;
          text-align: center;
        }
      }
    }

    .checkout {
      &-result {
        display: none;

        @media screen and (max-width: $tablet) {
          display: block;
          background-color: transparent;
          margin-bottom: 0;
          padding: 2.5rem 1.5rem 0;
        }
      }

      &-btn-row {
        @media screen and (max-width: $smartphone) {
          flex-direction: column-reverse;
          margin-bottom: 1rem;

          .btn {
            margin-bottom: 1rem;
          }
        }
      }
    }

    &_phone {
      position: absolute;
      bottom: 0;
      left: 0;
      color: $add-text;

      .number {
        color: $text;
        font-weight: $font-bold;
        margin: 0;
      }

      @media screen and (max-width: $tablet) {
        margin-top: 2rem;
        position: relative;
      }
    }

    &_info {
      @media screen and (max-width: $tablet) {
        font-size: 1.4rem;

        .order-info {
          color: #919ba9;
          font-size: 1.2rem;
          margin-top: 2.6rem;
        }
      }

      p {
        margin-bottom: 2rem;

        @media screen and (max-width: $tablet) {
          margin-bottom: 0;
        }
      }
    }
  }

  &-info {
    margin-bottom: 3rem;

    @media screen and (max-width: $tablet) {
      padding: 0 0.9rem;
      font-size: 1.4rem;
      margin-bottom: 0;

      & + .checkout-result {
        display: none;
      }
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.2rem;
    }

    &_one {
      display: flex;
      margin-top: 2rem;

      @media screen and (max-width: $tablet) {
        display: block;
      }

      &:first-child {
        margin-top: 0;
      }

      .icon {
        flex: 0 0 8rem;
        width: 8rem;

        @media screen and (max-width: $tablet) {
          display: block;
          width: 5rem;
          margin-bottom: 1.2rem;
        }

        img {
          width: 4rem;
          height: auto;

          @media screen and (max-width: $tablet) {
            width: 3rem;
          }
        }
      }
    }
  }

  &-pay {
    @media screen and (max-width: $hd) {
      .checkout-delivery_delivery-right {
        padding-left: 15px;

        &:before {
          display: none;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      .checkout-delivery_delivery {
        & > .row {
          flex-direction: column;
        }
      }
    }

    @media screen and (max-width: $smartphone) {
      margin: 5.4rem 0 1rem;
      position: relative;
    }
  }

  &-coupon {
    .coupon-success {
      font-size: 1.8rem;
      font-weight: $font-bold;
      margin: 0 auto 1.2rem;
      max-width: 26rem;
      padding-top: 6.5rem;
      position: relative;
      text-align: center;

      &:before {
        background: url(../../images/ic-success.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 4.5rem;
        height: 4.5rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &_price {
        font-weight: $font-bold;
        text-align: center;

        .caption {
          color: $subcategory;
          font-size: 1.2rem;
        }

        .price {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.8rem;
          margin: 1.1rem 0 2.4rem;
        }

        .old {
          color: #919ba9;
          font-size: 1.6rem;
          text-decoration: line-through;
        }

        .new {
          color: $red;
          margin-left: 0.8rem;
        }
      }
    }
  }

  &-content {
    .checkout-profile + .row,
    .checkout-delivery + .row {
      .btn-close_mobile {
        display: none;
      }

      @media screen and (max-width: $tablet) {
        background-color: $white;
        border-radius: 0.5rem 0.5rem 0 0;
        margin: 0;
        padding: 1rem 2.8rem 1rem;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 12;

        .mobile-bottom {
          display: flex;
          justify-content: space-between;
          padding: 0;

          .btn-close_mobile {
            display: block;
            background: url(../../images/ic-close_b.svg) 50% 50% no-repeat;
            background-size: 1.2rem 1.2rem;
            border: none;
            flex: 0 0 4.8rem;
            width: 4.8rem;
            margin: 0 0.6rem 0 -1.6rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet) {
    &-step1 {
      flex-direction: column-reverse;
      padding-top: 6rem;
    }
  }
}

.block-info {
  background-color: $bg-social;
  border-radius: 0.2rem;
  color: $add-text;
  font-weight: $font-medium;
  font-size: 2rem;
  padding: 1.1rem;
  width: 100%;
  text-align: center;
  text-transform: uppercase;

  @media screen and (max-width: $smartphone) {
    font-size: 1.4rem;
    padding-bottom: 1.1rem;
  }
}

.todo-disabled {
  pointer-events: none;
  opacity: 0.5;
}
