.modal {
  z-index: 1100;

  &-backdrop {
    background-color: rgba($main-text, 0.8);
    z-index: 1099;

    &.show {
      opacity: 1;
    }
  }

  &-dialog {
    background-color: $white;
    border: 0.1rem solid rgba($gray-border, 0.2);
    border-radius: 0.4rem;
    box-shadow: 0 0 2rem 0 rgba($shadow, 0.2);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0;

    @media screen and (max-width: $smartphone) {
      border-radius: 0;
      top: 0;
      left: 0;
      transform: none !important;
      width: 100%;
      height: 100%;
    }

    .close {
      cursor: pointer;
      font-size: 2.8rem;
      position: absolute;
      top: -2.3rem;
      right: -1.4rem;
      opacity: 1;
      z-index: 10;

      @media screen and (max-width: $smartphone) {
        top: 3rem;
        right: 2rem;
      }
    }
  }

  &-content {
    background-color: transparent;
    border: none;
    border-radius: 0;

    @media screen and (max-width: $smartphone) {
      height: 100%;
    }
  }

  &-header,
  &-header h4,
  &-title {
    color: $main-text;
    font-weight: $font-medium;
    font-size: 2.4rem;

    @media screen and (max-width: $smartphone) {
      font-size: 2rem;

      &.desktop {
        display: none;
      }
    }
  }

  &-title {
    &.mobile {
      display: none;

      @media screen and (max-width: $smartphone) {
        display: block;
      }
    }
  }

  &-header {
    background-color: transparent;
    border-radius: 0;
    border-bottom-color: $gray-border;
    padding: 2.5rem 5.8rem 2rem;
    position: relative;

    .back-link {
      display: none;
    }

    @media screen and (max-width: $smartphone) {
      box-shadow: 0 0.1rem 0.6rem 0 rgba($shadow, 0.2);
      border-bottom: none;
      justify-content: center;
      font-size: 2rem;
      padding: 0.9rem 1.5rem 1.1rem;
      position: relative;
      text-align: center;

      .back-link {
        display: block;
        position: absolute;
        top: 50%;
        left: 1.5rem;
        transform: translateY(-50%);
        margin: 0;
        padding: 0;
        width: 3rem;
      }
    }
  }

  &-body {
    padding: 2.6rem 3.1rem;

    @media screen and (max-width: $smartphone) {
      padding: 2rem 1.5rem 7.5rem;
    }
  }

  &-footer {
    border-radius: 0;
    border-top-color: $gray-border;
    display: block;
    padding: 2.9rem 3.1rem 3rem;

    div[class*='col-'] {
      &:nth-child(2n + 1) {
        padding-right: 10px;
      }

      &:nth-child(2n) {
        padding-left: 10px;
      }
    }

    & > * {
      margin: 0;
    }

    @media screen and (max-width: $smartphone) {
      background-color: $white;
      border-top: none;
      padding: 1.5rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &_add-card {
    width: 70rem;
    max-width: none;

    @media screen and (max-width: $smartphone) {
      width: 100%;
    }

    .modal {
      &-header {
        padding: 1.8rem 3.1rem 2.3rem;

        @media screen and (max-width: $smartphone) {
          padding: 0.9rem 1.5rem 1.1rem;
        }

        .card-logos {
          border-left: 0.1rem solid $gray-border;
          display: flex;
          align-items: center;
          height: 3.8rem;
          position: absolute;
          top: 50%;
          right: 4rem;
          transform: translateY(-50%);

          img {
            margin-left: 2.4rem;

            &.mc-logo {
              width: 3.9rem;
            }

            &.visa-logo {
              width: 5.6rem;
            }
          }

          @media screen and (max-width: $smartphone) {
            display: none;
          }
        }
      }

      &-title {
        font-size: 3rem;

        @media screen and (max-width: $smartphone) {
          font-size: 2rem;
        }
      }

      &-body {
        padding: 2.9rem 3.1rem;

        @media screen and (max-width: $smartphone) {
          padding: 2rem 1.5rem 7.5rem;
        }
      }

      &-footer {
        display: flex;
        justify-content: space-between;

        .btn {
          width: 15rem;

          @media screen and (max-width: $smartphone) {
            width: 49%;
          }
        }
      }
    }

    .form_add-card {
      background-color: $bg-social;
      border: 0.1rem solid rgba($gray-border, 0.5);
      border-radius: 0.8rem;
      color: $add-text;
      width: 100%;
      height: 24.5rem;
      padding: 8.5rem 3.4rem 2rem;

      @media screen and (max-width: $smartphone) {
        height: auto;
        max-width: 32rem;
        margin: 0 auto;
        padding: 2.6rem 2.5rem 2.5rem;
      }

      .form {
        &-group {
          margin-bottom: 0;
        }

        &-row {
          &_card {
            display: flex;

            .form-group {
              &:first-child {
                width: 15rem;
                flex: 0 0 15rem;
              }
            }

            label {
              margin-bottom: 0.2rem;
            }

            input {
              height: 2rem;
              padding: 0;

              @media screen and (max-width: $smartphone) {
                font-size: 1.4rem;
              }
            }
          }
        }
      }

      label {
        font-family: $font-family-base;

        @media screen and (max-width: $smartphone) {
          font-size: 1.4rem;
        }
      }

      input {
        background-color: transparent;
        border: none;
        color: $main-text;

        &::-webkit-input-placeholder {
          color: $main-text;
        }

        &::-moz-placeholder {
          color: $main-text;
        }

        &:-moz-placeholder {
          color: $main-text;
        }

        &:-ms-input-placeholder {
          color: $main-text;
        }

        &.card {
          color: $add-text;
          font-family: $font-family-second;
          font-size: 3rem;
          height: 3.3rem;
          margin-bottom: 1.8rem;
          padding: 0;

          &::-webkit-input-placeholder {
            color: $add-text;
          }

          &::-moz-placeholder {
            color: $add-text;
          }

          &:-moz-placeholder {
            color: $add-text;
          }

          &:-ms-input-placeholder {
            color: $add-text;
          }

          @media screen and (max-width: $smartphone) {
            font-size: 2rem;
          }
        }
      }
    }
  }

  &-close {
    background: url(../../images/ic-close.svg) 0 0 no-repeat;
    background-size: cover;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 2.2rem;
    right: 2.5rem;
    z-index: 3;
  }

  &-notify {
    width: 100%;
    max-width: 70rem;
    margin: auto;

    @media screen and (max-width: $tablet) {
      width: auto;
    }

    &_content {
      background-color: $white;
      width: 100%;
      max-width: 70rem;
      padding: 3.2rem 3.6rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      @media screen and (max-width: $smartphone-middle) {
        padding: 3.2rem 1.5rem 2rem;
      }
    }

    .modal-close {
      top: 1.2rem;
      right: 1.5rem;
    }

    h3 {
      font-size: 2rem;
      margin-bottom: 3rem;
    }

    .search-address {
      display: inline-block;
      margin-bottom: 2rem;
    }

    .form-item {
      label {
        display: block;
        text-align: left;
      }
    }

    .btn {
      max-width: 20rem;
      margin: 0 auto 2rem;

      @media screen and (max-width: $smartphone-middle) {
        max-width: none;
      }
    }
  }
}

.ReactModal {
  &__Overlay {
    background: rgba($black, 0.5) !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1120 !important;
  }

  &__Content {
    position: absolute;
    top: 4rem;
    left: 4rem;
    right: 4rem;
    bottom: 4rem;
    overflow: auto;
    outline: none;

    @media screen and (max-width: $smartphone) {
      inset: 1.5rem;
    }
  }
}

.product {
  &_modal {
    top: auto;
    bottom: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &_overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
}

.cart {
  &_modal {
    z-index: 11111;
    left: 0;
    right: 0;
    height: 2px;
    overflow: inherit;
    margin: auto;
    top: initial;
    bottom: initial;
  }

  &_overlay {
    z-index: 1111;
  }
}

.edit {
  &_modal {
    z-index: 11111;
    left: 0;
    right: 0;
    height: 2px;
    overflow: inherit;
    max-width: 139.8rem;
    margin: auto;
    top: initial;
    bottom: initial;
    position: static;
  }

  &_overlay {
    z-index: 1111;
  }
}

.modal-body-custom {
  .modal_add-card {
    width: 70rem;
  }

  .checkout-delivery_address-street {
    margin-bottom: 3rem;
  }
}

.checkout-delivery_card .card-name {
  font-size: 2rem;
}

.modal-header {
  display: flex;
  align-items: center;
}

.center-portmone {
  .sep {
    display: none;
    justify-content: center;
    color: #919ba9;
    font-size: 1.4rem;
    font-weight: $font-semibold;
    margin: 2rem -1rem 1.5rem;
    position: relative;

    @media screen and (max-width: $tablet) {
      display: flex;
    }

    &:before {
      background-color: #e9e9e9;
      content: '';
      display: block;
      width: 100%;
      height: 0.1rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    span {
      background-color: $bg;
      padding: 0 5rem;
      position: relative;
    }
  }
}

.modal_add-card {
  .modal-footer {
    z-index: 111;

    &-card {
      justify-content: flex-end;
    }
  }
}

.payment {
  &_overlay {
    background: rgba($black, 0.5);
  }
}

.coupon-modal {
  width: calc(100% - 8rem);
  height: calc(100% - 8rem);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $tablet) {
    width: 100%;
    height: 100%;
    align-items: flex-end;
    inset: 0;
  }

  &-container {
    background-color: $white;
    padding: 4rem;
    border-radius: 0.8rem;
    width: 100%;
    max-width: 60rem;

    .coupon-error {
      color: $red;
    }

    @media screen and (max-width: $tablet) {
      border-radius: 2rem 2rem 0 0;
      padding: 5.8rem 2.4rem 1.5rem;
      position: relative;

      &:before {
        background-image: url(../../images/ic-arrow.svg);
        background-size: cover;
        content: '';
        display: block;
        position: absolute;
        top: 1.8rem;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        width: 1.7rem;
        height: 1.1rem;
        opacity: 0.3;
      }

      h1 {
        font-size: 1.8rem;
        margin-bottom: 2.2rem;
      }

      .coupon {
        &-error {
          font-size: 1.8rem;
          font-weight: $font-bold;
          margin: 0 auto 2.2rem;
          max-width: 26rem;
          padding-top: 6.5rem;
          position: relative;
          text-align: center;

          &:before {
            background: url(../../images/ic-error.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 4.5rem;
            height: 4.5rem;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .coupon-used {
      margin-bottom: 2.5rem;

      .top {
        font-weight: $font-semibold;
      }

      .btn {
        margin: 1rem 0 2.5rem;
        width: auto;
      }

      .or {
        color: $add-text;
        position: relative;
        text-align: center;

        &:before {
          background-color: $gray-border;
          content: '';
          display: block;
          width: 100%;
          height: 0.1rem;
          position: absolute;
          top: 1.1rem;
          left: 0;
        }

        span {
          background-color: $white;
          display: inline-block;
          padding: 0 1.5rem;
          position: relative;
        }
      }
    }
  }

  &_form {
    @media screen and (max-width: $tablet) {
      .title {
        display: none;
      }

      .form-control {
        margin-bottom: 2.3rem;
      }
    }
  }

  &_without {
    border-bottom: 0.1rem dashed $text;
    cursor: pointer;
    display: inline-block;
    margin-top: 2rem;

    &:hover {
      border-color: $subcategory;
      color: $subcategory;
    }

    @media screen and (max-width: $tablet) {
      border: 0.1rem solid $subcategory;
      border-radius: 1rem;
      color: $subcategory;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      font-weight: $font-bold;
      height: 4.8rem;
      margin-top: 1rem;

      &:hover {
        background-color: $subcategory;
        color: $white;
      }
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.4rem;
    }
  }
}
