.home {
  &-page {
    padding: 0 0 6rem;
    background-color: $bg;

    .section-main {
      padding-bottom: 0;
    }

    // &-desktop {
    //   // display: none;
    // }

    @media screen and (max-width: $tablet) {
      &_mobile {
        padding: 5.6rem 0 8rem !important;
      }

      .main-page-banner {
        display: block;
        margin-top: 2rem;

        .react-multi-carousel-list {
          padding-bottom: 2rem;
        }
      }
    }

    .banner {
      &-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @media screen and (max-width: $tablet) {
          border-radius: 2rem;
          height: 45rem;
          position: relative;
        }

        @media screen and (max-width: $tablet-small) {
          height: 30rem;
        }

        @media screen and (max-width: $smartphone-middle) {
          height: 24rem;
        }

        @media screen and (max-width: $smartphone-small) {
          height: 19rem;
        }

        img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        video {
          position: absolute;
          top: 0;
          left: 1.5rem;
          width: calc(100% - 2rem);

          @media screen and (max-width: $tablet) {
            left: 0;
            width: 100%;
          }
        }
      }

      &-text {
        color: $white;
        padding: 3rem;

        @media screen and (max-width: $smartphone-middle) {
          padding: 2rem 2.4rem;
          position: relative;
        }

        h1 {
          margin-bottom: 1.5rem;
        }

        h2 {
          font-size: 2rem;
          line-height: 1.25;
          text-align: left;
        }
      }

      &-mobile {
        a {
          color: $white;
        }

        .btn {
          display: inline-flex;
          width: auto;

          @media screen and (max-width: $tablet) {
            position: absolute;
            right: 2rem;
            bottom: 2rem;
            height: 3.2rem;
            font-size: 1.4rem;
          }

          @media screen and (max-width: $tablet) {
            right: 1rem;
            bottom: 1rem;
            font-size: 1.2rem;
          }
        }
      }

      @media screen and (max-width: $tablet) {
        &-mobile {
          margin: 0 2.4rem;
          overflow: hidden;
          position: relative;
        }
      }

      @media screen and (max-width: $smartphone-smallest) {
        &-mobile {
          margin: 0 1.5rem;
        }
      }
    }
  }

  &-adv {
    margin-top: 1rem;

    &_big {
      color: $white;
      margin-right: -1rem;
      min-height: 39.4rem;
      height: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: flex-end;

      @media screen and (max-width: $tablet) {
        display: block;
        min-height: 5rem;

        .banner-pic {
          position: relative;
        }
      }

      .banner {
        &-text {
          padding: 5rem;
          position: relative;

          @media screen and (max-width: $tablet) {
            display: none;
          }

          h1 {
            font-size: 3rem;
            margin-bottom: 1rem;
          }
        }
      }

      .btn {
        position: relative;
        bottom: -3rem;
      }
    }

    &_column {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &_sm {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      align-items: flex-end;
      margin: 1rem 0 0 -1rem;
      position: relative;

      @media screen and (max-width: $tablet) {
        margin-left: 0;
      }

      &:first-child {
        margin-top: 0;
      }

      .btn {
        height: 4rem;
        position: relative;
        left: -1.5rem;
        bottom: -1.5rem;
      }
    }
  }

  &-art {
    background-color: $white;
    box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
    border-radius: 0.8rem;
    margin-bottom: 2rem;
    overflow: hidden;

    .pic {
      height: 28rem;
      overflow: hidden;

      @media screen and (max-width: $tablet) {
        height: 24rem;
      }

      @media screen and (max-width: $tablet-small) {
        height: 22rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        height: 18rem;
      }

      a {
        display: block;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &_label {
      padding: 1.4rem 2.4rem 1.8rem;

      p {
        font-weight: $font-black;
        margin-bottom: 0.5rem;
      }
    }

    &_list {
      margin-bottom: 4rem;
    }
  }

  @media screen and (max-width: $tablet) {
    &-page {
      &_landing {
        .mobile-categories_slider,
        .infinite-scroll-component__outerdiv {
          display: none;
        }

        .section-main {
          height: auto;
          padding-top: 6rem;
        }

        .search-main {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          color: $text !important;

          .container h1,
          .container h2 {
            color: $text !important;
          }

          .container h1 {
            margin-bottom: 2rem;
          }

          .container h2 {
            font-size: 1.8rem;
            font-weight: $font-regular;
          }

          .search-form_block .btn {
            margin-bottom: 4rem;
          }

          &_pic {
            position: relative;
            height: auto;
            max-width: 40rem;
          }
        }
      }
    }
  }
}
