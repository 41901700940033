.system {
  position: absolute;
  left: 50%;
  top: 1.5rem;
  transform: translateX(-50%);
  width: 90%;
  background-color: $white;
  border-radius: 0.25rem;
  color: $white;
  font-size: 1.4rem;
  font-weight: $font-semibold;
  padding: 0.6rem 4rem;
  text-align: center;
  z-index: 70;

  &.success {
    background-color: $alert-success-background;
    color: $alert-success-color;
    border-color: $alert-success-border;
  }

  &.error {
    background-color: $alert-danger-background;
    color: $alert-danger-color;
    border-color: $alert-danger-border;
  }

  &.none {
    display: none;
  }

  &-close {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
