.modal {
  &-delivery {
    background-color: $main-text;
    border: none;
    max-width: 126.5rem;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    max-height: 82rem;

    @media screen and (max-width: $tablet) {
      max-width: 58.9rem;
      height: 100%;
    }

    @media screen and (max-width: $smartphone) {
      max-width: none;
      transform: none !important;
    }

    .modal {
      &-header {
        border-bottom-color: #595f87;
        margin: 0 6.9rem;
        padding: 3.8rem 0;

        @media screen and (max-width: $hd) {
          margin: 0 4rem;
        }

        @media screen and (max-width: $smartphone) {
          border-bottom: 0.1rem solid #595f87;
          box-shadow: none;
        }

        @media screen and (max-width: $smartphone-middle) {
          margin: 0 2rem;
        }
      }

      &-title {
        color: $white;
        font-family: $font-family-base;
        font-size: 2.4rem;
        text-align: center;
        width: 100%;
      }

      &-footer {
        background-color: $main-text;
        border-top: none;
        padding: 5.3rem 1.5rem 5.6rem;
        text-align: center;

        .btn {
          width: 20.4rem;
          margin: 0 auto;
        }

        @media screen and (max-width: $smartphone) {
          padding: 2.9rem 1.5rem 3rem;
        }
      }

      &-content {
        height: 100%;
      }
    }
  }

  &-change {
    .modal {
      &-title {
        font-size: 3rem;
        text-align: center;
        width: 100%;
      }

      &-body {
        color: $main-text;
        font-size: 2.1rem;
        line-height: 2.6rem;
        padding: 3.5rem 4rem;

        @media screen and (max-width: $smartphone-small) {
          padding: 2rem 1.5rem 7.5rem;
        }
      }

      &-footer {
        display: flex;
        justify-content: space-between;

        .btn {
          width: 15rem;
        }
      }
    }
  }

  &-available {
    background-color: $main-text;
    border: none;
    color: $white;
    font-size: 1.8rem;
    max-width: 59.5rem;
    width: 100%;
    padding: 7rem 8rem 6.5rem;
    text-align: center;

    @media screen and (max-width: $smartphone) {
      max-width: none;
    }

    @media screen and (max-width: $smartphone-middle) {
      padding: 7rem 4rem 6.5rem;
    }

    .title {
      color: $subcategory;
      font-weight: $font-medium;
      font-size: 3.2rem;
      line-height: 1;
      margin-bottom: 3rem;
    }

    &_text {
      padding: 0 2.2rem 4rem;

      @media screen and (max-width: $smartphone-middle) {
        padding: 0 0 4rem;
      }
    }

    .form {
      &-control {
        border: 1px solid rgba(218, 222, 234, 0.500191);
        box-sizing: border-box;
        box-shadow: 0px 0px 20px rgba(160, 160, 160, 0.2);
        border-radius: 0.4rem;
        height: 7.3rem;
        margin-bottom: 0;
        text-align: center;

        &::-webkit-input-placeholder {
          color: $main-text;
        }

        &::-moz-placeholder {
          color: $main-text;
        }

        &:-moz-placeholder {
          color: $main-text;
        }

        &:-ms-input-placeholder {
          color: $main-text;
        }

        &:focus,
        &:active {
          background-color: $white;
        }
      }
    }

    .btn {
      text-transform: uppercase;
    }
  }

  &-settings {
    display: flex;
    align-items: center;

    &_modal {
      background-color: $white;
      border-radius: 0.8rem;
      max-width: 62.5rem;
      margin: 0 auto;
      padding: 4rem;
      position: relative;
      text-align: center;

      @media screen and (max-width: $smartphone-middle) {
        padding: 4rem 1.5rem;
      }

      .btn {
        max-width: 20rem;
        margin: 0 auto;
      }
    }

    &_content {
      margin-bottom: 4rem;
    }

    &_title {
      font-size: 1.8rem;
      font-weight: $font-bold;
      margin-bottom: 3rem;
    }
  }

  &-filter {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    inset: 0;

    &_container {
      background-color: $white;
      padding: 6.6rem 2.4rem 1.5rem;
      border-radius: 2rem 2rem 0 0;
      width: 100%;
      max-width: 60rem;
      max-height: calc(100vh - 3rem);
      margin: 0 auto;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: $smartphone-smallest) {
        padding: 6.6rem 1.5rem 1.5rem;
      }

      .top-close {
        background: url(../../images/ic-arrow.svg) 50% 50% no-repeat;
        background-size: 1.7rem 1.1rem;
        border-radius: 2rem 2rem 0 0;
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 3.9rem;
        opacity: 0.3;
        z-index: 5;
      }

      .title {
        font-size: 1.8rem;
        font-weight: $font-bold;
        margin-bottom: 2.1rem;
      }

      .btn {
        flex: 0 0 4.8rem;
      }

      .sorting-items {
        .form-radio label {
          padding-top: 0.4rem;
        }
      }
    }
  }

  &_zip-code {
    background: #79a0da url(../../images/bg-zip.png) 50% 100% no-repeat;
    border-radius: 0.8rem;
    width: 100%;
    max-width: 62.2rem;
    height: 54.6rem;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3.6rem 4.2rem;

    @media screen and (max-width: $smartphone + 10) {
      width: calc(100% - 3rem);
    }

    @media screen and (max-width: $smartphone-middle) {
      padding: 3rem 2rem;
    }

    .zip {
      &-title {
        font-size: 2.4rem;
        font-weight: $font-bold;
        margin: 0 auto 1rem;
        max-width: 48rem;
        text-align: center;

        @media screen and (max-width: $smartphone-middle) {
          font-size: 2rem;
        }
      }

      &-subtitle {
        color: $black;
        font-size: 1.4rem;
        line-height: 1.57;
        margin-bottom: 2.5rem;
        text-align: center;
      }

      &-form {
        display: flex;

        input {
          border-radius: 0.4rem;
        }

        @media screen and (max-width: $smartphone-middle - 75) {
          display: block;
        }

        .form-item {
          flex: 2;
        }

        .btn {
          background-color: #214983;
          max-width: 18rem;
          margin-left: 1.3rem;

          @media screen and (max-width: $smartphone-middle - 75) {
            margin: 0 auto;
          }
        }
      }
    }

    .btn {
      font-size: 1.4rem;
    }

    &_error {
      background: #8cabda url(../../images/bg-zip_corner.png) 100% 0 no-repeat;
      width: 100%;
      max-width: 61.3rem;
      height: 31.4rem;
      padding: 3.6rem 4.2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $smartphone + 10) {
        width: calc(100% - 3rem);
      }

      @media screen and (max-width: $smartphone-middle) {
        padding: 3rem 2rem;
      }

      .zip {
        &-title {
          max-width: none;
          margin-bottom: 2rem;
        }

        &-subtitle {
          max-width: 37rem;
          margin: 0 auto 3rem;

          @media screen and (max-width: $smartphone-middle) {
            max-width: none;
          }
        }
      }

      .btn {
        max-width: 30rem;
        margin: 0 auto;
      }
    }

    &_special {
      background: #8cabda url(../../images/bg-zip_corner.png) 100% 0 no-repeat;
      width: 69.9rem;
      height: 42rem;
      padding: 2.7rem 5rem;

      @media screen and (max-width: $smartphone + 10) {
        width: calc(100% - 3rem);
        padding: 3rem 2rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        height: 54.6rem;
      }

      .zip {
        &-special {
          display: flex;
          align-items: center;

          @media screen and (max-width: $smartphone-middle) {
            display: block;
          }

          &_left {
            flex: 0 0 26.6rem;
            width: 26.6rem;
            color: $black;
            font-size: 1.4rem;
            line-height: 1.14;
            padding-right: 6rem;

            @media screen and (max-width: $smartphone-middle) {
              width: 100%;
              padding: 0 0 3rem;
            }

            .pic {
              width: 16rem;
              height: 14.4rem;
              margin-bottom: 1.5rem;
            }
          }

          &_right {
            .zip {
              &-title {
                color: #2d3541;
                text-align: left;
              }

              &-subtitle {
                text-align: left;
              }
            }

            .btn {
              max-width: 30rem;
              margin-top: 1rem;

              &-border {
                background: transparent;
                border-color: #214983;
                color: #214983;
              }
            }
          }
        }
      }
    }
  }

  &-shipment {
    width: 100%;
    max-width: 96rem;
    max-height: 70rem;
    overflow-y: auto;
    pointer-events: auto;
  }
}
