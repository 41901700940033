.red-frame {
  padding: 1rem;
  background-color: $bg-red-light;

  &_basket {
    margin: 1.5rem 3.2rem 2rem;
  }
}

.basket {
  &-block {
    border-radius: 0.4rem;
    margin-bottom: 3rem;
    position: relative;
    text-align: left;

    @media screen and (max-width: $smartphone) {
      border-radius: 0;
    }

    &_title {
      border-bottom: 0.1rem solid $border;
      color: $add-text;
      line-height: 1.1;
      padding: 1.8rem 3rem 1.3rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      p {
        color: $text;
        font-weight: $font-bold;
        margin-bottom: 0;
      }
    }

    &_close {
      background: $white url(../../images/ic-close-basket.svg) 50% 50% no-repeat;
      background-size: 1.2rem 1.2rem;
      display: block;
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: -0.1rem;
      right: -0.1rem;
    }

    &_bottom {
      border-radius: 0.4rem;
      overflow: hidden;

      @media screen and (max-width: $tablet) {
        border-bottom: 0.1rem solid #e9e9e9;
        margin: 0 0.9rem;
        padding-top: 1.4rem;
      }
    }

    &_item {
      padding: 2rem 3.2rem 2rem 4rem;
      position: relative;

      @media screen and (max-width: $tablet) {
        padding: 1.6rem 6rem 0 2.4rem;
      }

      @media screen and (max-width: $smartphone-smallest) {
        padding: 1.6rem 4rem 0 1.5rem;
      }

      .btn-remove {
        background: $white url(../../images/ic-delete.svg) 0 0 no-repeat;
        background-size: cover;
        display: block;
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 3.8rem;
        left: 1rem;

        @media screen and (max-width: $tablet) {
          background: url(../../images/ic-delete_m.svg) 0 0 no-repeat;
          width: 1.8rem;
          height: 1.8rem;
          top: calc(50% + 0.8rem);
          left: auto;
          right: 2.8rem;
          transform: translateY(-50%);
        }

        @media screen and (max-width: $smartphone-smallest) {
          right: 1rem;
        }
      }

      &-product {
        display: flex;
        margin-bottom: 1rem;

        @media screen and (max-width: $tablet) {
          background-color: #fefefe;
          box-shadow: 0 0.3rem 0.4rem rgba($black, 0.1);
          border-radius: 1.6rem;
          margin-bottom: 0;
          padding: 2rem 2.5rem 2rem 0;
        }

        .pic {
          margin-right: 1rem;
          flex: 0 0 8.5rem;
          width: 8.5rem;
          height: 5.68rem;
          display: flex;
          justify-content: center;
          overflow: hidden;
          position: relative;

          @media screen and (max-width: $tablet) {
            flex: 0 0 9.4rem;
            width: 9.4rem;
            height: 8rem;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .item-status {
            right: 0;
            font-size: 1.1rem;
            padding: 0.25rem 0.5rem;

            @media screen and (max-width: $tablet) {
              top: 0;
            }
          }
        }

        .info {
          margin-bottom: 1.6rem;

          @media screen and (max-width: $tablet) {
            margin-bottom: 0.2rem;
          }

          &-name {
            font-weight: $font-bold;
            font-size: 1.3rem;
            line-height: 1.1;
            margin-bottom: 0;

            @media screen and (max-width: $smartphone-middle) {
              font-size: 1.2rem;
            }

            a {
              color: $subcategory;
            }
          }
        }
      }

      &-right {
        flex: 2;

        @media screen and (max-width: $tablet) {
          padding-right: 2.7rem;

          .product-content_quantity {
            .quantity {
              &-form {
                display: block;
                padding-top: 3.2rem;
                position: relative;
              }

              &-price {
                font-size: 1.4rem;
                position: absolute;
                top: 0.2rem;
                left: 0;
              }
            }
          }
        }

        @media screen and (max-width: $smartphone-small) {
          padding-right: 1rem;
        }
      }

      .weight-choose_form,
      .quantity-form_inside {
        flex: 0 0 8.8rem;
        width: 8.8rem;

        .btn-minus,
        .btn-plus {
          padding-bottom: 0.3rem;

          background-color: transparent;
          font-size: 1.6rem;
          flex: 0 0 2.2rem;
          width: 2.2rem;
          height: 1.8rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        input {
          font-size: 1.3rem;
          font-weight: $font-bold;
          height: 1.8rem;
        }

        @media screen and ((max-width: $smartphone-small) or (max-width: $smartphone-middle) or (max-width: $smartphone) or (max-width: $tablet)) {
          .btn-minus,
          .btn-plus {
            padding-bottom: 0.3rem;
            font-size: 2.6rem;
            height: 3.5rem;
          }
          .btn {
            margin-top: 0;
          }
          input {
            height: 3.5rem;
          }
        }
      }

      &-bottle {
        color: $add-text;
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        margin-top: 0.5rem;

        @media screen and (max-width: $smartphone-middle) {
          justify-content: flex-start;

          span:first-child {
            margin-right: 0.5rem;
          }
        }

        .caption {
          margin-right: 0.8rem;
          padding-right: 2rem;
          position: relative;
        }

        .info-block {
          position: absolute;
          top: 0;
          right: 0;
          width: 1.6rem;

          .info {
            background-color: #919ba9;
            border-radius: 100%;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.6rem;
            height: 1.6rem;
            font-size: 1.1rem;
            font-weight: $font-semibold;
            margin-bottom: 0;

            &-hover {
              display: none;
              background-color: $white;
              border-radius: 0.2rem;
              box-shadow: 0 0.2rem 0.8rem rgba($black, 0.15);
              position: absolute;
              top: 2rem;
              left: 50%;
              transform: translateX(-50%);
              font-size: 1.2rem;
              width: 31rem;
              padding: 0.8rem;
              z-index: 2;

              &:before {
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-color: transparent;
                border-width: 0 0.7rem 0.7rem 0.7rem;
                border-bottom-color: $white;
                position: absolute;
                top: -0.7rem;
                left: 50%;
                transform: translateX(-50%);
              }

              @media screen and (max-width: $smartphone-small) {
                width: 21rem;
                left: auto;
                right: -2rem;
                transform: none;

                &:before {
                  left: auto;
                  right: 2.1rem;
                  transform: none;
                }
              }
            }

            &:hover {
              & + .info-hover {
                display: block;
              }
            }
          }
        }
      }

      &-error {
        color: $red;
        display: block;
        font-size: 1.2rem;
        line-height: 1.1;
        margin-top: 0.5rem;
      }
    }

    &_comment {
      font-size: 1.3rem;
      margin-bottom: 0;

      .comment-item {
        display: block;
        min-height: 1.9rem;
        margin-bottom: 0;
        padding-left: 3.5rem;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;

        &::before {
          background: url(../../images/ic-comment.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 2.2rem;
          height: 1.9rem;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .count-comment {
        display: block;
        text-align: right;
        color: $add-text;
        font-size: 1.3rem;
      }

      &-form {
        border: 0.2rem solid $border;
        border-radius: 0.4rem;
        margin-top: 0.5rem;
        position: relative;

        .form-control {
          height: 5rem;
          margin: -1rem 0 1.15rem;
          padding-right: 4.7rem;

          &:focus,
          &:active {
            border-color: $subcategory;
          }
        }

        textarea,
        textarea.form-control {
          font-size: 1.4rem;
          margin: 0;
          padding: 0.6rem 4.7rem 0 1rem;
        }

        .btn-send {
          position: absolute;
          top: 0.55rem;
          right: 0.1rem;
        }
      }
    }

    &_added {
      background-color: $white;
      border: 0.1rem dotted $green;
      border-radius: 2.5rem;
      box-shadow: 0 0.1rem 0.4rem 0 rgba(3, 129, 178, 0.1);
      color: $main-text;
      display: flex;
      align-items: center;
      height: 4.8rem;
      padding: 0 3.5rem;

      .caption {
        color: $green;
        font-weight: $font-medium;
        margin-right: 0.5rem;
      }
    }

    &_more {
      border: 0.1rem solid $green;
      border-radius: 1.4rem;
      font-size: 1.4rem;
      padding: 1.5rem 2rem;

      .title {
        font-weight: $font-medium;
        margin-bottom: 2rem;
      }

      .more {
        &-list {
          margin: 0 0 0 -0.7rem;

          li {
            display: inline-block;
            height: 3.5rem;
            margin: 0 0 1rem 0.7rem;
            overflow: hidden;
            position: relative;

            .quantity-form {
              height: 3.5rem;
              width: 8rem;

              &_inside {
                flex: 0 0 8rem;
                width: 8rem;
                border-radius: 0.4rem;
                margin-right: 0;
                padding: 0.2rem;
              }

              input {
                height: 3.1rem;
                font-size: 1.4rem;
                padding: 0;
              }

              .btn-minus,
              .btn-plus {
                border-radius: 0.2rem 0 0 0.2rem;
                flex: 0 0 2.2rem;
                width: 2.2rem;
                height: 3.1rem;
              }

              .btn-plus {
                border-radius: 0 0.2rem 0.2rem 0;
              }
            }
          }

          &_item {
            background-color: $bg-social;
            border-radius: 0.4rem;
            display: block;
            height: 3.5rem;
            line-height: 3.5rem;
            min-width: 8rem;
            padding: 0 1.2rem;
            white-space: nowrap;
            text-align: center;

            &.deleted {
              background-color: #cecfd8;
            }

            &.added {
              background-color: rgba(24, 170, 19, 0.2);
            }
          }
        }

        &-add {
          margin-bottom: 0;
          padding: 1.3rem 0 1.2rem;

          .btn-add {
            background-color: $green;
            border-radius: 0.4rem;
            color: $white;
            display: inline-block;
            font-size: 1.4rem;
            min-width: 17rem;
            padding: 0.8rem 1.1rem 0.7rem;

            .icon-plus {
              border: 0.1rem solid #50c64c;
              border-radius: 3rem;
              display: inline-block;
              line-height: 1.8rem;
              margin-right: 1.1rem;
              width: 2rem;
              height: 2rem;
              text-align: center;
              vertical-align: middle;
            }
          }
        }
      }

      &-btn {
        padding: 2rem 0.5rem 0;

        div[class*='col-'] {
          &:nth-child(2n + 1) {
            padding-right: 10px;
          }

          &:nth-child(2n) {
            padding-left: 10px;
          }
        }

        .btn {
          text-transform: uppercase;
        }
      }
    }

    .product-content_quantity {
      .quantity-price {
        display: flex;
        align-items: center;
        font-size: 1.3rem;
        font-weight: $font-bold;

        @media screen and (max-width: $tablet) {
          flex-direction: row-reverse;
          color: $subcategory;
          font-size: 1.4rem;
        }

        .price {
          &-old {
            color: $add-text;
            font-size: 1.1rem;
            text-decoration: line-through $red;

            @media screen and (max-width: $tablet) {
              font-size: 1.2rem;
            }
          }

          &-sale {
            color: $red;
            margin-left: 0.5rem;

            @media screen and (max-width: $tablet) {
              margin: 0 0.7rem 0 0;
            }
          }
        }
      }
    }

    &_delivery {
      background-color: #ebeff2;
      color: $black;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 3.8rem;
      padding: 0 1.6rem;
      margin: 0;

      @media screen and (max-width: $tablet) {
        background-color: transparent;
        color: $main-text;
        font-size: 1.4rem;
        height: auto;
        padding: 0 0 0.6rem;

        span:last-child {
          font-weight: $font-bold;
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.2rem;
      }
    }

    &_price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #c8e1fb;
      color: $subcategory;
      font-weight: $font-bold;
      height: 5.2rem;
      margin: 0;
      padding: 0 1.6rem;

      .number {
        font-size: 2rem;
        white-space: nowrap;

        &.old {
          font-weight: $font-regular;
          text-decoration: line-through;
        }

        @media screen and (max-width: $tablet) {
          color: $subcategory;
          font-size: 2rem;

          &.new {
            color: $red;
          }
        }

        @media screen and (max-width: $smartphone-middle) {
          font-size: 1.8rem;
        }
      }

      .full-price {
        font-size: 1.8rem;
        margin-right: 1rem;
        text-align: right;
        text-decoration: line-through;

        @media screen and (max-width: $hd) {
          display: block;
          margin-right: 0;
        }
      }

      @media screen and (max-width: $tablet) {
        align-items: flex-start;
        background-color: transparent;
        color: $subcategory;
        height: auto;
        padding: 0 0 0.9rem;

        .caption {
          font-size: 1.4rem;
        }

        .total-price {
          .number {
            color: $subcategory;
            display: block;
            font-size: 1.8rem;

            &.old {
              color: #919ba9 !important;
              font-size: 1.6rem;
              text-decoration: line-through;
            }

            &.new {
              color: $red;
            }
          }
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        .caption {
          font-size: 1.2rem;
        }
      }
    }

    &_sertificate {
      display: flex;
      justify-content: space-between;
      color: $subcategory;
      font-size: 1.8rem;
      margin-bottom: 0;
    }

    &_buttons {
      background-color: $white;
      padding: 1rem 3.2rem 3rem;

      @media screen and (max-width: $smartphone) {
        padding: 2rem 1.5rem;
      }

      .btn {
        margin: 1rem 0 0;

        &:first-child {
          margin: 0;
        }
      }
    }

    &_scroll {
      overflow-y: auto;
      min-height: 20rem;
      max-height: 36vh;

      .basket-block_item {
        &:last-child:not(:first-child) {
          .basket-block_item-bottle .info-block .info-hover {
            top: auto;
            bottom: 2rem;

            &:before {
              top: auto;
              bottom: -0.7rem;
              transform: translateX(-50%) rotate(180deg);

              @media screen and (max-width: $smartphone-small) {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    &_bottle {
      color: $add-text;
      display: flex;
      justify-content: space-between;
      font-size: 1.4rem;
      margin-bottom: 0;
      padding: 0.8rem 1.6rem 0;

      @media screen and (max-width: $tablet) {
        color: $main-text;
        padding: 0 0 0.6rem;

        span:last-child {
          font-weight: $font-bold;
        }
        &.spend-more {
          span {
            font-weight: $font-regular;
          }
        }

        &.order-weight {
          span {
            font-weight: $font-regular;
          }
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.2rem;
        &.spend-more {
          span {
            font-weight: $font-regular;
          }
        }

        &.order-weight {
          span {
            font-weight: $font-regular;
          }
        }
      }

      &.shipment {
        span:first-child {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    &_time {
      color: $add-text;
      font-size: 1.4rem;
      margin: -1rem 0 1rem;
      text-align: center;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    &_maxdev {
      display: flex;
      justify-content: center;
      color: $add-text;
      font-size: 1.4rem;
      padding: 0.8rem 1.6rem;
      text-align: center;

      span {
        margin: 0 0.2rem;
      }

      @media screen and (max-width: $tablet) {
        color: $main-text;
        padding: 0 0 0.6rem;

        .text {
          font-weight: $font-bold;
        }
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.2rem;
      }
    }
  }

  &-content {
    &_add {
      font-size: 1.4rem;
      color: $red-light;

      .caption {
        font-weight: $font-bold;
      }

      @media screen and (max-width: $tablet) {
        margin: 1.6rem 0 0;
        padding: 1.5rem 2.4rem 2rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.2rem;
      }
    }
  }

  &-side {
    display: flex;
    flex-direction: column;
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;

    @media screen and (max-width: $tablet) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .basket-block {
      & > div {
        .basket-block_scroll {
          max-height: 37.5rem;

          @media screen and (max-width: $tablet) {
            margin: 0 -1.5rem;
            max-height: none;
            overflow: visible;
          }
        }
      }
    }

    h3 {
      margin-bottom: 1.4rem;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }
  }

  &-popup {
    background-color: $white;
    box-shadow: 0 0.1rem 0.6rem 0 rgba($shadow, 0.2);
    position: absolute;
    top: 6.5rem;
    right: 0;
    width: 43.5rem;
    max-height: 84rem;
    margin-bottom: 0;
    padding: 7.3rem 0 0;

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-width: 0 0.8rem 1rem;
      border-bottom-color: $white;
      position: absolute;
      top: -1rem;
      right: 13.5rem;
      z-index: 2;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    .pay-loader {
      position: absolute;

      &:before {
        position: absolute;
      }
    }

    .basket-block {
      &_buttons {
        .btn {
          @media screen and (max-width: $smartphone-middle) {
            white-space: nowrap;
            padding: 0 0.5rem;
          }

          .price {
            display: none;

            @media screen and (max-width: $tablet) {
              display: block;
              font-size: 1.8rem;
              margin-left: 1rem;
            }

            @media screen and (max-width: $smartphone-middle) {
              font-size: 1.6rem;
              white-space: nowrap;
            }
          }
        }
      }

      &_bottom {
        margin: 0 3.2rem;

        .low-price-error {
          display: none;
        }

        @media screen and (max-width: $tablet) {
          margin: 0;
          padding-top: 0;

          .low-price-error {
            display: flex;
            align-items: center;
            background-color: rgba(208, 98, 76, 0.2);
            color: #d0624c;
            font-weight: $font-bold;
            height: 3.5rem;
            padding: 0 2.4rem;
          }
        }

        @media screen and (max-width: $smartphone) {
          .low-price-error {
            font-size: 1.2rem;
          }
        }
      }

      &_delivery {
        &.shipment {
          span:first-child {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        &.spend-more {
          justify-content: space-between;
          font-weight: $font-regular;
          font-size: 1.3rem;
          align-items: baseline;
          height: 2.4rem;

          b {
            margin-left: 0.2rem;
            margin-right: 0.2rem;
            font-weight: $font-medium;
          }
        }

        &.order-weight {
          margin-top: 0.1rem;
        }
      }

      &_item {
        @media screen and (max-width: $desktop + 56) {
          &-product {
            display: flex;
          }
        }

        @media screen and (max-width: $tablet) {
          &-product {
            background-color: #fefefe;
            box-shadow: 0 0.3rem 0.4rem rgba($black, 0.1);
            border-radius: 1.6rem;
            margin-bottom: 0;
            padding: 2rem 0 2rem 0.8rem;

            .pic {
              flex: 0 0 9.4rem;
              width: 9.4rem;
              height: 8rem;
            }
          }

          &-right {
            .product-content_quantity {
              .quantity {
                &-form {
                  display: block;
                  padding-top: 3.2rem;
                  position: relative;
                }

                &-price {
                  font-size: 1.4rem;
                  position: absolute;
                  top: 0.2rem;
                  left: 0;
                }
              }
            }
          }
        }

        @media screen and (max-width: $smartphone-smallest - 20) {
          &-product {
            .pic {
              flex: 0 0 6.4rem;
              width: 6.4rem;
              height: 7rem;
            }
          }
        }
      }

      @media screen and (max-width: $tablet) {
        &_title {
          background-color: $white;
          border-radius: 2rem 2rem 0 0;
          border-bottom: none;
          overflow: hidden;
          padding: 4rem 2.4rem 0;

          & + div {
            display: flex;
            flex-direction: column;
            background: #f6f6f6;
            border: 0.05rem solid #f6f6f6;
            border-radius: 1rem 1rem 0 0;
            height: 100%;
            padding-bottom: 2rem;
          }
        }

        &_close {
          background-image: url(../../images/ic-arrow.svg);
          background-size: cover;
          border: none;
          top: 1.5rem;
          right: auto;
          left: 50%;
          transform: translateX(-50%);
          width: 1.7rem;
          height: 1.1rem;
          opacity: 0.3;
          z-index: 5;
        }

        &_scroll {
          flex: 2;
          max-height: none;
          padding: 0.4rem 0 3rem;
        }

        &_price {
          display: none;
        }

        &_buttons {
          box-shadow: 0 0 1.3rem rgba($black, 0.15);
          border-radius: 0.5rem 0.5rem 0 0;
          display: flex;
          flex-direction: row-reverse;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 1rem 2.8rem;
          overflow: hidden;

          .low-price-error {
            display: none;
          }

          .btn {
            margin: 0;

            &-close_mobile {
              background: url(../../images/ic-close_b.svg) 50% 50% no-repeat;
              background-size: 1.2rem 1.2rem;
              border: none;
              flex: 0 0 4.8rem;
              width: 4.8rem;
              margin: 0 0.6rem 0 -1.6rem;

              span {
                display: none;
              }
            }
          }
        }

        &_bottle,
        &_maxdev {
          align-items: center;
          background-color: #e9e9e9;
          color: #919ba9;
          font-weight: $font-bold;
          height: 3.5rem;
          padding: 0 2.4rem;
        }

        &_maxdev {
          justify-content: flex-start;
        }

        &_delivery {
          background-color: rgba($subcategory, 0.2);
          color: $subcategory;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: $font-bold;
          height: 3.5rem;
          padding: 0 2.4rem;

          &.spend-more {
            justify-content: end;

            span:last-child {
              font-weight: $font-regular;
            }
          }

          &.order-weight {
            margin-top: 0.1rem;

            span {
              font-weight: $font-regular;
            }
          }
        }
      }

      @media screen and (max-width: $smartphone-smallest) {
        &_bottle,
        &_maxdev,
        &_delivery {
          padding: 0 1.5rem;

          &.spend-more {
            justify-content: end;

            span:last-child {
              font-weight: $font-regular;
            }
          }

          &.order-weight {
            margin-top: 0.1rem;

            span {
              font-weight: $font-regular;
            }
          }
        }
      }
    }

    .mobile-title {
      display: none;
    }

    @media screen and (max-width: $tablet) {
      background-color: $white;
      border: none;
      border-radius: 2rem 2rem 0 0;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: fixed;
      width: 100%;
      top: 3rem !important;
      right: 0;
      height: calc(100% - 3rem);
      margin: 0;
      max-height: calc(100% - 3rem);
      padding: 8.7rem 0 4.5rem;
      overflow: hidden;
      z-index: 20;

      & + div {
        background-color: rgba($black, 0.4);
      }

      .mobile-title {
        display: flex;
        align-items: center;

        .title {
          font-weight: $font-bold;
          font-size: 1.8rem;
          margin-right: 1.1rem;
        }

        .number {
          color: #919ba9;
          font-size: 1.2rem;
          font-weight: $font-regular;
          padding-top: 0.2rem;
        }
      }

      .desktop-title {
        display: none;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    &-info {
      background: rgba($subcategory, 0.2);
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: $font-semibold;
      height: 6rem;
      margin: 0 -1.5rem;
      padding: 0 2.3rem;
      position: relative;

      &:after {
        background: url(../../images/ic-down.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.5rem;
        height: 0.9rem;
        position: absolute;
        top: 50%;
        right: 2.7rem;
        transform: translateY(-50%);
      }

      &.open {
        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      &_price {
        color: $subcategory;
        font-size: 1.6rem;
        font-weight: $font-bold;
        margin-left: 0.4rem;
      }
    }
  }

  &-page {
    background-color: $bg;
    padding: 3.1rem 0;

    @media screen and (max-width: $tablet) {
      padding-bottom: 8rem;
    }

    .container {
      max-width: 78rem;
    }

    h1 {
      margin-bottom: 2.9rem;
      text-align: center;
    }

    .btn {
      width: auto;
      margin: 0 auto;
    }

    &_delete {
      background: url(../../images/ic-delete.svg) 0 0 no-repeat;
      background-size: cover;
      cursor: pointer;
      display: block;
      flex: 0 0 1.8rem;
      width: 1.8rem;
      height: 1.8rem;
    }

    &_price {
      flex: 0 0 12rem;
      width: 12rem;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      font-weight: $font-bold;

      @media screen and (max-width: $smartphone-middle) {
        justify-content: flex-start;
        margin-top: 1rem;
      }

      .price {
        &-prev {
          color: #919ba9;
          font-size: 1.2rem;
          margin-right: 0.7rem;
          text-decoration: line-through;
        }

        &-sale {
          color: $subcategory;
        }
      }
    }

    &_item {
      border-top: 0.1rem solid #d8dbdf;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.4rem;
      min-height: 9.5rem;
      padding: 1rem 3.8rem 1rem 2.2rem;

      @media screen and (max-width: $tablet-small) {
        padding: 1rem 1.5rem;
      }

      .pic {
        flex: 0 0 6rem;
        width: 6rem;
        height: 6rem;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .item-status {
          font-size: 1.1rem;
          padding: 0.25rem 0.25rem 0.1rem 0.6rem;
          right: 0;

          @media screen and (max-width: $tablet) {
            top: 0;
          }
        }
      }

      .main-info {
        flex: 2;
        padding: 0 3.2rem 0 2.8rem;

        @media screen and (max-width: $smartphone-middle) {
          padding: 0 1.5rem;
        }

        &_main {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: $smartphone-middle) {
            display: block;
          }

          .title {
            flex: 2;
            font-weight: $font-medium;
            padding-right: 2rem;

            @media screen and (max-width: $tablet-small) {
              font-size: 1.2rem;
            }

            @media screen and (max-width: $smartphone-middle) {
              color: $main-text;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              margin-bottom: 1rem;
              padding: 0;
            }
          }

          .weight-choose_form {
            margin-right: 0;
            flex: 0 0 9rem;
            width: 9rem;

            .btn-minus,
            .btn-plus,
            .prev,
            .next {
              flex: 0 0 2.1rem;
              width: 2.1rem;
              height: 3.1rem;
              font-size: 1.8rem;
            }

            input {
              flex: 0 0 2.8rem;
              width: 2.8rem;
              font-size: 1.2rem;
              height: 3.1rem;
            }
          }

          .main-info_error {
            flex: 0 0 100%;
            width: 100%;
          }
        }
      }
    }

    &_list {
      background-color: $white;
      border-radius: 0.8rem;
      margin-bottom: 1rem;

      .basket-page_item {
        &:first-child {
          border-top: none;
        }
      }
    }

    &_total {
      margin-bottom: 2.5rem;

      p {
        border-bottom: 0.1rem solid #d8dbdf;
        margin-bottom: 0;
      }

      .basket-block_bottle {
        color: $main-text;
        padding: 1.3rem 1.6rem;
        border-bottom: none;

        &.spend-more {
          font-size: 1.3rem;
          justify-content: end;
          background: none;
          padding-top: 0;
        }

        &.order-weight {
          font-size: 1.3rem;
          border-top: 0.1rem solid #efefef;
          background: none;
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
        font-weight: $font-semibold;
        padding: 1.3rem 1.6rem;
        border-top: 0.1rem solid #d8dbdf;
      }
    }
  }
}
