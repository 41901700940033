.load {
  &-more {
    font-weight: $font-bold;
    margin: 3rem 0 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .icon {
      background: $white url(../../images/load-more.svg) 50% 50% no-repeat;
      background-size: 2.4rem 2.4rem;
      box-shadow: 0 0.4rem 1.2rem rgba($text, 0.12);
      border-radius: 100%;
      display: block;
      width: 6rem;
      height: 6rem;
      margin: 0 auto 1.4rem;
    }

    @media screen and (max-width: $smartphone-middle) {
      margin: 1rem 0 0;
    }
  }
}
