.link-back {
  color: $subcategory !important;
  font-size: 1.4rem;
  font-weight: $font-bold;
  position: absolute;
  top: 1.8rem;
  left: 2.6rem;
  padding-left: 2rem;
  display: none;

  @media screen and (max-width: $tablet) {
    display: block;
  }

  &:before {
    background: url(../../images/metro/ic-arrow_b.svg) 0 0 no-repeat;
    background-size: cover;
    content: '';
    display: block;
    width: 1.5rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.login {
  &-popup {
    width: 47rem;
    background-color: $white;
    box-shadow: 0 1rem 5rem rgba($text, 0.4);
    margin-top: 1rem;
    padding: 3.2rem 3.6rem;
    position: absolute;
    top: 100%;
    right: 0;

    @media screen and (max-width: $tablet) {
      padding: 1.5rem;
      top: 3.5rem;
      right: 1.5rem;
      position: fixed;
      z-index: 9;
    }

    @media screen and (max-width: $smartphone) {
      width: 34rem;
    }

    @media screen and (max-width: $smartphone-small) {
      width: 30rem;
    }
  }

  &-title {
    font-size: 1.8rem;
    font-weight: $font-bold;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-list {
    margin-bottom: 4rem;

    li {
      margin-top: 1.8rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &-social {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.4rem;
    color: $white;
    cursor: pointer;
    width: 100%;
    font-size: 1.6rem;
    height: 4rem;
    padding: 1rem;
    position: relative;

    .icon {
      position: absolute;
      top: 50%;
      left: 1.5rem;
      transform: translateY(-50%);

      svg {
        width: auto;
        height: 2.2rem;

        @media screen and (max-width: $smartphone-middle) {
          height: 1.8rem;
        }
      }
    }
  }

  &-apple {
    background-color: $black;
  }

  &-google {
    background-color: $white;
    border: 0.1rem solid $border;
    color: $text;
  }

  &-facebook {
    background-color: $facebook;
  }

  &-sep {
    font-weight: $font-bold;
    margin-bottom: 2rem;
    text-align: center;
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 2.4rem;

    a,
    span {
      border-bottom: 0.1rem dashed $text;
      cursor: pointer;

      &:hover {
        border-color: $subcategory;
        color: $subcategory;
      }

      @media screen and ((max-width: $tablet-small) or (max-width: $smartphone) or (max-width: $smartphone-middle)) {
        border-bottom: none;
        text-decoration: underline 0.1rem dashed $text;
      }
    }
  }

  &-info {
    color: $text;
    display: none;
    font-size: 1.4rem;
    padding-top: 3rem;
    text-align: center;

    a {
      color: $subcategory;
    }

    .register {
      color: $text;
      font-size: 1.6rem;
      font-weight: $font-medium;
      margin-bottom: 4.8rem;

      a {
        font-weight: $font-semibold;
      }
    }

    @media screen and (max-width: $smartphone-middle) {
      font-size: 1.2rem;

      .register {
        font-size: 1.4rem;
      }
    }
  }
}

.forgot-password {
  display: none;

  @media screen and (max-width: $tablet) {
    color: $subcategory !important;
    display: block;
    font-size: 1.2rem;
    font-weight: $font-bold;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.section-register {
  .login {
    &-bottom {
      padding-bottom: 4rem;

      @media screen and (max-width: $tablet) {
        display: none;
      }
    }

    &-list {
      .mobile {
        display: none;

        @media screen and (max-width: $tablet) {
          display: block;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      &-mobile {
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 7rem;
      }

      &-info {
        display: block;
      }

      &-form {
        margin-bottom: 4rem;

        .btn-submit {
          &:disabled {
            opacity: 0.5;
          }
        }
      }

      &-sep {
        color: #919ba9;
        margin: 0 -1.5rem 4rem;
        position: relative;
        text-transform: lowercase;

        &:before {
          background-color: #e9e9e9;
          content: '';
          display: block;
          width: 100%;
          height: 0.1rem;
          position: absolute;
          top: 0.8rem;
          left: 0;
        }

        span {
          background-color: $bg;
          padding: 0 5rem;
          position: relative;
        }

        &:first-letter {
          text-transform: uppercase;
        }
      }

      &-list {
        margin-bottom: 1.8rem;
      }
    }

    @media screen and (max-width: $tablet-small) {
      &-list,
      &-form {
        padding: 0 2.3rem;
      }
    }

    @media screen and (max-width: $smartphone-middle) {
      &-sep {
        font-size: 1.4rem;
      }
    }

    @media screen and (max-width: $smartphone-smallest) {
      &-list,
      &-form {
        padding: 0 1rem;
      }
    }

    &-social {
      @media screen and (max-width: $tablet) {
        justify-content: flex-start;
        border: 0.05rem solid #e9e9e9;
        border-radius: 1rem;
        box-shadow: 0 0.3rem 0.4rem rgba($black, 0.1);
        height: 4.8rem;
        padding-left: 4.7rem;
      }

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.4rem;

        .icon {
          svg {
            height: 1.8rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: $tablet) {
    .form-item_password {
      margin-bottom: 2.6rem;
    }
  }
}

.restore {
  &-form {
    padding-bottom: 8rem;

    .btn-show-password {
      top: 3.2rem;
    }

    .btn-hide-password {
      top: 2.8rem;
    }

    @media screen and (max-width: $tablet) {
      .form-item {
        .label {
          display: none;
        }
      }

      .btn-show-password {
        top: 1.2rem;
      }

      .btn-hide-password {
        top: 0.8rem;
      }
    }

    @media screen and (max-width: $tablet-small) {
      padding: 0 2.3rem 8rem;
    }

    @media screen and (max-width: $smartphone-middle) {
      padding-bottom: 5rem;
    }
  }
}

.registration {
  &-popup-container {
    background-color: $white;
    width: 50rem;
    height: 50rem;
    top: 30%;
    left: 30%;
  }

  &-modal {
    padding: 2rem;
    background-color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: $tablet) {
      background-color: $bg;
      height: calc(100vh - 8rem);
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .mobile {
        max-width: 40rem;

        .close-modal-pass {
          position: absolute;
          top: 1.8rem;
          left: 2.4rem;
          color: $subcategory;
          font-size: 1.4rem;
          font-weight: $font-bold;
        }

        .forgot-content {
          font-size: 1.4rem;
        }
      }
    }

    .form-item {
      padding-bottom: 3.5rem;
    }

    .forgot-content,
    .btn {
      margin-bottom: 2rem;
    }

    .close-modal-pass {
      cursor: pointer;

      &:hover {
        color: $subcategory;
      }
    }
  }
}
