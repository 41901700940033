.section-delivery {
  .breadcrumb {
    margin-bottom: 3.5rem;
  }
}

.delivery {
  &-main {
    font-size: 1.8rem;

    .link {
      color: $subcategory;
      font-weight: $font-bold;
    }
  }

  &-pic {
    margin-bottom: 2.5rem;
    width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-right {
    margin-bottom: 2.5rem;

    h4 {
      font-size: 2.4rem;
      margin-bottom: 2.5rem;
      max-width: 50rem;
    }
  }

  &-content {
    font-size: 1.8rem;
    line-height: 1.5;

    @media screen and (max-width: $smartphone) {
      font-size: 1.6rem;
      padding: 1.5rem 0;
    }

    a {
      font-weight: $font-bold;
    }
  }

  &-static {
    background-color: $bg-blue-gray;
    padding: 2.5rem 0 5rem;
  }

  &-info {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 3rem;
    padding: 2.1rem 0;

    @media screen and (max-width: $smartphone) {
      display: block;
      padding: 1.5rem 0;
    }

    .subtitle {
      font-size: 1.8rem;
      font-weight: $font-bold;
    }

    &_phone {
      flex: 0 0 22.5rem;
      margin-left: 2rem;
      padding-left: 3.2rem;

      @media screen and (max-width: $smartphone) {
        border-left: none;
        margin: 0 0 2.5rem;
        padding-left: 0;
      }

      .phone {
        color: $subcategory;
        font-weight: $font-bold;
      }
    }

    .info-time {
      margin-bottom: 2.5rem;
    }
  }

  &-time {
    @media screen and (max-width: $tablet) {
      margin-bottom: 2.5rem;
    }
  }
}
