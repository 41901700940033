.mobile {
  &-menu {
    background-color: $white;
    box-shadow: 0 0 1.3rem rgba($black, 0.15);
    border-radius: 0.5rem 0.5rem 0 0;
    position: fixed;
    left: 0;
    bottom: 0;
    display: none;
    justify-content: space-between;
    font-size: 1.2rem;
    width: 100%;
    padding: 1.4rem 2.2rem 1.5rem;

    @media screen and (max-width: $tablet) {
      display: flex;
    }
  }

  &-button {
    color: #2d3541;
    font-size: 1.2rem;
    position: relative;

    .icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 auto;
      position: relative;

      &-home {
        background: url(../../images/ic-home.svg) 50% 50% no-repeat;
        background-size: 1.9rem 1.9rem;
      }

      &-categories {
        background: url(../../images/ic-categories.svg) 50% 50% no-repeat;
        background-size: 1.7rem 1.7rem;
      }

      &-search {
        background: url(../../images/ic-search_m.svg) 50% 50% no-repeat;
        background-size: 1.6rem 1.6rem;
      }

      &-fav {
        background: url(../../images/ic-fav.svg) 50% 50% no-repeat;
        background-size: 1.9rem 1.7rem;
      }

      &-cart {
        background: url(../../images/ic-basket_m.svg) 50% 50% no-repeat;
        background-size: 1.7rem 1.7rem;
      }
    }

    .number {
      background-color: $subcategory;
      border-radius: 0.6rem;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.1rem;
      font-weight: $font-semibold;
      min-width: 1.4rem;
      height: 1.4rem;
      padding: 0 0.3rem;
      position: absolute;
      top: 0;
      left: 1.3rem;
    }

    &:hover {
      color: $text;
    }

    &.active {
      color: $subcategory;

      .icon {
        &-home {
          background-image: url(../../images/metro/ic-home_active.svg);
        }

        &-categories {
          background-image: url(../../images/metro/ic-categories_active.svg);
        }

        &-search {
          background-image: url(../../images/metro/ic-search_m_active.svg);
        }

        &-fav {
          background-image: url(../../images/metro/ic-fav_active.svg);
        }

        &-cart {
          background-image: url(../../images/metro/ic-basket_m_active.svg);
        }
      }
    }
  }

  &-header {
    background-color: $subcategory;
    border-radius: 0 0 2rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.5rem;
    padding: 0 2.4rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;

    .logo-mobile {
      display: block;
      width: 8.2rem;
      height: 3.2rem;
    }

    .dropdown {
      padding: 0;

      @media screen and (max-width: $tablet) {
        &-login-btn {
          position: static;
        }
      }
    }

    .header {
      &-login {
        width: 1.6rem;
        height: 1.9rem;

        .btn-login {
          &:before {
            background: url(../../images/ic-user_m.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 1.6rem;
            height: 1.9rem;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }

          span {
            display: none;
          }
        }
      }

      &-user {
        color: $white;
        display: block;
        font-size: 1.4rem;
        font-weight: $font-semibold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 17.5rem;

        @media screen and (max-width: $smartphone-small) {
          max-width: 10rem;
        }

        &:hover {
          color: $white;
        }

        &:before {
          background: url(../../images/ic-user_m.svg) 0 0 no-repeat;
          background-size: cover;
          width: 1.6rem;
          height: 1.9rem;
        }
      }
    }

    &_right {
      display: flex;

      .header-zip {
        background-image: url(../../images/ic-target_w.svg);
        background-size: 1.4rem 1.7rem;
        color: $white;
        font-size: 1.4rem;
        margin-right: 1.5rem;
        padding-left: 2.4rem;

        @media screen and (max-width: $smartphone-small) {
          max-width: 10rem;
        }

        @media screen and (max-width: $smartphone-smallest) {
          max-width: 7rem;
        }
      }
    }
  }

  &-categories {
    min-height: 100vh;
    padding-bottom: 6.8rem;

    &_list {
      background-color: $white;
      padding-bottom: 3rem;
    }

    &_one {
      height: 6rem;
      padding: 0 2.4rem;

      @media screen and (max-width: $smartphone-smallest) {
        padding: 0 1.5rem;
      }

      &-in {
        border-top: 0.1rem solid rgba(213, 221, 224, 0.4);
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        position: relative;

        &:after {
          background: url(../../images/ic-arrow-drop.svg) 0 0 no-repeat;
          background-size: cover;
          content: '';
          display: block;
          width: 1.2rem;
          height: 0.66rem;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%) rotate(-90deg);
        }
      }

      &-link {
        border-top: 0.1rem solid rgba(213, 221, 224, 0.4);
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 100%;
        position: relative;
      }

      .pic {
        flex: 0 0 4.6rem;
        width: 4.6rem;
        height: 4.6rem;
        margin-right: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 90%;
          height: 90%;
          object-fit: contain;
        }
      }

      .name {
        font-size: 1.7rem;
        font-weight: $font-medium;
      }
    }

    &_back {
      display: none;

      @media screen and (max-width: $tablet) {
        display: flex;
        align-items: center;
        color: $subcategory;
        font-size: 1.2rem;
        font-weight: $font-bold;
        margin-bottom: 1.4rem;

        .arrow {
          background: url(../../images/metro/ic-arrow_b.svg) 0 0 no-repeat;
          background-size: cover;
          display: block;
          width: 1.5rem;
          height: 1rem;
          margin-right: 0.7rem;
        }
      }
    }

    &_top {
      padding: 1.8rem 2.4rem 1.7rem;

      @media screen and (max-width: $smartphone-smallest) {
        padding: 1.8rem 1.5rem 1.7rem;
      }

      & + .mobile-categories_one {
        .mobile-categories_one-in,
        .mobile-categories_one-link {
          border-top: none;
        }
      }

      h1 {
        margin: 0.6rem 0 0;
      }
    }

    &_products {
      background-color: $bg;
      border-radius: 2rem 2rem 0 0;
    }

    &_name {
      padding-right: 13rem;
    }

    &_filter {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      position: absolute;
      top: 5.5rem;
      right: 2.4rem;

      @media screen and (max-width: $smartphone-middle) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: $smartphone-smallest) {
        right: 1.5rem;
      }

      .arrows {
        background: url(../../images/ic-sort.svg) 0 0 no-repeat;
        background-size: cover;
        display: block;
        width: 1.8rem;
        height: 1.4rem;
        margin-left: 1.6rem;
      }
    }

    &_footer {
      background-color: $bg;
      padding: 2.4rem;

      @media screen and (max-width: $tablet) {
        &-right {
          text-align: right;
        }
      }

      @media screen and (max-width: $smartphone-smallest) {
        padding: 2.4rem 1.5rem;
      }
    }
  }

  &-filter {
    &_one {
      flex: 0 0 50%;
      width: 50%;
      cursor: pointer;
      padding: 0 2.4rem;
      position: relative;

      @media screen and (max-width: $smartphone-smallest) {
        padding: 0 1.5rem;
      }

      &:last-child {
        border-left: 0.1rem solid #d5dde0;
      }

      &.filter {
        .caption {
          background: url(../../images/ic-filter.svg) 0 50% no-repeat;
          background-size: 1.6rem 1.4rem;
          display: block;
          padding: 1.3rem 1rem 1.3rem 2.6rem;
        }

        .number {
          background-color: $bg-red;
          border-radius: 100%;
          color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          font-weight: $font-semibold;
          min-width: 1.6rem;
          height: 1.6rem;
          padding: 0 0.3rem;
          text-align: center;
          position: absolute;
          top: 0.9rem;
          left: 3.2rem;

          @media screen and (max-width: $smartphone-smallest) {
            left: 2.2rem;
          }
        }
      }

      &.sort {
        .caption {
          background: url(../../images/ic-sort.svg) 0 50% no-repeat;
          background-size: 1.8rem 1.6rem;
          display: block;
          padding: 1.3rem 1rem 1.3rem 2.6rem;
        }
      }
    }

    &_row {
      display: flex;
      border-top: 0.1rem solid #d5dde0;
      border-bottom: 0.1rem solid #d5dde0;
      font-size: 1.2rem;
      margin: 0 0 1.4rem;
    }

    &_item {
      border-bottom: 0.1rem solid rgba(213, 221, 224, 0.4);
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-size: 1.7rem;
      height: 6rem;
      position: relative;

      &:after {
        background: url(../../images/ic-arrow-drop.svg) 0 0 no-repeat;
        background-size: cover;
        content: '';
        display: block;
        width: 1.2rem;
        height: 0.66rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) rotate(-90deg);
      }

      .filter {
        &-selected {
          flex: 2;
          text-align: right;
          padding: 0 2rem 0 4rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          font-size: 1.2rem;
          font-weight: $font-bold;
        }
      }
    }

    &_result {
      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.1rem;

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          position: relative;

          .number {
            background-color: $subcategory;
            border-radius: 100%;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            font-weight: $font-semibold;
            min-width: 1.6rem;
            height: 1.6rem;
            margin-left: 0.5rem;
            padding: 0 0.3rem;
            text-align: center;
          }
        }
      }
    }

    &_clear {
      color: $add-text;
      font-size: 1.2rem;
      font-weight: $font-bold;
    }

    &_search {
      margin: 2rem 2.4rem 0;
      position: relative;

      input {
        background: #f7f8f9 url(../../images/ic-search.svg) 1.7rem 50% no-repeat;
        background-size: 2rem 2rem;
        border: 0.1rem solid #d5dde0;
        border-radius: 0.8rem;
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
        padding: 0 3rem 0 5rem;

        &:focus {
          background: $white url(../../images/ic-search.svg) 1.7rem 50% no-repeat;
        }
      }

      .clear {
        position: absolute;
        top: 0.8rem;
        right: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 3rem;
        height: 3rem;
      }
    }

    &_checkbox {
      display: flex;
      align-items: center;
      border-bottom: 0.1rem solid rgba(213, 221, 224, 0.4);
      height: 6rem;

      label {
        font-size: 1.4rem;
        margin-bottom: 0;
        padding-left: 3.8rem;
        position: relative;

        &:before {
          background: $border;
          border: 1px solid $gray-border;
          border-radius: 0.2rem;
          content: '';
          cursor: pointer;
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          width: 2.4rem;
          height: 2.4rem;
          transform: translateY(-50%);

          @media screen and (max-width: $tablet) {
            background: #f7f8f9;
            border: 1px solid #ebebeb;
          }
        }
      }

      input[type='checkbox'] {
        display: none;

        &:checked + label {
          &:before {
            background-color: $subcategory;
            border-color: $subcategory;
          }

          &:after {
            background: url(../../images/ic-check-mark_w.svg) 0 0 no-repeat;
            background-size: cover;
            content: '';
            display: block;
            width: 1.3rem;
            height: 0.9rem;
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
          }
        }
      }

      &-list {
        margin-bottom: 1.9rem;
      }
    }

    &_choose {
      overflow-y: auto;
      height: 100%;
    }
  }

  &-user {
    &_static {
      display: none;
    }

    &_top {
      .profile-aside_credits {
        display: none;
      }
    }

    @media screen and (max-width: $tablet) {
      &_close {
        background: url(../../images/ic-close_o.svg) 0 0 no-repeat;
        background-size: cover;
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        margin-bottom: 2.5rem;
      }

      &_top {
        background: #f7f8f9;
        border-radius: 0 0 2rem 2rem;
        margin-bottom: 0.6rem;
        padding: 2.6rem 2.4rem 3.2rem;

        .user-popup_name {
          display: none;

          &.mobile {
            display: block;
            margin-bottom: 0.9rem;
          }
        }

        .profile-aside_credits {
          display: flex;
        }
      }

      &_static {
        display: block;
        border: none !important;
        font-size: 1.5rem;
        font-weight: $font-medium;
        padding: 0 2.4rem !important;

        li {
          border-bottom: 0.1rem solid rgba(213, 221, 224, 0.4);
          padding: 0 !important;
        }

        a {
          display: flex;
          align-items: center;
          height: 4.8rem;
        }
      }
    }

    @media screen and (max-width: $smartphone-smallest) {
      &_top {
        padding: 2.6rem 1.5rem 3.2rem;
      }

      &_static {
        padding: 0 1.5rem !important;
      }
    }
  }
}
